import React from 'react'
import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Box,
  Text,
  Stack,
} from '@chakra-ui/react'
import BackgroundImage from '../../components/BackgroundImage'
import SpecialButton from '../../components/SpecialButton'
import { responsive } from '../../components/ThemeProvider/theme'
import CustomModalCloseButton from '../../components/ModalCloseButton'

import step_1 from './step_1.png'
import step_2 from './step_2.png'
import step_3 from './step_3.png'
import title from './title.png'
import arrow_left from './subtitle_arrow-left.png'
import arrow_right from './subtitle_arrow-right.png'
import CustomModal from '../../components/CustomModal'

const steps = [
  { img: step_1, text: '在畫面中找出日常可見的退役材料。' },
  { img: step_2, text: '認識材料後，進一步完成改造任務。' },
  { img: step_3, text: <Text>蒐集完 9 種退役材料，即可獲得<Text as="span" color="normalBlue">霹靂徽章</Text>！</Text> },
]

const group = /([^[]+)\[([^\]]+)\](\W+)/

const Intro = ({ isOpen, onClose }) => {
  return (
    <CustomModal scrollBehavior="inside" isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalContent
        my={responsive('0')}
        maxH={responsive('90vh', '95vh')}
        maxW={responsive('30em', '39.875em')}
        overflow="auto"
        border="3px solid"
        borderRadius={'1.25rem'}
        color="textBlue"
        width={responsive('91.8%', null, '33.23%', null, '39.875em')}
        minWidth={responsive('auto', '25em')}
        p="0"
      >
        <CustomModalCloseButton />
        <ModalBody px={responsive('1.5em', null, null, null, '3.75em')} py={responsive('1.5em', null, '1.75em', null, '2.25em')}>
          <Stack spacing={responsive('1.125em', '1.5em')}>
            <Box mx="auto" width={responsive('80%', '67.4%')}><BackgroundImage src={title} ratio={647 / 367} /></Box>
            <Flex justifyContent={'center'} alignItems="center">
              <Box width={responsive('1.5em', null, '1.625em', null, '2.25em')}><BackgroundImage src={arrow_left} ratio={54 / 42} /></Box>
              <Box borderBottom={'2px solid'} mx="0.375rem"><Text variant={'mission_title'}>遊戲任務說明</Text></Box>
              <Box width={responsive('1.5em', null, '1.625em', null, '2.25em')}><BackgroundImage src={arrow_right} ratio={54 / 42} /></Box>
            </Flex>
            <Stack px={responsive(0, '0.75em')} spacing={responsive(4, null, null, null, 5)}>
              {steps.map(({ img, text }, i) => (
                <Flex justifyContent={'cneter'} key={i}>
                  <Box
                    width={responsive('3.75em', null, '3em', null, '5em')}
                    borderRadius="0.625em"
                    border="3px solid"
                    ml={responsive(0, '1.75em')}
                    height="100%"
                  ><BackgroundImage src={img} ratio={1} /></Box>
                  <Box ml="1.5em" flex={1}>
                    <Box px="1.25em" bg="paleGray" display={'inline-block'} borderRadius="1.25em">
                      <Text fontSize={responsive('1em', null, '0.875em', null, '1em')}>STEP {i + 1}.</Text>
                    </Box>
                    <Box mt="0.375em">
                      <Text>{text}</Text>
                    </Box>
                  </Box>
                </Flex>
              ))}
            </Stack>
            <Box textAlign={'center'}>
              <SpecialButton variant={'yellow'} fontSize={responsive('1.25em', null, '1.375em', null, '1.625em')} onClick={onClose}>開始任務</SpecialButton>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </CustomModal>
  )
}

export default Intro
