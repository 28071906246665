import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Icon, IconButton, useDisclosure, } from '@chakra-ui/react';
import { useMeasure, useSessionStorage } from 'react-use';
// import { IoCaretBackOutline, IoCaretForwardOutline } from 'react-icons/io5'

import Layout from './Layout'
import BackgroundSVG from './BackgroundSVG'
import home_floor from './home_floor.svg'
import Game from './Game';
import Progress from './Progress';
import Intro from './Intro';
import Compelete from './Complete';
import Guide from './Guide';
import { responsive } from '../components/ThemeProvider/theme';
import useResponsive from '../contexts/mediaQuery/useResponsive';

// const ScrollButton = ({ as, ...props }) => (
//   <IconButton
//     display={responsive('inline-block', 'none')}
//     lineHeight="0"
//     isRound
//     h={'auto'}
//     pos="fixed"
//     border="5px solid"
//     borderColor="textBlue"
//     bg="rgba(255, 255, 255, 0.7)"
//     top={'50%'}
//     transform={'translateY(-50%)'}
//     _hover={{}}
//     _active={{}}
//     icon={<Icon as={as} w={6} h={6} />}
//     zIndex={5}
//     {...props}
//   />
// )

const HomePage = () => {
  const [finish, setFinish] = useSessionStorage('mission', {})
  // const [ref, { height }] = useMeasure()
  const bgRef = useRef()
  const [done, setDone] = useSessionStorage('done', undefined)
  const [gameOpen, setOpen] = useState()
  const [guideOpen, setGuide] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isTablet } = useResponsive()
  useEffect(() => onOpen(), [])
  useEffect(() => {
    if (Object.values(finish).length === 9 && Object.values(finish).every(d => d.includes('finish')) && typeof done === 'undefined' && !gameOpen) {
      setDone(true)
    }
  } ,[finish, gameOpen])
  // const { isTablet } = useResponsive()
  useEffect(() => {
    if (bgRef?.current && isTablet) {
      bgRef?.current.scroll({
        top: 0,
        left: bgRef?.current?.offsetHeight * (192 / 101) * 0.4,
        // behavior: 'smooth'
      })
    }
  }, [isTablet, bgRef?.current])
  return (
    <Layout>
      <Flex flexDir="column" h={'100%'} pos={'relative'} overflowX={responsive('auto', null, 'hidden')} overflowY={responsive('hidden')} ref={bgRef}>
        <BackgroundSVG width={responsive(bgRef?.current?.offsetHeight * (192 / 101), null ,'100%')} setOpen={setOpen} finish={finish} position={'relative'} overflow="visible" zIndex={3} />
        <Box
          position={'relative'}
          flex={1}
          bg="skyBlue"
          backgroundImage={`url(${home_floor})`}
          backgroundRepeat="repeat"
          backgroundSize="8.25vw"
          backgroundPosition='0% 0%'
        />
        <Progress
          finish={finish}
          setFinish={setFinish}
          setDone={setDone}
          onOpen={onOpen}
        />
        {/* <ScrollButton
          as={IoCaretBackOutline}
          p="10px 12px 10px 8px"
          left={'15px'}
          onClick={() => {
            // console.log('hi', typeof window.scrollBy === 'function')
            if (typeof window.scrollBy === 'function') {
              window.scrollBy(-10, 0)
            }
          }}
        />
        <ScrollButton
          as={IoCaretForwardOutline}
          p="10px 8px 10px 12px"
          right={'15px'}
            onClick={() => {
          }}
        /> */}
      </Flex>
      <Game isOpen={gameOpen} finish={finish} setFinish={setFinish} onClose={() => setOpen(false)} />
      <Intro isOpen={isOpen} onClose={onClose} />
      <Compelete isOpen={done} onClose={() => setDone(false)} />
      {isTablet && <Guide isOpen={guideOpen} onClose={() => setGuide(false)} />}
    </Layout>
  )
}

export default HomePage
