import React, { useState } from 'react'
import { Box, Flex, HStack, IconButton, Stack } from '@chakra-ui/react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { BsQuestion } from 'react-icons/bs'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../components/ThemeProvider/theme'

import game1 from './image/progress-bar_01.png'
import game1_done from './image/progress-bar_01_done.png'
import game2 from './image/progress-bar_02.png'
import game2_done from './image/progress-bar_02_done.png'
import game3 from './image/progress-bar_03.png'
import game3_done from './image/progress-bar_03_done.png'
import game4 from './image/progress-bar_04.png'
import game4_done from './image/progress-bar_04_done.png'
import game5 from './image/progress-bar_05.png'
import game5_done from './image/progress-bar_05_done.png'
import game6 from './image/progress-bar_06.png'
import game6_done from './image/progress-bar_06_done.png'
import game7 from './image/progress-bar_07.png'
import game7_done from './image/progress-bar_07_done.png'
import game8 from './image/progress-bar_08.png'
import game8_done from './image/progress-bar_08_done.png'
import game9 from './image/progress-bar_09.png'
import game9_done from './image/progress-bar_09_done.png'
import Replay from './Replay'
import { questions } from '../Game'
import badge from '../Intro/step_3.png'

const progress = [
  { invisible: game6, visible: game6_done, key: 5 },
  { invisible: game1, visible: game1_done, key: 0 },
  { invisible: game5, visible: game5_done, key: 4 },
  { invisible: game9, visible: game9_done, key: 8 },
  { invisible: game7, visible: game7_done, key: 6 },
  { invisible: game4, visible: game4_done, key: 3 },
  { invisible: game2, visible: game2_done, key: 1 },
  { invisible: game3, visible: game3_done, key: 2 },
  { invisible: game8, visible: game8_done, key: 7 },
]

const IconBtn = ({...props}) => (
  <Box>
    <IconButton
      width={'auto'}
      isRound
      minW={'auto'}
      height={'auto'}
      border="5px solid"
      borderColor="textBlue"
      bg="rgba(255, 255, 255, 0.7)"
      fontSize={responsive('4.5vw', '1em')}
      _hover={{}}
      _active={{}}
      {...props}
    />
  </Box>
)

const Progress = ({ finish, setFinish, setDone, onOpen }) => {
  const [sink, setSink] = useState(true)
  return (
    <Box
      position={responsive('fixed', null, 'absolute')}
      left="50%"
      transform={responsive(sink ? 'translate(-50%, 100%)' : 'translateX(-50%)', null, 'translateX(-50%)')}
      width={responsive('113.5%', null, '50.1%')}
      bottom={responsive('-7px', '-14px')}
      transition={'all 0.5s'}
      zIndex={5}
    >
      <IconButton
        pos="absolute"
        height="auto"
        border="5px solid white"
        borderRadius={'10px 10px 0 0'}
        p={responsive('1.38vw', '5px')}
        bottom="100%"
        right={responsive('10.15vw')}
        transform="translateY(5px)"
        display={responsive('inline-block', null, 'none')}
        bg={'textBlue'}
        color={'white'}
        fontSize={responsive('3.2vw', '1em')}
        icon={sink ? <FaCaretUp size="3em" /> : <FaCaretDown size="3em" />}
        onClick={() => setSink(!sink)}
        _hover={{}}
        _active={{}}
      />
      <Flex
        flexWrap='wrap'
        justifyContent={'center'}
        pos="relative"
        border="7px solid"
        borderColor="textBlue"
        borderRadius="1.25em 1.25em 0 0"
        px={responsive('3.5em', null, '2.5em')}
        py={responsive('0.625em', '1.875em')}
        bg="rgba(255, 255, 255, 0.7)"
      >
        {progress.map(({ invisible, visible, key }) => (
          <Box width={responsive('18.95%', null, '11.1%')} key={key}>
            <BackgroundImage src={finish?.[key]?.includes(questions[key].ans) ? visible : invisible } ratio={1} key={key} />
          </Box>
        ))}
      </Flex>
      <Stack
        spacing={responsive(2, null, 0)}
        flexDir={responsive('column', null, 'row-reverse')}
        pos="absolute"
        textAlign={'right'}
        top={responsive('-15.6vw', '-84px', '0')}
        left={responsive('unset', null, '100%')}
        right={responsive('10.15vw', null, 'unset')}
        transform={responsive('translateY(-100%)', null, 'translateX(30px)')}
      >
        {Object.keys(finish).length === 9 && Object.keys(finish).every(d => finish[d].includes(questions[d].ans)) && (
          <>
            <IconBtn onClick={() => setDone(true)} fontSize={responsive('4.5vw', '1.32em')} ml={responsive(0, 4)}>
              <Box width={'2.75em'}>
                <BackgroundImage src={badge} ratio={1} />
              </Box>
            </IconBtn>
            <IconBtn
              onClick={() => {
                setFinish({})
                setDone(undefined)
              }}
              p={responsive('1.95vw', '7px')}
              ml={responsive(0, 4)}
            >
              <Replay width={responsive("1.885em", '2.75em')} />
            </IconBtn>
          </>
        )}
        <IconBtn
          fontSize={responsive('4.5vw', '1.32em')}
          p="0"
          onClick={onOpen}
          icon={<BsQuestion size="2.75em" />}
        />
      </Stack>
    </Box>
  )
}

export default Progress
