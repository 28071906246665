import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { responsive } from '../../components/ThemeProvider/theme'

export const types = [
  '改造類別',
  '循環再造商品',
]

const Type = ({ data , ...props }) => (
  <Stack spacing={'6px'} {...props}>
    {types.map((type, i) => (
      <Stack spacing={'6px'} textAlign={'center'} key={i}>
        <Box
          borderRadius={responsive('1em', '1.875rem')}
          bg={'paleGray'}
        >
          <Text variant={'h4'}>{type}</Text>
        </Box>
        <Text variant={'h4'}>
          {data?.categories?.[i]}
        </Text>
      </Stack>
    ))}
  </Stack>
)

export default Type
