import React, { useState } from 'react'
import {
  ModalContent,
  ModalBody,
  Flex,
  Box,
  Icon,
  Text,
  Stack,
} from '@chakra-ui/react'
import { GoSearch } from "react-icons/go";
import BackgroundImage from '../../components/BackgroundImage'
import SpecialButton from '../../components/SpecialButton'
import { responsive } from '../../components/ThemeProvider/theme'
import useResponsive from '../../contexts/mediaQuery/useResponsive';
import CustomModalCloseButton from '../../components/ModalCloseButton';
import CustomModal from '../../components/CustomModal';

import Play from './Play';

import start from './image/start.png'
import intro1 from './image/intro_01.png'
import intro2 from './image/intro_02.png'
import intro3 from './image/intro_03.png'
import intro4 from './image/intro_04.png'
import intro5 from './image/intro_05.png'
import intro6 from './image/intro_06.png'
import intro7 from './image/intro_07.png'
import intro8 from './image/intro_08.png'
import intro9 from './image/intro_09.png'
import tutorial1 from './image/tutorial_01.png'
import tutorial2 from './image/tutorial_02.png'
import tutorial3 from './image/tutorial_03.png'
import tutorial4 from './image/tutorial_04.png'
import tutorial5 from './image/tutorial_05.png'
import tutorial6 from './image/tutorial_06.png'
import tutorial7 from './image/tutorial_07.png'
import tutorial8 from './image/tutorial_08.png'
import tutorial9 from './image/tutorial_09.png'
import tutorial1_m from './image/tutorial_01-m.png'
import tutorial2_m from './image/tutorial_02-m.png'
import tutorial3_m from './image/tutorial_03-m.png'
import tutorial4_m from './image/tutorial_04-m.png'
import tutorial5_m from './image/tutorial_05-m.png'
import tutorial6_m from './image/tutorial_06-m.png'
import tutorial7_m from './image/tutorial_07-m.png'
import tutorial8_m from './image/tutorial_08-m.png'
import tutorial9_m from './image/tutorial_09-m.png'
import game1 from './image/game_01-1.png'
import game1_2 from './image/game_01-2.png'
import game2 from './image/game_02-1.png'
import game2_2 from './image/game_02-2.png'
import game3 from './image/game_03-1.png'
import game3_2 from './image/game_03-2.png'
import game4 from './image/game_04-1.png'
import game4_2 from './image/game_04-2.png'
import game5 from './image/game_05-1.png'
import game5_2 from './image/game_05-2.png'
import game6 from './image/game_06-1.png'
import game6_2 from './image/game_06-2.png'
import game7 from './image/game_07-1.png'
import game7_2 from './image/game_07-2.png'
import game8 from './image/game_08-1.png'
import game8_2 from './image/game_08-2.png'
import game9 from './image/game_09-1.png'
import game9_2 from './image/game_09-2.png'
import tutorial_title from './image/tutorial_title.png'
import tutorial_title_m from './image/tutorial_title-m.png'
import MaterialList from './MaterialList';
import Type from './Type';

const Span = (props) => <Text as="span" color="normalBlue" {...props} />

export const questions = [
  {
    img: intro1,
    pic: tutorial1,
    picMobile: tutorial1_m,
    game: game1,
    finish: game1_2,
    name: '煤灰',
    intro: '火力發電燃燒煤炭後產生的灰燼，與水泥混合後可作為建材減少水泥的使用量、或與陶瓷土混搭，不同製品都需要經過設計師反覆測試才能找到最佳比例唷！',
    categories: ['材料跨界', '木光/擴香公文筆記本'],
    link: 'https://www.tpcreativeshop.com/collections/woodpower',
    ans: 1,
    correct: <Text>恭喜你運用<Span>鏟子</Span>協助蒐集煤灰！燃燒後的<Span>煤灰</Span>與水泥跨界結合後，變成了<Span>木光收納盒</Span>！</Text>
  },
  {
    img: intro2,
    pic: tutorial2,
    picMobile: tutorial2_m,
    game: game2,
    finish: game2_2,
    name: '底泥',
    intro: '水力發電過程中因抽蓄作業或上游沖刷而堆積在水庫底部的淤泥即為底泥。黏性比一般陶土較高，雜質亦較多，需經過前置處理才可使用，燒製難度也更高。',
    categories: ['材料跨界', '人孔蓋杯墊'],
    link: 'https://www.tpcreativeshop.com/collections/sunmoonlake',
    ans: 1,
    correct: <Text>恭喜你運用<Span>鏟子</Span>協助蒐集底泥！堆積在水庫的<Span>底泥</Span>與陶土跨界結合後，變成了<Span>人孔蓋杯墊</Span>！</Text>
  },
  {
    img: intro3,
    pic: tutorial3,
    picMobile: tutorial3_m,
    game: game3,
    finish: game3_2,
    name: '絕緣礙子',
    intro: '安裝在各種電力設備中絕緣用，如電塔、電線桿、變電箱內。規格尺寸小則10公分、大到超過1公尺，材料為耐高溫的陶瓷，經過清潔即可原裝再利用。',
    categories: ['結構重組', '礙子燈座/礙子獎盃'],
    link: 'https://www.tpcreativego.com/退役礙子不退役台電文創打造獨一無二台電獎盃/',
    ans: 0,
    correct: <Text>恭喜你運用<Span>刷子</Span>協助清潔！<Span>絕緣礙子</Span>經過設計重組後，變成了<Span>礙子獎盃</Span>！</Text>
  },
  {
    img: intro4,
    pic: tutorial4,
    picMobile: tutorial4_m,
    game: game4,
    finish: game4_2,
    name: '電桿牌',
    intro: '掛置在電線桿上的告示牌，清楚標示該電線桿基本資訊，如電力座標編碼 (標示所在位置)、桿號，及所屬行政區資訊。鋁製材質具高度延展性與彈性，清潔後即可進入再造階段。',
    categories: ['結構重組', '此項材料循環商品開發中'],
    btn: '看更多材料介紹',
    link: 'https://www.tpcreativego.com/projects/電桿牌-f003/',
    ans: 0,
    correct: <Text>謝謝你協助清潔材料進入設計階段，商品開發中，敬請期待！</Text>
  },
  {
    img: intro5,
    pic: tutorial5,
    picMobile: tutorial5_m,
    game: game5,
    finish: game5_2,
    name: '電纜線',
    intro: '舖設於地面下、或高空中，導線材質大多為鋁合金，多股導線再繞成一條電纜，電纜中間亦可能加入鋼纜以強化電纜強度，才能走得更遠，使用時需視產品調整長度。',
    categories: ['結構重組', '此項材料循環商品開發中'],
    ans: 2,
    correct: <Text>謝謝你協助蒐集材料進入設計階段，商品開發中，敬請期待！</Text>
  },
  {
    img: intro6,
    pic: tutorial6,
    picMobile: tutorial6_m,
    game: game6,
    finish: game6_2,
    name: '人孔蓋',
    intro: '遮蓋馬路上人孔洞之圓形蓋板，台電人孔蓋覆有十字紋，以鑄鐵材質製作，厚實沉重。承重度與硬度皆為最高標準，不易裁切因而提高設計商品之難度。',
    categories: ['結構重組', '此項材料循環商品開發中'],
    ans: 2,
    correct: <Text>謝謝你協助蒐集材料進入設計階段，商品開發中，敬請期待！</Text>
  },
  {
    img: intro7,
    pic: tutorial7,
    picMobile: tutorial7_m,
    game: game7,
    finish: game7_2,
    name: '木橫擔',
    intro: '電力設備無法直接安裝於電線桿本體，需放置在木橫擔上，所有荷重皆由其一肩承擔。可用一般木工工法加工，但木料硬度高，刀具裁切時也是一大考驗。',
    categories: ['結構重組', 'WhimsE010閱讀燈'],
    link: 'https://www.tpcreativeshop.com/collections/e010',
    ans: 2,
    correct: <Text>恭喜你運用<Span>鋸子</Span>協助切割材料！<Span>木橫擔</Span>經過設計重組後，變成了<Span>WhimsE010床頭閱讀燈</Span>！</Text>
  },
  {
    img: intro8,
    pic: tutorial8,
    picMobile: tutorial8_m,
    game: game8,
    finish: game8_2,
    name: '變電箱',
    intro: '彩繪變電箱是臺灣街頭的獨特風景，不同地區各有特色。過去皆以熔鑄方式回收利用。台電文創再製時僅以最簡約方式切割造型，保留這些獨一無二的印記。',
    categories: ['結構重組', '台電熱墊/山水徽章/置物盤'],
    link: 'https://www.tpcreativeshop.com/collections/transformerbox',
    ans: 2,
    correct: <Text>恭喜你運用<Span>鋸子</Span>協助切割材料！<Span>變電箱</Span>經過設計重組後，變成了<Span>台電熱墊</Span>！</Text>
  },
  {
    img: intro9,
    pic: tutorial9,
    picMobile: tutorial9_m,
    game: game9,
    finish: game9_2,
    name: '電表玻璃',
    intro: '回收的電表玻璃大多狀況良好，簡單清潔即可投入製程。為了保護設備，電表玻璃的材質厚實，與清透視覺感形成強烈反差，但要保留那份手感可不容易！',
    categories: ['結構重組', '電表玻璃獎座'],
    link: 'https://www.tpcreativego.com/54年歷史台電技能競賽獎座設計，退役電表玻璃化身/',
    ans: 0,
    correct: <Text>恭喜你運用<Span>刷子</Span>協助清潔！<Span>電表玻璃</Span>經過設計重組後，變成了<Span>電表玻璃獎座</Span>！</Text>
  },
]


const Game = ({ isOpen, onClose, finish, setFinish }) => {
  const [play, setPlay] = useState()
  const data = questions[isOpen] || []
  const { isMobile, isLaptop } = useResponsive()
  return (
    <CustomModal scrollBehavior="inside" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalContent
        my={responsive('0')}
        maxW={responsive('30em', '36em', '60%', null, '66.5em')}
        overflow="hidden"
        border="3px solid"
        borderRadius={'1.25rem'}
        color="textBlue"
        width={responsive('91.8%', 'auto')}
        maxH={responsive('86vh', '95vh')}
        p="0"
      >
        <CustomModalCloseButton
          top={responsive('0.5rem', null, null, null, '0.75rem')}
          right={responsive('0.5rem', null, null, null, '1rem')}
          onClick={() => {
            onClose()
            setPlay(false)
          }}
        />
        <ModalBody p={responsive('2.25em 2.75em 2.25em 1.5em', null, '2em 2.5em', null, '3.75em')}>
          {isOpen === 10 ? (
            <MaterialList questions={questions} />
          ) : (
            <Flex flexDir={responsive('column', null, 'row')}>
              <Box>
                <Flex alignItems={'center'} borderRadius={'full'} border="3px solid" justifyContent={'center'} py={responsive('0.25em', '0.3125em')}>
                  <Icon as={GoSearch} w={responsive(6, 6)} h={responsive(6, 6)} />
                  <Text ml={responsive('0.125rem', '0.375rem')} variant="btn">
                    材料小知識
                  </Text>
                </Flex>
                <Stack spacing={'14px'}>
                  <Box mx="auto" minW={responsive('10em')} width={responsive('72%', '63.25%')} pt={responsive('1.5em', '1.625em')}>
                    <BackgroundImage src={data?.img} ratio={330 / 180} />
                  </Box>
                  <Box pt={'0.5em'} pb={responsive('0.75em')} borderBottom="3px dashed #001133">
                    <Text variant={'h2'}>{data.name}</Text>
                  </Box>
                  <Text>{data.intro}</Text>
                  <Type data={data} />
                  {data.link && !isLaptop && (
                    <Box pt={responsive('0.875em', null, 0, null, '0.875em')} textAlign={'center'}>
                      <SpecialButton variant={'orange'} href={data.link}>{data.btn || '看更多商品細節'}</SpecialButton>
                    </Box>
                  )}
                </Stack>
              </Box>
              {(play || finish?.[isOpen]?.length) ? (
                <Play data={data} finish={finish} setFinish={setFinish} isOpen={isOpen} onClose={onClose} />
              ) : (
                <Stack
                  mt={responsive('0.625em', 0)}
                  ml={responsive(0, null ,'3em')}
                  px={responsive('1.625em', '2em')}
                  pt={responsive('2.125em')}
                  pb={responsive('2.125em', '2.875em')}
                  spacing={responsive(5, 6)}
                  border="3px solid"
                  borderColor="textBlue"
                  borderRadius={'10px'}
                  bg="paleBlue"
                  minWidth={responsive('auto', null, '50.9%', null, '33.875em')}
                  transform="translateZ(0)"
                >
                  <Box width={responsive('100%', '94%')} mx="auto">
                    <BackgroundImage src={isMobile ? tutorial_title_m : tutorial_title} ratio={isMobile ? 381 / 105 : 666 / 147} />
                  </Box>
                  <Text textAlign={'center'} variant="h3">選擇適合的工具，幫助退役材料重生！</Text>
                  <Box borderRadius={'1.25em'} border="3px dashed" overflow={'hidden'}>
                    <BackgroundImage src={isMobile ? data.picMobile : data.pic} ratio={isMobile ? 630 / 447 : 564 / 363} />
                  </Box>
                  <Box textAlign="center">
                    <SpecialButton onClick={() => setPlay(true)} width={responsive('100%', null, '10em', null,'15.5rem')} py={'0.5rem'} variant={'yellow'}>
                      <BackgroundImage width={responsive('6.75rem', null, '4.5em', null,'6.75em')} src={start} ratio={159 / 42} />
                    </SpecialButton>
                  </Box>
                </Stack>
              )}
              </Flex>
          )}

          {data.link && isLaptop && (
            <Box pt="1.5em" textAlign={'center'}>
              <SpecialButton variant={'orange'} href={data.link}>{data.btn || '看更多商品細節'}</SpecialButton>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </CustomModal>
  )
}

export default Game
