import { css } from '@emotion/react'
import open from './jf-openhuninn.ttf'

export default css`
  @font-face {
    font-family: open;
    src: url(${open});
  }

  html {
    height: 100%
  }

  body {
    min-width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }

  #root {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`;
