import React from 'react'
import { Box } from '@chakra-ui/react'
import Button from '../Button'
import BackgroundImage from '../BackgroundImage'
import { responsive } from '../ThemeProvider/theme'

import btn_correct from './btn_correct.png'
import btn_wrong from './btn_wrong.png'

const SpecialButton = ({ children, variant, borderRadius, img, isCorrect, width, disabled, opacity, ...props }) => {
  return (
    <Box pos={'relative'} display="inline-block" opacity={((disabled && !isCorrect) && 0.7) || opacity }>
      <Box
        borderRadius={borderRadius || "1.375em"}
        pos="absolute"
        top={borderRadius ? '9px' : '5px'}
        left={0}
        right={0}
        bottom={borderRadius ? '-9px' : '-5px'}
        bg="textBlue"
      />
      <Button variant={variant} width={width} borderRadius={borderRadius} disabled={disabled} {...props}>
        {children}
      </Button>
      {disabled && (isCorrect ? (
        <Box pos="absolute" top={responsive('0.25em', '0.375em')} left={responsive('0.25em', '0.375em')} width={responsive('1.375rem', '1.875rem')}>
          <BackgroundImage src={btn_correct} ratio={1} />
        </Box>
      ) : (
        <Box pos="absolute" top={responsive('0.25em', '0.375em')} left={responsive('0.25em', '0.375em')} width={responsive('1.375rem', '1.875rem')}>
          <BackgroundImage src={btn_wrong} ratio={1} />
        </Box>
      ))}
    </Box>
  )
}

export default SpecialButton
