import React from 'react';

import HomePage from './containers/HomePage'

function App() {
  return (
    <HomePage />
  );
}

export default App;
