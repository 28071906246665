import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import get from 'lodash/get';

const chakraBp = createBreakpoints({
  sm: "30em",
  md: "48.125em",
  lg: "60em",
  xl: "80em",
  "2xl": "96em",
})

export const breakpoints = Object.values(chakraBp).slice(1)

export const responsiveIndex = [
  [1, 'mobile'],
  [2, 'tablet'],
  [3, 'laptop'],
  [4, 'desktop'],
]

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

export const responsive = (...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
};

export const mobileOrDesktop = responsive

export const containerWidth = [null, "48em", "60em", "80em"];
export const containerPadding = responsive('1em', '2em')

const font = '"open", Arial, "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
}

const primary = 'blue'
const secondary = 'green'
const danger = 'red'

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    primary: get(theme.colors, `${primary}.500`),
    secondary: get(theme.colors, `${secondary}.500`),
    danger: get(theme.colors, `${danger}.500`),
    text: get(theme.colors, 'black'),
    skyBlue: '#BBE4EC',
    paleYellow: '#FFE480',
    textBlue: '#001133',
    paleGray: '#E6E6E6',
    paleBlue: '#BBE4EC',
    borderGray: '#ABABAB',
    normalBlue: '#3085CC',
    normalYellow: '#FFC900',
    normalOrange: '#FF8900',
  },
  breakpoints,
  containerWidth,
  headerHeight: '5em',
  components: {
    Text: {
      baseStyle: {
        color: 'textBlue',
        fontSize: responsive('1rem', null, '0.875rem', null, '1.125rem')
      },
      variants: {
        h1: {
          fontSize: responsive('2.25rem', null, '1.875rem', null, '2.5rem')
        },
        h2: {
          fontSize: responsive('1.875rem', null, '1.5rem', null, '2rem')
        },
        h3: {
          fontSize: responsive('1.5rem', null, '1.375rem', null, '1.625rem')
        },
        h4: {
          fontSize: responsive('1.125rem', null, '1rem', null, '1.25rem')
        },
        mission_title: {
          fontSize: responsive('1.375rem', null, '1.25rem', null, '1.625rem')
        },
        btn: {
          fontSize: responsive('1.125rem', null, '1rem', null, '1.375rem'),
        }
      }
    },
    Button: {
      baseStyle: {
        color: 'textBlue',
        fontWeight: 400,
        border: '2px solid',
        lineHeight: 1.5
      },
      variants: {
        orange: {
          _hover: {
            transform: 'translateY(5px)',
          },
          borderRadius: '1.375rem',
          fontSize: responsive('1.125em', null, '1em', null, '1.375em'),
          px: '1.625rem',
          py: responsive('0.65625rem', null, '0.375rem', null, '0.65625rem'),
          bg: 'normalOrange',
          height: 'auto',
          width: 'auto',
        },
        yellow: {
          _hover: {
            transform: 'translateY(5px)',
          },
          borderRadius: '1.375rem',
          fontSize: responsive('1.125em', null, '1em', null, '1.375em'),
          px: '1.625rem',
          py: responsive('0.375rem', null, '0.25rem', null, '0.375rem'),
          bg: 'normalYellow',
          height: 'auto',
          width: 'auto',
        }
      }
    }
  }
}

const customTheme = extendTheme(overrides)

export default customTheme
