import React from 'react'
import PropTypes from 'prop-types'

import Box from '../components/Box';

const Layout = ({ children }) => {
  return (
    <>
      {/* {!hideHeader && <Header height={theme.headerHeight} siteTitle="Styled Create React App" />} */}
      <Box>
        {children}
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
