import React from 'react'
import SVG from '../../components/SVG'

const Replay = (props) => {
  return (
    <SVG {...props} viewBox="0 0 44 44">
      <g>
        <g>
          <path d="M35.75,22c0-2.97-.97-5.86-2.75-8.24-1.78-2.38-4.29-4.11-7.14-4.95-2.85-.83-5.9-.72-8.68,.32-2.78,1.04-5.15,2.96-6.76,5.46-1.6,2.5-2.35,5.46-2.14,8.42,.22,2.96,1.39,5.78,3.34,8.02,1.95,2.24,4.57,3.79,7.48,4.42,2.9,.63,5.93,.3,8.64-.94,.33-.13,.7-.14,1.03,0s.6,.38,.75,.71c.15,.32,.17,.69,.05,1.03s-.36,.61-.67,.77c-3.24,1.49-6.88,1.89-10.36,1.13-3.49-.75-6.63-2.61-8.97-5.31s-3.74-6.07-4-9.63c-.26-3.56,.64-7.1,2.57-10.1,1.92-3,4.77-5.3,8.11-6.55,3.34-1.25,7-1.38,10.42-.38,3.42,1,6.43,3.09,8.57,5.94,2.14,2.85,3.29,6.32,3.29,9.89h-2.75Z" fill="#001133" fill-rule="evenodd" stroke="#001133" stroke-width="1.5"/>
          <path d="M31.72,22h10.81c.13,0,.26,.04,.37,.11,.11,.07,.2,.17,.25,.29,.06,.12,.08,.25,.06,.38-.02,.13-.07,.25-.15,.35l-5.41,6.49c-.28,.33-.78,.33-1.06,0l-5.41-6.49c-.08-.1-.14-.22-.15-.35-.02-.13,0-.26,.06-.38,.06-.12,.14-.22,.25-.29,.11-.07,.24-.11,.37-.11h0Z" fill="#013" stroke="#013" stroke-width="1.5"/>
          <rect width="44" height="44" fill="none"/>
        </g>
      </g>
    </SVG>
  )
}

export default Replay
