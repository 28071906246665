import React from 'react'
import { Box, Circle, keyframes, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import BackgroundImage from '../../components/BackgroundImage'
import CustomModal from '../../components/CustomModal'
import triangle from './triangle.png'
import swipe from './swipe.png'
import { responsive } from '../../components/ThemeProvider/theme'

const anime = keyframes`
  50% {
    transform: rotate(10deg);
  }
`

const Swipe = styled(Box)`
  transform-origin: bottom center;
  animation: ${anime} 2s infinite;
`

const Guide = ({ isOpen, onClose }) => {
  return (
    <CustomModal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      // overlyChildren={}
    >
      <ModalContent boxShadow={'none'} bg="transparent" width="auto">
        <Box pos={'relative'} bg="normalYellow" display={'inline-block'} p="0.75em 1em" borderRadius="10px">
          <Text fontSize={responsive('3.86vw', '1em', 0)}>
            點擊空白處開始遊戲<br />
            左右滑動尋找材料
          </Text>
          <Box pos="absolute" top="100%" left="50%" transform={'translate(-50%, -1px)'} width={responsive("5.55vw", '1.5em')}>
            <BackgroundImage src={triangle} ratio={23 / 20} />
          </Box>
        </Box>
        <Circle mx="auto" bg="rgba(255, 255, 255, 0.7)" border="5px solid" size="9.625em" mt={"7.246vw"}>
          <Box width="100%">
            <Swipe transform="rotate(-10deg)">
              <BackgroundImage src={swipe} ratio={1} />
            </Swipe>
          </Box>
        </Circle>
      </ModalContent>
      <Box
        width="14em"
        pos={'absolute'}
        bg="normalYellow"
        textAlign={'center'}
        display={'inline-block'}
        p="0.625em 1em"
        borderRadius="10px"
        fontSize={responsive('3.86vw', '1em', 0)}
        bottom={responsive('18vw' , '80px', 0)}
        right="3.38vw"
        zIndex={'var(--chakra-zIndices-modal)'}
      >
        <Box pos="absolute" top="100%" right={responsive("4vw", '24px')} transform={'translateY(-1px)'} width={responsive("5.55vw", '1.5em')}>
          <BackgroundImage src={triangle} ratio={23 / 20} />
        </Box>
        點一下了解材料蒐集進度，
        再點一下繼續尋找！
      </Box>
    </CustomModal>
  )
}

export default Guide
