import styled from '@emotion/styled'
import React, { useEffect, useMemo, useRef } from 'react'
import range from 'lodash/range';
import SVG from '../components/SVG'
import sampleSize from 'lodash/sampleSize';
import useResponsive from '../contexts/mediaQuery/useResponsive';

const StyledSVG = styled(SVG)`
  .clickable {
    cursor: pointer;
  },
`

const animateCircleM = (delay) => <animate attributeName="r" begin={delay} repeatCount="indefinite" from="50" to="230" dur="2s"/>
const animateCircleL = (delay) => <animate attributeName="r" begin={delay} repeatCount="indefinite" from="50" to="280" dur="2s"/>
const animateOpacity = (delay) => <animate attributeName="opacity" keySplines= "0.42 0 1 1" keyTimes="0;1" begin={delay} repeatCount="indefinite" from="0.2" to="0" dur="2s"/>

const challenges = [
  {
    tag : (
      <g id="_04">
        <g>
          <g>
            <rect x="1401.27" y="373.18" width="192.14" height="75.92" rx="4.8" ry="4.8" transform="translate(735.46 1764.14) rotate(-75.97)" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1409.59" y="381.13" width="175.5" height="60.03" transform="translate(735.47 1764.14) rotate(-75.97)" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <rect x="1384.12" y="324.26" width="75.92" height="192.14" rx="4.8" ry="4.8" transform="translate(82.9 1057.18) rotate(-41.9)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1381.05,328.2l-39.04,35.03,23.29,50.71,82.9,92.41,29.27-13.44c3.49-1.6,5.01-5.73,3.41-9.21l-51.4-111.89-38.63-43.06c-2.56-2.86-6.95-3.09-9.81-.53v-.02Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <rect x="1372.22" y="315.29" width="75.92" height="192.14" rx="4.8" ry="4.8" transform="translate(-42.99 626.14) rotate(-24.67)" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1380.17" y="323.61" width="60.03" height="175.5" transform="translate(-42.98 626.14) rotate(-24.67)" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1537.14,408.54l-12.41-89.66-34.16-8.53c-3.72-.93-7.49,1.33-8.42,5.06l-22.23,88.99,10.69,77.26c.53,3.8,4.03,6.45,7.83,5.93l40.35-5.58,18.35-73.46h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <rect x="1314.07" y="376.68" width="192.14" height="75.92" rx="4.8" ry="4.8" transform="translate(666 1682.19) rotate(-75.97)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1321.14" y="364.46" width="192.14" height="75.92" rx="4.8" ry="4.8" transform="translate(683.22 1679.79) rotate(-75.97)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1329.46" y="372.41" width="175.5" height="60.03" transform="translate(683.22 1679.8) rotate(-75.97)" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <rect x="1365.8" y="409.05" width="96.25" height="13.07" transform="translate(667.96 1686.59) rotate(-75.97)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <circle cx="1431.69" cy="344.56" r="14.42" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1470.63,316.67l-31.67-7.91c-.05,.51-.04,1.04,.03,1.57l14.39,104.02,22.3-89.25c.93-3.72-1.33-7.49-5.06-8.42h.01Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <rect x="1454.8" y="292.01" width="75.92" height="192.14" rx="4.8" ry="4.8" transform="translate(-39.11 208.32) rotate(-7.88)" fill="#3185cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1454.8" y="292.01" width="75.92" height="192.14" rx="4.8" ry="4.8" transform="translate(-39.11 208.32) rotate(-7.88)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1479.16,311.27l-13.42,33.02c-.98,2.4,1.01,4.96,3.58,4.6l35.3-4.88c2.57-.36,3.79-3.36,2.2-5.4l-21.88-28.13c-1.59-2.05-4.8-1.6-5.78,.8h0Z" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1486.26,341.37c-.63,.09-1.19-.06-1.67-.45-.48-.39-.77-.91-.86-1.56s.04-1.23,.4-1.74c.36-.51,.85-.8,1.48-.89s1.21,.06,1.69,.45,.77,.91,.86,1.55-.04,1.24-.4,1.75c-.34,.5-.85,.8-1.5,.89h0Zm-.93-6.93c-.99,.14-1.6-.26-1.81-1.19l-1.6-7.34-.32-1.74c-.27-1.58,.38-2.47,1.94-2.69,1.55-.21,2.41,.47,2.57,2.07l.16,1.76,.27,4.46,.18,3.03c.06,.95-.4,1.49-1.4,1.63h.01Z" fill="#013" stroke="#013" stroke-width=".52"/>
            <rect x="1462.74" y="300.33" width="60.03" height="175.5" transform="translate(-39.11 208.32) rotate(-7.88)" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <rect x="1479.3" y="363.15" width="13.07" height="96.25" transform="translate(-42.36 207.59) rotate(-7.88)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <rect x="1494.64" y="360.84" width="13.07" height="56.6" transform="translate(-39.18 209.48) rotate(-7.88)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1356.81,500.27l60.18,15.03c3.72,.93,7.49-1.33,8.42-5.06l.05-.19,18.47-8.49,4.28,4.77,5.18,5.77c2.56,2.86,6.95,3.09,9.81,.53l9.66-8.67,31.33,7.83c3.72,.93,7.49-1.33,8.42-5.06l6.18-24.74,.12-.47,17.73-2.45c3.8-.53,6.45-4.03,5.93-7.83l-7.52-54.33,2.09-8.38h0l5.35-21.42-200.38,.95,23.19,25.85h0l4.63,5.16-18.18,72.76c-.93,3.72,1.33,7.49,5.06,8.42v.02Z" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <g>
            <rect x="1324.34" y="392.45" width="244.51" height="135.03" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="1324.34" y1="413.91" x2="1568.86" y2="413.91" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <rect x="1324.34" y="392.45" width="244.51" height="21.46" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <g>
              <line x1="1479.68" y1="425.59" x2="1479.68" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1512.52" y1="425.59" x2="1512.52" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1545.36" y1="425.59" x2="1545.36" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1446.84" y1="425.59" x2="1446.84" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1414" y1="425.59" x2="1414" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1381.16" y1="425.59" x2="1381.16" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1348.32" y1="425.59" x2="1348.32" y2="515.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
            <g>
              <line x1="1335.82" y1="440.19" x2="1555.29" y2="440.19" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1335.82" y1="470.7" x2="1555.29" y2="470.7" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1335.82" y1="501.21" x2="1555.29" y2="501.21" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 4
  },
  {
    tag : (
      <g id="_05">
        <g>
          <path d="M1746.66,292.95l-33.55-3.59c2.21-20.62,9.88-38.42,22.79-52.89,10.11-11.33,23.3-20.44,39.22-27.08,26.03-10.85,50.87-11.41,51.91-11.43l.6,33.73h.05c-.2,0-20.23,.57-40.39,9.18-11,4.7-19.93,10.89-26.51,18.39-8.06,9.17-12.67,20.19-14.11,33.68h0Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1737.38,291.94c.11-33.75,4.17-53.56,7.7-64.45-3.3,2.81-6.37,5.8-9.19,8.96-12.91,14.47-20.58,32.27-22.79,52.89l24.28,2.6h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <ellipse cx="1841.19" cy="206.68" rx="7.21" ry="19.1" transform="translate(1.34 424.94) rotate(-13.17)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1814.36" y="190.67" width="27.19" height="38.21" transform="translate(.28 422) rotate(-13.17)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <ellipse cx="1814.84" cy="212.85" rx="7.21" ry="19.1" transform="translate(-.76 419.09) rotate(-13.17)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1834.2,208.42c-1.21-5.17-1.51-10.03-1.01-13.7" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1839.64,221.56c-2.18-3.07-4.19-7.73-5.45-13.14" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <g>
            <ellipse cx="1685.41" cy="216.25" rx="19.1" ry="7.21" transform="translate(-4.06 34.46) rotate(-1.17)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1703.16,294.5l-33.74,.15c-.34-74.31-.39-95.38-.39-95.58l33.74-.08c0,.2,.06,21.24,.39,95.5Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <ellipse cx="1684.82" cy="188.61" rx="19.1" ry="7.21" transform="translate(-3.5 34.44) rotate(-1.17)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <path d="M1704.46,215.46h0v0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1666.27,216.11v.12h0s0-.09,0-.12Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1685.22,208.51c10.55-.22,19.16,2.83,19.24,6.82l-.55-27.06-38.2,.78,.55,27.06c-.08-3.98,8.4-7.38,18.95-7.59h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </g>
          </g>
          <g>
            <path d="M1675.02,221.05c-.27-.94-1.03-1.7-2.16-2.26-.06-.26-.11-.51-.17-.77,1.14,.56,1.91,1.33,2.17,2.27l-5.83-20.51c0,4.16,.09,27.85,.39,94.88l10.03-.05c-.5-34.16-2.88-56.1-5.43-70,.92-1.26,1.31-2.48,.99-3.57h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1674.07,220.47l-.04-.12s.02,.09,.03,.12h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1637.32,230.91h0s-.03-.08-.04-.12l.04,.12Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <ellipse cx="1652.5" cy="226.71" rx="19.1" ry="7.21" transform="translate(.95 460.24) rotate(-15.86)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1675.39,303.94l-33.74,.15c-.31-66.63-7.69-84.47-8-85.2,0,0,.11,.27,.36,.72l29.69-16.04c2.66,4.92,11.36,26.7,11.7,100.36h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1653.59,291.27l30.18-15.1c-13.75-27.47-26.17-45.81-36.6-58.06-15.64-18.36-39.85,8.4-39.85,8.4-.8-.22-1.2-.39-1.22-.39,.21,.1,19.38,8.98,47.49,65.15Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <ellipse cx="1644.94" cy="199.9" rx="19.1" ry="7.21" transform="translate(7.99 457.15) rotate(-15.86)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <path d="M1670.73,220.74h0l-.04-.12s.02,.09,.03,.12h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1633.96,231.06l.04,.12h0s-.03-.08-.04-.12Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1650.37,218.91c10.15-2.88,19.25-2.12,20.35,1.71l-7.4-26.04-36.75,10.44,7.4,26.04c-1.09-3.82,6.25-9.27,16.4-12.15h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </g>
          </g>
          <g>
            <path d="M1703.16,294.5c-.16-35.34-.26-58.61-.31-73.34-4.58,15.58-8.3,38.87-8.27,73.38l8.58-.04Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1739.35,225.04l.06-.11s-.04,.08-.06,.11h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <ellipse cx="1726.02" cy="217.15" rx="7.21" ry="19.1" transform="translate(808.27 1695) rotate(-65.33)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1732.53,295.86l-33.74,.15c-.35-76.76,17.7-98.96,23.24-103.94l22.54,25.11c.62-.55,.9-.89,.91-.91-.14,.18-13.23,16.77-12.95,79.58h0Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <ellipse cx="1737.53" cy="192.02" rx="7.21" ry="19.1" transform="translate(837.81 1690.81) rotate(-65.33)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <path d="M1743.54,224.7h0l.06-.11s-.04,.08-.06,.11Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1708.88,208.65l-.06,.11h0s.03-.08,.05-.11h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1729.25,210.07c9.58,4.41,16.01,10.91,14.35,14.52l11.3-24.6-34.72-15.94-11.3,24.6c1.66-3.61,10.77-2.97,20.37,1.42Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </g>
          </g>
          <g>
            <ellipse cx="1618.73" cy="218.14" rx="19.1" ry="7.21" transform="translate(440.73 1349.59) rotate(-51.52)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1648.46,291.27l30.18-15.1c-34.36-68.65-60.49-80.3-67.68-82.24l-8.77,32.58c-.8-.22-1.2-.39-1.22-.39,.21,.1,19.38,8.98,47.49,65.15h0Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <ellipse cx="1597.11" cy="200.91" rx="19.1" ry="7.21" transform="translate(446.05 1326.15) rotate(-51.52)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <path d="M1606.5,232.75h0l-.1-.08s.07,.05,.1,.08h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1630.16,202.77l.1,.08h0s-.06-.05-.1-.08Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1612.64,213.24c-6.56,8.26-9.36,16.96-6.25,19.43l-21.2-16.85,23.77-29.9,21.2,16.85c-3.12-2.48-10.97,2.21-17.53,10.47h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </g>
          </g>
          <path d="M1771.56,290.64l-31.82-11.23c8.65-24.49,30.64-42.69,63.61-52.59,23.01-6.91,43.69-7.49,44.57-7.51l.83,33.73-.41-16.87,.45,16.87c-.17,0-17.62,.55-36.36,6.29-22.17,6.8-35.92,17.33-40.86,31.31h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1775.95,294.39l-31.82-11.23c8.65-24.49,30.64-42.69,63.61-52.59,23.01-6.91,43.69-7.49,44.57-7.51l.83,33.73-.41-16.87,.45,16.87c-.17,0-17.62,.55-36.36,6.29-22.17,6.8-35.92,17.33-40.86,31.31h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1641.07,277.18c.32,7.94,.52,16.87,.57,26.92l25.4-.11c0-1.03-.03-2.05-.04-3.05l8.36-.04c-.02-2.13-.04-4.21-.08-6.25h.04l4.12-.02,19.33-.09v1.49l33.74-.15v-4.43l4.85,.52,9.28,.99c.31-2.92,.78-5.71,1.39-8.4l27.89,9.84c4.94-13.99,18.68-24.52,40.86-31.31,2.22-.68,4.42-1.29,6.58-1.83h-191.69c2.97,4.68,6.09,9.97,9.38,15.93h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <ellipse cx="1861.38" cy="239.24" rx="7.21" ry="19.1" transform="translate(-13.05 147.11) rotate(-4.51)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1834.23" y="221.2" width="27.19" height="38.21" transform="translate(-13.17 146.04) rotate(-4.51)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <ellipse cx="1834.4" cy="241.36" rx="7.21" ry="19.1" transform="translate(-13.3 144.99) rotate(-4.51)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1854.19,239.95c-.42-5.29,.02-10.15,1.07-13.7" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1857.6,253.76c-1.7-3.37-2.97-8.28-3.41-13.81" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <g>
            <g>
              <rect x="1632.53" y="265.46" width="188.86" height="133.12" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="1632.53" y="265.46" width="188.86" height="18.53" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <path d="M1751.63,312.61c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22,10.22-15.35,10.22-24.68-3.63-18.09-10.22-24.68Z" fill="#fff"/>
              <polygon points="1749.28 339.07 1742.74 347.55 1742.74 343.68 1728.07 364.17 1739.7 351.43 1739.6 355.49 1745.96 346.82 1746.14 348.94 1753.91 338.24 1748.27 342.85 1749.28 339.07" fill="#e50012"/>
              <polygon points="1742.74 331.13 1742.56 327.25 1749.2 335.46 1748.37 331.69 1753.91 336.39 1745.87 325.32 1745.87 327.71 1739.42 319.03 1739.6 322.91 1727.79 310.45 1742.74 331.13" fill="#e50012"/>
              <polygon points="1704.98 335.83 1711.45 327.61 1711.45 331.31 1726.11 310.55 1714.31 323.55 1714.58 319.22 1708.03 328.08 1708.03 325.77 1700.28 336.76 1705.73 332.05 1704.98 335.83" fill="#e50012"/>
              <polygon points="1711.45 343.68 1711.63 347.37 1704.98 339.15 1706 343.13 1700.28 338.14 1708.21 349.21 1708.21 346.82 1714.76 355.59 1714.58 351.53 1726.3 364.17 1711.45 343.68" fill="#e50012"/>
              <g>
                <path d="M1751.63,312.61c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22,10.22-15.35,10.22-24.68-3.63-18.09-10.22-24.68Zm-24.68,57.87c-18.31,0-33.19-14.89-33.19-33.19s14.88-33.19,33.19-33.19,33.19,14.88,33.19,33.19-14.88,33.19-33.19,33.19Z" fill="#0069ab"/>
                <path d="M1726.86,305.3c-17.59,0-31.9,14.31-31.9,31.9s14.31,31.9,31.9,31.9,31.91-14.31,31.91-31.9-14.3-31.9-31.91-31.9Zm0,62.24c-16.73,0-30.34-13.61-30.34-30.34s13.61-30.34,30.34-30.34,30.35,13.62,30.35,30.34-13.61,30.34-30.35,30.34Z" fill="#0069ab"/>
                <path d="M1726.86,308.22c-15.98,0-28.98,13.01-28.98,28.99s13.01,29,28.98,29,29-13.01,29-29-13.01-28.99-29-28.99Zm0,56.56c-15.2,0-27.57-12.37-27.57-27.57s12.37-27.57,27.57-27.57,27.58,12.37,27.58,27.57-12.37,27.57-27.58,27.57Z" fill="#0069ab"/>
                <path d="M1727.69,335.67h10.15l-.72-1.83h-20.19l-.82,1.83h9.74v1.49h-10.26l2.97,6.9h2.03l-2.12-5.02h1.79l2.24,5.02,1.97-.02-2.13-5.05,3.51-.02v6.51h-6.81l3.68,8.29h3.17v7.23l1.37,2.98,3.6-8.67h-2.02l-1.06,2.79v-4.33h3.7l3.39-8.29-7.19-.03v-6.48l4,.02-2.22,5.05h2.07l2.12-5.08h2.12l-2.12,5.04,1.83,.03,2.91-6.87-10.7-.02v-1.47h0Zm-5.02,13.04l-.68-1.45h3.84v1.45h-3.17Zm3.17,3.34l-1.72-.02-.72-1.54,2.46,.02-.03,1.54h0Zm4.43,0h-2.57v-1.54s3.18,0,3.18,0l-.6,1.54Zm1.99-4.72l-.65,1.42h-3.92v-1.42s4.57,0,4.57,0Z" fill="#0069ab"/>
                <path d="M1732.59,323.87h-11.29l-3.4,7.81h18.18l-3.5-7.81h0Zm-10.05,1.8h8.9l1.8,4.12h-12.44l1.74-4.12h0Z" fill="#0069ab"/>
                <polygon points="1728.98 315.48 1727.96 317.72 1729.2 320.46 1724.78 320.46 1728.05 313.31 1727.02 310.85 1722.04 322.27 1731.94 322.27 1728.98 315.48" fill="#0069ab"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 5,
  },
  {
    tag : (
      <g id="_09">
        <g>
          <g>
            <g>
              <path d="M1825.72,295.15c10.19,1.42,19.34,5.31,27.43,11.67,5.31,4.56,9.95,16.07,9.29,23.04-1.42,10.19-5.31,19.34-11.67,27.43-4.56,5.31-16.07,9.95-23.04,9.29-10.19-1.42-19.34-5.31-27.43-11.67-5.31-4.56-9.95-16.07-9.29-23.04,1.42-10.19,5.31-19.34,11.67-27.43,4.56-5.31,16.07-9.95,23.04-9.29Z" fill="#bbe4ec" isolation="isolate" opacity=".8"/>
              <path d="M1825.72,295.15c10.19,1.42,19.34,5.31,27.43,11.67,5.31,4.56,9.95,16.07,9.29,23.04-1.42,10.19-5.31,19.34-11.67,27.43-4.56,5.31-16.07,9.95-23.04,9.29-10.19-1.42-19.34-5.31-27.43-11.67-5.31-4.56-9.95-16.07-9.29-23.04,1.42-10.19,5.31-19.34,11.67-27.43,4.56-5.31,16.07-9.95,23.04-9.29Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g opacity=".57">
              <path d="M1826,304.96c7.39,1.03,14.02,3.85,19.89,8.46,3.85,3.31,7.22,11.66,6.74,16.71-1.03,7.39-3.85,14.02-8.46,19.89-3.31,3.85-11.66,7.22-16.71,6.74-7.39-1.03-14.02-3.85-19.89-8.46-3.85-3.31-7.22-11.66-6.74-16.71,1.03-7.39,3.85-14.02,8.46-19.89,3.31-3.85,11.66-7.22,16.71-6.74h0Z" fill="#fff"/>
            </g>
            <g clip-path="url(#clippath-2)">
              <g>
                <path d="M1852.63,330.13c.48-5.05-2.89-13.4-6.74-16.71-5.87-4.61-12.5-7.43-19.89-8.46,0,0,4.5-8.73-10.17-12.37,0,0,30.75-1.94,31.29-1.78s25,21.53,25,21.53c0,0,2.53,18.5-1.87,21.73-11.3-11.16-17.63-3.94-17.63-3.94h.01Z" fill="#fff"/>
                <path d="M1800.83,331.59c-.48,5.05,2.89,13.4,6.74,16.71,0,0-10.15-3.16-9.51,6.08l-12.27-15.35s-.55-11.3,2.33-12.85c5.1,9.09,12.72,5.41,12.72,5.41h0Z" fill="#fff"/>
                <g clip-path="url(#clippath-3)">
                  <path d="M1900.2,301.94c-4.25-2.2-8.54-4.39-12.86-6.58-23.63,18.53-55.24,37.98-94.24,54.41,3.27,2.05,6.51,4.08,9.71,6.1,40-16,72.67-35.28,97.39-53.92h0Z" fill="#fff" isolation="isolate" opacity=".66"/>
                </g>
              </g>
            </g>
            <path d="M1825.72,295.15c10.19,1.42,19.34,5.31,27.43,11.67,5.31,4.56,9.95,16.07,9.29,23.04-1.42,10.19-5.31,19.34-11.67,27.43-4.56,5.31-16.07,9.95-23.04,9.29-10.19-1.42-19.34-5.31-27.43-11.67-5.31-4.56-9.95-16.07-9.29-23.04,1.42-10.19,5.31-19.34,11.67-27.43,4.56-5.31,16.07-9.95,23.04-9.29Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1826,304.96c7.39,1.03,14.02,3.85,19.89,8.46,3.85,3.31,7.22,11.66,6.74,16.71" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1807.57,348.3c-3.85-3.31-7.22-11.66-6.74-16.71" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <rect x="1811.88" y="263.67" width="79.03" height="80.48" rx="15" ry="15" transform="translate(1245.15 2079.44) rotate(-80.47)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <path d="M1860.92,338.52c-10.41,.97-20.37-.7-29.89-5.02-6.34-3.21-13.69-13.33-14.71-20.26-1.05-10.24,.6-20.02,4.93-29.35,3.23-6.21,13.48-13.38,20.52-14.34,10.41-.97,20.37,.7,29.89,5.02,6.34,3.21,13.69,13.33,14.71,20.26,1.05,10.24-.6,20.02-4.93,29.35-3.23,6.21-13.48,13.38-20.52,14.34Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <g>
                  <ellipse cx="1827.25" cy="295.06" rx="5.32" ry="5.42" transform="translate(1233.74 2048.24) rotate(-80.47)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <g>
                    <line x1="1829.73" y1="299.8" x2="1827.22" y2="295.3" fill="none"/>
                    <path d="M1829.73,299.8l-3.6-3.9c-.48-.52-.45-1.34,.07-1.82,.68-.66,1.88-.3,2.11,.6l1.42,5.11h0Z" fill="#013"/>
                  </g>
                </g>
                <g>
                  <ellipse cx="1839.64" cy="297.13" rx="5.32" ry="5.42" transform="translate(1242.03 2062.19) rotate(-80.47)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <g>
                    <line x1="1835.77" y1="300.82" x2="1839.61" y2="297.38" fill="none"/>
                    <path d="M1835.77,300.82l3.01-4.37c.4-.58,1.2-.73,1.79-.33,.79,.51,.71,1.77-.12,2.19l-4.67,2.51h-.01Z" fill="#013"/>
                  </g>
                </g>
                <g>
                  <ellipse cx="1852.02" cy="299.22" rx="5.32" ry="5.42" transform="translate(1250.31 2076.14) rotate(-80.47)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <g>
                    <line x1="1852.95" y1="294.43" x2="1852" y2="299.46" fill="none"/>
                    <path d="M1852.95,294.43l.28,5.26c.04,.71-.51,1.32-1.22,1.35-.94,.07-1.65-.97-1.24-1.82l2.18-4.8h0Z" fill="#013"/>
                  </g>
                </g>
                <g>
                  <ellipse cx="1864.42" cy="301.3" rx="5.32" ry="5.42" transform="translate(1258.59 2090.1) rotate(-80.47)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <g>
                    <line x1="1868.67" y1="298.64" x2="1864.4" y2="301.54" fill="none"/>
                    <path d="M1868.67,298.64l-3.58,3.93c-.48,.53-1.29,.56-1.82,.09-.71-.62-.47-1.85,.41-2.16l4.98-1.86h0Z" fill="#013"/>
                  </g>
                </g>
                <g>
                  <ellipse cx="1876.82" cy="303.38" rx="5.32" ry="5.42" transform="translate(1266.89 2104.07) rotate(-80.47)" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <g>
                    <line x1="1872.95" y1="307.06" x2="1876.79" y2="303.62" fill="none"/>
                    <path d="M1872.95,307.06l3.01-4.37c.4-.58,1.2-.73,1.79-.33,.79,.51,.71,1.77-.12,2.19l-4.67,2.51h-.01Z" fill="#013"/>
                  </g>
                </g>
              </g>
              <g>
                <ellipse cx="1829.75" cy="316.4" rx="1.45" ry="1.47" transform="translate(1214.78 2068.51) rotate(-80.47)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <ellipse cx="1867.49" cy="322.74" rx="1.45" ry="1.47" transform="translate(1240.02 2111.02) rotate(-80.47)" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </g>
              <rect x="1847.75" y="304.45" width="2.52" height="27.51" transform="translate(1229.07 2089.01) rotate(-80.47)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <rect x="1846.92" y="316.52" width="2.52" height="13.1" transform="translate(1223.58 2092.25) rotate(-80.47)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <rect x="1853.07" y="279.95" width="2.52" height="13.1" transform="translate(1264.77 2067.8) rotate(-80.47)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="1821.2" y1="305.03" x2="1879.52" y2="314.82" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
            <g>
              <path d="M1860.92,338.52c-10.41,.97-20.37-.7-29.89-5.02-6.34-3.21-13.69-13.33-14.71-20.26-1.05-10.24,.6-20.02,4.93-29.35,3.23-6.21,13.48-13.38,20.52-14.34,10.41-.97,20.37,.7,29.89,5.02,6.34,3.21,13.69,13.33,14.71,20.26,1.05,10.24-.6,20.02-4.93,29.35-3.23,6.21-13.48,13.38-20.52,14.34Z" fill="#fff" isolation="isolate" opacity=".2" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g opacity=".3">
                <path d="M1858.18,329.13c-7.55,.7-14.77-.51-21.68-3.64-4.6-2.33-9.93-9.67-10.67-14.69-.76-7.42,.43-14.52,3.58-21.29,2.34-4.5,9.78-9.7,14.88-10.4,7.55-.7,14.77,.51,21.68,3.64,4.6,2.33,9.93,9.67,10.67,14.69,.76,7.42-.43,14.52-3.58,21.29-2.34,4.51-9.78,9.7-14.88,10.4Z" fill="#fff"/>
              </g>
              <g clip-path="url(#clippath-4)">
                <g>
                  <path d="M1825.95,310.71c.74,5.02,6.07,12.36,10.67,14.69,6.9,3.13,14.13,4.34,21.68,3.64,0,0-2.35,9.52,13.01,9.69,0,0-29.9,8.97-30.47,8.94-.58-.03-29.87-15.17-29.87-15.17,0,0-6.94-17.41-3.38-21.55,13.84,8.24,18.35-.23,18.35-.23h0Z" fill="#fff"/>
                  <path d="M1876.75,297.36c-.74-5.02-6.07-12.36-10.67-14.69,0,0,10.78,.73,7.93-8.1l15.81,12.09s3.26,10.86,.79,13.03c-7.22-7.66-13.86-2.33-13.86-2.33h0Z" fill="#fff"/>
                  <g clip-path="url(#clippath-5)">
                    <path d="M1785.74,349.09c4.73,1.16,9.49,2.3,14.28,3.44,18.88-23.46,45.43-49.65,79.99-74.61-3.72-1.24-7.41-2.47-11.06-3.69-35.66,24.77-63.29,51.04-83.22,74.86h.01Z" fill="#fff" isolation="isolate" opacity=".66"/>
                  </g>
                </g>
              </g>
              <path d="M1858.29,329.05c-7.55,.7-14.77-.51-21.68-3.64-4.6-2.33-9.93-9.67-10.67-14.69" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1866.08,282.66c4.6,2.33,9.93,9.67,10.67,14.69" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1816.33,313.24c-1.05-10.24,.6-20.02,4.93-29.35,3.23-6.21,13.48-13.38,20.52-14.34,10.41-.97,20.37,.7,29.89,5.02,6.34,3.21,13.69,13.33,14.71,20.26,1.05,10.24-.59,20.02-4.93,29.35-3.23,6.21-13.48,13.38-20.52,14.34-10.41,.97-20.37-.7-29.89-5.02-6.34-3.21-13.69-13.33-14.71-20.26Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
          </g>
          <g>
            <g opacity=".8">
              <path d="M1824.36,383.42c-7.77,6.75-16.71,11.08-26.82,13-6.92,1-18.65-3.06-23.47-8.14-6.75-7.77-11.08-16.71-13-26.82-1-6.92,3.06-18.65,8.14-23.47,7.77-6.75,16.71-11.08,26.82-13,6.92-1,18.65,3.06,23.47,8.14,6.75,7.77,11.08,16.71,13,26.82,1,6.92-3.06,18.65-8.14,23.47Z" fill="#bbe4ec"/>
              <path d="M1824.36,383.42c-7.77,6.75-16.71,11.08-26.82,13-6.92,1-18.65-3.06-23.47-8.14-6.75-7.77-11.08-16.71-13-26.82-1-6.92,3.06-18.65,8.14-23.47,7.77-6.75,16.71-11.08,26.82-13,6.92-1,18.65,3.06,23.47,8.14,6.75,7.77,11.08,16.71,13,26.82,1,6.92-3.06,18.65-8.14,23.47Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g opacity=".57">
              <path d="M1816.78,377.19c-5.63,4.9-12.12,8.04-19.45,9.43-5.02,.73-13.53-2.22-17.02-5.91-4.9-5.63-8.04-12.12-9.43-19.45-.73-5.02,2.22-13.53,5.91-17.02,5.63-4.9,12.12-8.04,19.45-9.43,5.02-.73,13.53,2.22,17.02,5.91,4.9,5.63,8.04,12.12,9.43,19.45,.73,5.02-2.22,13.53-5.91,17.02Z" fill="#fff"/>
            </g>
            <g clip-path="url(#clippath-6)">
              <g>
                <path d="M1780.31,380.71c3.49,3.68,12,6.63,17.02,5.91,7.33-1.39,13.82-4.54,19.45-9.43,0,0,3.62,9.13,16.01,.47,0,0-18.75,24.45-19.23,24.75s-32.66,4.69-32.66,4.69c0,0-15.61-10.26-15.15-15.69,15.84-1.18,14.56-10.7,14.56-10.7Z" fill="#fff"/>
                <path d="M1813.26,340.71c-3.49-3.68-12-6.63-17.02-5.91,0,0,9.05-5.57,1.67-11.16l19.63,.84s8.88,7.02,8.16,10.2c-10.21-2.13-12.43,6.03-12.43,6.03h-.01Z" fill="#fff"/>
                <g clip-path="url(#clippath-7)">
                  <path d="M1770.29,435.09c4.45-1.76,8.92-3.55,13.42-5.37,1.56-29.99,7.69-66.59,20.94-106.78-3.69,1.12-7.35,2.22-10.98,3.31-14.24,40.66-21.18,77.96-23.37,108.84h-.01Z" fill="#fff" isolation="isolate" opacity=".66"/>
                </g>
              </g>
            </g>
            <path d="M1824.36,383.42c-7.77,6.75-16.71,11.08-26.82,13-6.92,1-18.65-3.06-23.47-8.14-6.75-7.77-11.08-16.71-13-26.82-1-6.92,3.06-18.65,8.14-23.47,7.77-6.75,16.71-11.08,26.82-13,6.92-1,18.65,3.06,23.47,8.14,6.75,7.77,11.08,16.71,13,26.82,1,6.92-3.06,18.65-8.14,23.47Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1816.78,377.19c-5.63,4.9-12.12,8.04-19.45,9.43-5.02,.73-13.53-2.22-17.02-5.91" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1796.23,334.81c5.02-.73,13.53,2.22,17.02,5.91" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <g>
              <path d="M1913.05,345.46c-2.35,10.02-7.06,18.77-14.14,26.24-5.03,4.87-16.92,8.43-23.79,7.13-10.02-2.35-18.77-7.06-26.24-14.14-4.87-5.03-8.43-16.92-7.13-23.79,2.35-10.02,7.06-18.77,14.14-26.24,5.03-4.87,16.92-8.43,23.79-7.13,10.02,2.35,18.77,7.06,26.24,14.14,4.87,5.03,8.43,16.92,7.13,23.79Z" fill="#bbe4ec" isolation="isolate" opacity=".8"/>
              <path d="M1913.05,345.46c-2.35,10.02-7.06,18.77-14.14,26.24-5.03,4.87-16.92,8.43-23.79,7.13-10.02-2.35-18.77-7.06-26.24-14.14-4.87-5.03-8.43-16.92-7.13-23.79,2.35-10.02,7.06-18.77,14.14-26.24,5.03-4.87,16.92-8.43,23.79-7.13,10.02,2.35,18.77,7.06,26.24,14.14,4.87,5.03,8.43,16.92,7.13,23.79Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g opacity=".57">
              <path d="M1903.26,344.83c-1.7,7.27-5.12,13.61-10.25,19.03-3.65,3.53-12.27,6.12-17.26,5.17-7.27-1.7-13.61-5.12-19.03-10.25-3.53-3.65-6.12-12.27-5.17-17.26,1.7-7.27,5.12-13.61,10.25-19.03,3.65-3.53,12.27-6.12,17.26-5.17,7.27,1.7,13.61,5.12,19.03,10.25,3.53,3.65,6.12,12.27,5.17,17.26Z" fill="#fff"/>
            </g>
            <g clip-path="url(#clippath-8)">
              <g>
                <path d="M1875.75,369.04c4.99,.94,13.61-1.64,17.26-5.17,5.13-5.42,8.55-11.77,10.25-19.03,0,0,8.28,5.28,13.26-8.99,0,0-.89,30.79-1.1,31.32s-23.74,22.92-23.74,22.92c0,0-18.66,.82-21.46-3.85,12.15-10.23,5.54-17.19,5.54-17.19h-.01Z" fill="#fff"/>
                <path d="M1879.06,317.32c-4.99-.94-13.61,1.64-17.26,5.17,0,0,4.08-9.81-5.18-10.03l16.41-10.81s11.31,.49,12.59,3.5c-9.52,4.24-6.55,12.17-6.55,12.17h0Z" fill="#fff"/>
                <g clip-path="url(#clippath-9)">
                  <path d="M1899.46,419c2.58-4.03,5.16-8.1,7.74-12.2-16.28-25.23-32.74-58.5-45.52-98.84-2.34,3.07-4.66,6.11-6.96,9.11,12.25,41.3,28.45,75.6,44.74,101.93h0Z" fill="#fff" isolation="isolate" opacity=".66"/>
                </g>
              </g>
            </g>
            <path d="M1913.05,345.46c-2.35,10.02-7.06,18.77-14.14,26.24-5.03,4.87-16.92,8.43-23.79,7.13-10.02-2.35-18.77-7.06-26.24-14.14-4.87-5.03-8.43-16.92-7.13-23.79,2.35-10.02,7.06-18.77,14.14-26.24,5.03-4.87,16.92-8.43,23.79-7.13,10.02,2.35,18.77,7.06,26.24,14.14,4.87,5.03,8.43,16.92,7.13,23.79Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1903.26,344.83c-1.7,7.27-5.12,13.61-10.25,19.03-3.65,3.53-12.27,6.12-17.26,5.17" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1861.8,322.5c3.65-3.53,12.27-6.12,17.26-5.17" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <g>
              <path d="M1862.04,385.59c-10.09,2.01-20.01,1.35-29.74-2-6.51-2.56-14.68-11.91-16.35-18.7-2.01-10.09-1.35-20.01,2-29.74,2.56-6.51,11.91-14.68,18.7-16.35,10.09-2.01,20.01-1.35,29.74,2,6.51,2.56,14.68,11.91,16.35,18.7,2.01,10.09,1.35,20.01-2,29.74-2.56,6.51-11.91,14.68-18.7,16.35Z" fill="#bbe4ec" isolation="isolate" opacity=".8"/>
              <path d="M1862.04,385.59c-10.09,2.01-20.01,1.35-29.74-2-6.51-2.56-14.68-11.91-16.35-18.7-2.01-10.09-1.35-20.01,2-29.74,2.56-6.51,11.91-14.68,18.7-16.35,10.09-2.01,20.01-1.35,29.74,2,6.51,2.56,14.68,11.91,16.35,18.7,2.01,10.09,1.35,20.01-2,29.74-2.56,6.51-11.91,14.68-18.7,16.35Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g opacity=".57">
              <path d="M1858.55,376.42c-7.32,1.46-14.51,.98-21.57-1.45-4.72-1.86-10.65-8.64-11.86-13.56-1.46-7.32-.98-14.51,1.45-21.57,1.86-4.72,8.64-10.65,13.56-11.86,7.32-1.46,14.51-.98,21.57,1.45,4.72,1.86,10.65,8.64,11.86,13.56,1.46,7.32,.98,14.51-1.45,21.57-1.86,4.72-8.64,10.65-13.56,11.86Z" fill="#fff"/>
            </g>
            <g clip-path="url(#clippath-10)">
              <g>
                <path d="M1825.12,361.41c1.21,4.93,7.13,11.71,11.86,13.56,7.06,2.43,14.25,2.91,21.57,1.45,0,0-1.38,9.72,13.67,8.34,0,0-28.4,11.94-28.96,11.97s-30.69-12.12-30.69-12.12c0,0-8.47-16.64-5.38-21.13,14.34,6.82,17.94-2.08,17.94-2.08h-.01Z" fill="#fff"/>
                <path d="M1873.56,343c-1.21-4.93-7.13-11.71-11.86-13.56,0,0,10.62-.35,6.98-8.87l16.64,10.46s4.23,10.5,2.03,12.9c-7.81-6.91-13.79-.93-13.79-.93Z" fill="#fff"/>
                <g clip-path="url(#clippath-11)">
                  <path d="M1789.47,403.68c4.74,.68,9.51,1.34,14.31,1.99,16.22-25.27,39.68-54.03,71.1-82.37-3.76-.86-7.49-1.71-11.18-2.56-32.52,28.26-57.03,57.21-74.24,82.94h.01Z" fill="#fff" isolation="isolate" opacity=".66"/>
                </g>
              </g>
            </g>
            <path d="M1862.04,385.59c-10.09,2.01-20.01,1.35-29.74-2-6.51-2.56-14.68-11.91-16.35-18.7-2.01-10.09-1.35-20.01,2-29.74,2.56-6.51,11.91-14.68,18.7-16.35,10.09-2.01,20.01-1.35,29.74,2,6.51,2.56,14.68,11.91,16.35,18.7,2.01,10.09,1.35,20.01-2,29.74-2.56,6.51-11.91,14.68-18.7,16.35Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1858.55,376.42c-7.32,1.46-14.51,.98-21.57-1.45-4.72-1.86-10.65-8.64-11.86-13.56" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1861.71,329.43c4.72,1.86,10.65,8.64,11.86,13.56" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <g>
              <path d="M1934.59,381.89c-7.47,7.08-16.21,11.8-26.23,14.15-6.87,1.3-18.77-2.25-23.8-7.12-7.08-7.47-11.8-16.21-14.15-26.23-1.3-6.87,2.25-18.77,7.12-23.8,7.47-7.08,16.21-11.8,26.23-14.15,6.87-1.3,18.77,2.25,23.8,7.12,7.08,7.47,11.8,16.21,14.15,26.23,1.3,6.87-2.25,18.77-7.12,23.8Z" fill="#bbe4ec" isolation="isolate" opacity=".8"/>
              <path d="M1934.59,381.89c-7.47,7.08-16.21,11.8-26.23,14.15-6.87,1.3-18.77-2.25-23.8-7.12-7.08-7.47-11.8-16.21-14.15-26.23-1.3-6.87,2.25-18.77,7.12-23.8,7.47-7.08,16.21-11.8,26.23-14.15,6.87-1.3,18.77,2.25,23.8,7.12,7.08,7.47,11.8,16.21,14.15,26.23,1.3,6.87-2.25,18.77-7.12,23.8Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g opacity=".57">
              <path d="M1926.75,375.98c-5.42,5.13-11.76,8.56-19.03,10.27-4.99,.94-13.61-1.63-17.26-5.16-5.14-5.42-8.56-11.76-10.27-19.03-.94-4.99,1.63-13.61,5.16-17.26,5.42-5.14,11.76-8.56,19.03-10.27,4.99-.95,13.61,1.63,17.26,5.16,5.14,5.42,8.56,11.76,10.27,19.03,.94,4.99-1.63,13.61-5.16,17.26Z" fill="#fff"/>
            </g>
            <g clip-path="url(#clippath-12)">
              <g>
                <path d="M1890.47,381.09c3.65,3.53,12.27,6.11,17.26,5.16,7.27-1.71,13.61-5.13,19.03-10.27,0,0,4.01,8.96,16.01-.22,0,0-17.67,25.24-18.14,25.56s-32.43,6.1-32.43,6.1c0,0-16.04-9.57-15.81-15.02,15.77-1.87,14.08-11.32,14.08-11.32h0Z" fill="#fff"/>
                <path d="M1921.65,339.69c-3.65-3.53-12.27-6.11-17.26-5.16,0,0,8.8-5.96,1.18-11.23h19.65s9.17,6.61,8.59,9.83c-10.29-1.69-12.16,6.56-12.16,6.56h0Z" fill="#fff"/>
                <g clip-path="url(#clippath-13)">
                  <path d="M1882.81,435.85c4.37-1.95,8.76-3.93,13.17-5.94,.26-30.03,4.79-66.87,16.29-107.59-3.64,1.28-7.25,2.54-10.82,3.78-12.46,41.24-17.78,78.8-18.64,109.75h0Z" fill="#fff" isolation="isolate" opacity=".66"/>
                </g>
              </g>
            </g>
            <path d="M1934.59,381.89c-7.47,7.08-16.21,11.8-26.23,14.15-6.87,1.3-18.77-2.25-23.8-7.12-7.08-7.47-11.8-16.21-14.15-26.23-1.3-6.87,2.25-18.77,7.12-23.8,7.47-7.08,16.21-11.8,26.23-14.15,6.87-1.3,18.77,2.25,23.8,7.12,7.08,7.47,11.8,16.21,14.15,26.23,1.3,6.87-2.25,18.77-7.12,23.8Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1926.75,375.98c-5.42,5.13-11.76,8.56-19.03,10.27-4.99,.94-13.61-1.63-17.26-5.16" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1904.39,334.53c4.99-.95,13.61,1.63,17.26,5.16" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <path d="M1941.02,355.52h-179.8c-.33,2.16-.4,4.21-.15,5.95,1.92,10.11,6.25,19.05,13,26.82,.27,.28,.56,.56,.87,.83,5.24,4.68,16.06,8.26,22.6,7.31,10.11-1.92,19.05-6.25,26.82-13,.74-.71,1.46-1.57,2.15-2.54,.14-.19,.28-.39,.41-.59,1.81,1.46,3.65,2.63,5.38,3.31,9.73,3.34,19.65,4.01,29.74,2,1-.24,2.05-.64,3.12-1.14,2.89-1.34,5.96-3.49,8.65-5.98,.44,.11,.87,.25,1.32,.35,.38,.07,.78,.12,1.19,.17,.24,.02,.49,.04,.74,.05,2.11,3.5,4.6,6.8,7.51,9.87,.28,.27,.58,.53,.9,.79,5.43,4.45,16.4,7.56,22.9,6.33,10.02-2.36,18.76-7.07,26.23-14.15,.71-.74,1.39-1.63,2.04-2.63,3.76-5.79,6.19-15.3,5.08-21.17-.2-.87-.44-1.72-.69-2.57h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <rect x="1756.27" y="360.39" width="194.55" height="145.84" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="1774.25" y="408.64" width="158.59" height="49.33" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="1756.27" y1="377.46" x2="1950.82" y2="377.46" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <g>
              <path d="M1845.86,422.88c0,.37,0,.69-.04,.96s-.08,.48-.14,.64-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35s.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.05,.6,.06,.99h0Z" fill="#ffc900"/>
              <path d="M1865.99,428.18c.01,1.4-.19,2.65-.62,3.74s-1.06,2.01-1.89,2.75c-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27c.75,.14,1.51,.41,2.28,.8,.77,.39,1.43,.89,1.98,1.5,.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93s.6-.87,.78-1.42c.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
              <path d="M1887.37,441.58c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91s-.81-3.39-.83-5.48c-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15s1.24,.25,1.79,.44c.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62s.3,.32,.36,.43c.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.71-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33s-.68-.47-1.16-.74c-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.22-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
            </g>
            <g>
              <path d="M1845.86,422.88c0,.37,0,.69-.04,.96s-.08,.48-.14,.64-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35s.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.05,.6,.06,.99h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path d="M1865.99,428.18c.01,1.4-.19,2.65-.62,3.74s-1.06,2.01-1.89,2.75c-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27c.75,.14,1.51,.41,2.28,.8,.77,.39,1.43,.89,1.98,1.5,.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93s.6-.87,.78-1.42c.17-.55,.26-1.15,.25-1.79h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path d="M1887.37,441.58c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91s-.81-3.39-.83-5.48c-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15s1.24,.25,1.79,.44c.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62s.3,.32,.36,.43c.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.71-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33s-.68-.47-1.16-.74c-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.22-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
            <g>
              <path d="M1845.86,422.88c0,.37,0,.69-.04,.96s-.08,.48-.14,.64-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35s.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.05,.6,.06,.99h0Z" fill="#ffc900"/>
              <path d="M1865.99,428.18c.01,1.4-.19,2.65-.62,3.74s-1.06,2.01-1.89,2.75c-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27c.75,.14,1.51,.41,2.28,.8,.77,.39,1.43,.89,1.98,1.5,.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93s.6-.87,.78-1.42c.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
              <path d="M1887.37,441.58c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91s-.81-3.39-.83-5.48c-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15s1.24,.25,1.79,.44c.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62s.3,.32,.36,.43c.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.71-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33s-.68-.47-1.16-.74c-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.22-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 9,
  },
  {
    tag : (
      <g id="_06">
        <g>
          <rect x="181.91" y="909.87" width="308.11" height="9.39" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <ellipse cx="335.97" cy="919.04" rx="154.05" ry="64.38" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <ellipse cx="335.97" cy="909.87" rx="154.05" ry="64.38" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <g>
              <path d="M307.24,891.24v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.69,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M287.72,908.83v-21.39c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M326.75,908.83v-21.39c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M346.27,891.24v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M323.65,923.54v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M365.79,908.83v-21.39c0-.85-.69-1.55-1.55-1.55s-1.55,.7-1.55,1.55v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M317.46,898.6c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M336.98,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M297.95,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M284.62,923.54v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M304.14,905.96v20.46c0,.85,.69,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M461.83,892.79c.85,0,1.55-.7,1.55-1.55v-5.61c-.99-.71-2.02-1.42-3.1-2.12v7.73c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M432.09,917.73h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.69,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M479.8,907.19v1.64c0,.28,.08,.54,.21,.76-.01-.8-.08-1.6-.21-2.4Z" fill="#013"/>
              <path d="M391.5,881.01c0,.85,.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M442.32,875.2c.49,0,.93-.24,1.21-.6-.91-.4-1.83-.79-2.76-1.18v.23c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M385.31,891.24v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M440.77,887.44v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-21.39c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M424.35,891.24v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M404.83,908.83v-21.39c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M454.09,881.01c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.69,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M334.5,864.98h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.69,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M247.13,875.2c.85,0,1.55-.7,1.55-1.55v-6.45c-1.05,.32-2.08,.64-3.1,.96v5.49c0,.85,.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M254.87,898.6c0,.85,.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55h0Z" fill="#013"/>
              <path d="M343.17,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M393.99,861.88h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55Z" fill="#013"/>
              <path d="M412.57,864.98h2.82c-1.41-.37-2.84-.73-4.29-1.09,.2,.63,.78,1.09,1.47,1.09Z" fill="#013"/>
              <path d="M268.2,891.24v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M356.5,933.77c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.69,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M343.17,941.13v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55h0Z" fill="#013"/>
              <path d="M245.58,923.54v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M258.91,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M473.61,898.6c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M382.21,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M376.02,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M362.69,923.54v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.69-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M356.5,898.6c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.69,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M395.54,898.6c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M460.28,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M421.25,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M434.58,898.6c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M415.06,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M401.73,923.54v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M245.58,887.44v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-21.39c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M364.24,875.2c.85,0,1.55-.7,1.55-1.55v-16.94c-1.03-.09-2.06-.17-3.1-.25v17.19c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M343.17,856.07c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-.43c-1.03-.03-2.06-.05-3.1-.07v.51h0Z" fill="#013"/>
              <path d="M286.17,875.2c.85,0,1.55-.7,1.55-1.55v-14.88c-1.04,.15-2.07,.3-3.1,.46v14.43c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M305.69,857.62c.66,0,1.21-.41,1.44-.98-.92,.07-1.85,.15-2.76,.23,.27,.45,.76,.75,1.32,.75Z" fill="#013"/>
              <path d="M403.28,875.2c.85,0,1.55-.7,1.55-1.55v-11.21c-1.02-.22-2.06-.44-3.1-.65v11.86c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M325.2,875.2c.85,0,1.55-.7,1.55-1.55v-18.05c-1.04,.03-2.07,.05-3.1,.09v17.96c0,.85,.7,1.55,1.55,1.55h0Z" fill="#013"/>
              <path d="M443.87,944v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.85,.7,1.55,1.55,1.55,.14,0,.26-.02,.39-.06,.16-.07,.31-.14,.47-.21,.42-.28,.69-.75,.69-1.29h0Z" fill="#013"/>
              <path d="M471.12,914.63c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h7.1c.46-1.03,.84-2.07,1.13-3.1h-8.23Z" fill="#013"/>
              <path d="M434.58,933.77c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M352.46,881.01c0,.85,.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M258.91,951.36c0-.85-.7-1.55-1.55-1.55h-17.06c3.07,1.08,6.27,2.12,9.6,3.1h7.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M365.79,958.71c0-.85-.69-1.55-1.55-1.55s-1.55,.7-1.55,1.55v4.56c1.04-.08,2.07-.16,3.1-.25v-4.31h0Z" fill="#013"/>
              <path d="M383.76,939.58c-.85,0-1.55,.7-1.55,1.55v20.11c1.04-.14,2.07-.28,3.1-.43v-19.68c0-.85-.7-1.55-1.55-1.55h0Z" fill="#013"/>
              <path d="M403.28,957.16c-.55,0-1.04,.3-1.31,.73,.73-.15,1.47-.3,2.19-.46-.25-.17-.55-.28-.88-.28h0Z" fill="#013"/>
              <path d="M424.35,941.13c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v12.01c1.04-.31,2.08-.61,3.1-.93v-11.08h0Z" fill="#013"/>
              <path d="M326.75,958.71c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v5.33c1.03,.03,2.06,.06,3.1,.09v-5.42h0Z" fill="#013"/>
              <path d="M307.24,961.58v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v20.46c0,.58,.32,1.07,.78,1.34,.43,.04,.87,.07,1.3,.11,.59-.22,1.02-.78,1.02-1.45Z" fill="#013"/>
              <path d="M266.65,939.58c-.85,0-1.55,.7-1.55,1.55v15.73c1.02,.23,2.06,.46,3.1,.68v-16.4c0-.85-.7-1.55-1.55-1.55h0Z" fill="#013"/>
              <path d="M393.05,952.91h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M287.72,958.71c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55v1.8c1.03,.16,2.06,.31,3.1,.46v-2.26h0Z" fill="#013"/>
              <path d="M276.88,864.98c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-7.08c-4.58,.95-9.01,1.98-13.25,3.1h20.33Z" fill="#013"/>
              <path d="M451.61,932.22c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h10.04c1.5-1.02,2.92-2.05,4.25-3.1h-14.29Z" fill="#013"/>
              <path d="M198.8,900.15c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-2.2c-.74,1.03-1.41,2.06-1.98,3.1h4.18Z" fill="#013"/>
              <path d="M395.54,933.77c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M239.39,898.6c0-.85-.69-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M206.54,887.44v21.39c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-21.39c0-.83-.65-1.5-1.47-1.54-.54,.39-1.07,.79-1.59,1.19-.03,.11-.05,.23-.05,.36h0Z" fill="#013"/>
              <path d="M218.32,882.56c.85,0,1.55-.7,1.55-1.55s-.63-1.48-1.43-1.54c-1.86,1.01-3.63,2.04-5.31,3.09h5.19Z" fill="#013"/>
              <path d="M226.06,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M315.91,861.88h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55Z" fill="#013"/>
              <path d="M313.43,881.01c0,.85,.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M265.1,905.96v20.46c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-20.46c0-.85-.7-1.55-1.55-1.55s-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M274.39,881.01c0,.85,.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55Z" fill="#013"/>
              <path d="M257.36,879.46h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55Z" fill="#013"/>
              <path d="M317.46,933.77c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55Z" fill="#013"/>
              <path d="M275.94,952.91h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M314.98,952.91h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M256.42,935.32h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M354.01,952.91h20.46c.85,0,1.55-.7,1.55-1.55s-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55Z" fill="#013"/>
              <path d="M226.06,891.24c0,.85,.7,1.55,1.55,1.55s1.55-.7,1.55-1.55v-16.96c-1.05,.46-2.09,.92-3.1,1.38v15.58Z" fill="#013"/>
              <path d="M219.87,916.18c0-.85-.7-1.55-1.55-1.55h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55h0Z" fill="#013"/>
              <path d="M208.09,921.99c-.85,0-1.55,.7-1.55,1.55v9.08c.99,.76,2.02,1.52,3.1,2.26v-11.34c0-.85-.7-1.55-1.55-1.55Z" fill="#013"/>
              <path d="M237.84,932.22h-20.46c-.85,0-1.55,.7-1.55,1.55s.7,1.55,1.55,1.55h20.46c.85,0,1.55-.7,1.55-1.55s-.69-1.55-1.55-1.55Z" fill="#013"/>
              <path d="M227.61,939.58c-.85,0-1.55,.7-1.55,1.55v2.95c1.01,.47,2.05,.93,3.1,1.38v-4.34c0-.85-.7-1.55-1.55-1.55h0Z" fill="#013"/>
            </g>
            <ellipse cx="335.97" cy="909.87" rx="144.05" ry="54.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <rect x="292.33" y="907.53" width="87.28" height="2.34" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <ellipse cx="335.97" cy="909.87" rx="43.64" ry="18.24" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <ellipse cx="335.97" cy="907.53" rx="43.64" ry="18.24" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <polygon points="363.9 908.46 355.71 912.9 355.71 910.87 337.36 921.58 351.9 914.92 351.78 917.05 359.75 912.51 359.97 913.62 369.67 908.03 362.63 910.44 363.9 908.46" fill="#013"/>
                <polygon points="355.71 904.31 355.48 902.29 363.79 906.58 362.75 904.6 369.67 907.06 359.63 901.27 359.63 902.53 351.56 897.99 351.78 900.02 337.02 893.51 355.71 904.31" fill="#013"/>
                <polygon points="308.49 906.77 316.57 902.48 316.57 904.41 334.91 893.56 320.15 900.36 320.5 898.09 312.31 902.72 312.31 901.51 302.61 907.26 309.42 904.8 308.49 906.77" fill="#013"/>
                <polygon points="316.57 910.87 316.8 912.8 308.49 908.51 309.76 910.58 302.61 907.98 312.53 913.77 312.53 912.51 320.72 917.1 320.5 914.97 335.15 921.58 316.57 910.87" fill="#013"/>
                <g>
                  <path d="M366.83,894.64c-8.23-3.45-19.2-5.34-30.86-5.34s-22.63,1.9-30.86,5.34c-8.24,3.44-12.78,8.02-12.78,12.9s4.54,9.45,12.78,12.9c8.23,3.44,19.2,5.34,30.86,5.34s22.63-1.9,30.86-5.34c8.24-3.45,12.78-8.02,12.78-12.9s-4.54-9.45-12.78-12.9h0Zm-30.86,30.24c-22.9,0-41.51-7.78-41.51-17.35s18.61-17.35,41.51-17.35,41.51,7.78,41.51,17.35-18.61,17.35-41.51,17.35Z" fill="#013"/>
                  <path d="M335.86,892.34c-19.98,0-36.25,6.8-36.25,15.15s16.26,15.15,36.25,15.15,36.27-6.8,36.27-15.15-16.26-15.15-36.27-15.15Zm0,29.56c-19,0-34.47-6.46-34.47-14.41s15.47-14.41,34.47-14.41,34.5,6.47,34.5,14.41-15.47,14.41-34.5,14.41Z" fill="#013"/>
                  <path d="M336.9,906.69h12.7l-.9-.96h-25.26l-1.02,.96h12.18v.78h-12.84l3.71,3.61h2.54l-2.65-2.62h2.24l2.8,2.62h2.47l-2.66-2.65h4.39v3.4h-8.5l4.6,4.33h3.95v3.78l1.71,1.56,4.51-4.53h-2.53l-1.32,1.46v-2.26h4.63l4.24-4.33h-8.99l.02-3.4h4.99l-2.78,2.65h2.59l2.65-2.66h2.65l-2.65,2.63h2.29l3.64-3.58h-13.38v-.78h-.02Zm-6.27,6.81l-.84-.76h4.81v.76h-3.97Zm3.95,1.74h-2.15l-.9-.81h3.08l-.03,.81Zm5.55,0h-3.22l-.02-.81h3.98l-.75,.81h0Zm2.5-2.47l-.81,.74h-4.9l-.02-.74h5.73Z" fill="#013"/>
                  <path d="M343.02,900.52h-14.12l-4.26,4.08h22.75l-4.38-4.08h.01Zm-12.57,.94h11.12l2.26,2.15h-15.56l2.18-2.15Z" fill="#013"/>
                  <polygon points="338.51 896.14 337.23 897.31 338.78 898.74 333.25 898.74 337.33 895 336.04 893.72 329.82 899.69 342.2 899.69 338.51 896.14" fill="#013"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 6,
  },
  {
    tag : (
      <g id="_01">
        <g>
          <g>
            <path d="M940.16,671.98c9.65,8.39,12.45,11.68,14.76,23.38,2.76,13.95,3.78,6.28,8.94,16.83,4.88,9.98,8.84,43.55,5.71,55.47-4.63,17.65,7.89,20.98-8.24,27.82-13.95,5.92-54.62,.14-69.63,2.23-15.82,2.21-33.3,3.14-48.52-2.6-16.03-6.04-22.91-23.15-23.9-39.18-1-16.24,4.09-17.37,4.38-33.49,.22-11.91,.89-16.52-.39-28.33" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M827.69,679.66c-7.4,1.67-13.51,7.15-15.53,14.47-1.84,6.7-.42,14.22,12.65,17.5,24.82,6.22,73.96-7.46,97.9-13.19,24.02-5.75,53.88-25.06,29.63-35.61-22.79-9.91-79.43,6.6-124.65,16.83Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M953.41,668.47s-17.17,14.08-67.24,23.42c-18.61,3.47-41.89,7.93-65.67,4.21" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M837.62,696.78s15.34-31.73,40.49-36.47c25.16-4.73,47.94,11.22,55.6,16.71,0,0-1.03,2.19-4.35,3.38s-46.2,12.06-46.2,12.06l-25.4,3.97-20.13,.34h0Z" fill="#968949" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <path d="M859.23,689.09c-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.44,.67,.25,.17,.55,.24,.82,.19s.52-.22,.67-.44c.17-.25,.25-.52,.19-.82-.05-.27-.22-.52-.45-.7-.5-.32-1.19-.18-1.51,.29h0Z" fill="#231815"/>
                <path d="M855.29,676.52c-.14,.25-.22,.51-.16,.81s.23,.55,.44,.7c.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.45-.7-.47-.32-1.19-.15-1.51,.29h.01Z" fill="#231815"/>
                <path d="M852.14,688.87c-.14,.25-.22,.51-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.45-.7-.47-.32-1.22-.15-1.51,.29h.01Z" fill="#231815"/>
                <path d="M866.55,682.23c-.14,.25-.22,.51-.16,.81s.23,.55,.44,.7c.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.48-.69-.47-.32-1.16-.16-1.48,.28h0Z" fill="#231815"/>
                <path d="M880.85,671.69c-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.48-.69-.47-.32-1.16-.19-1.48,.28h0Z" fill="#231815"/>
                <path d="M877.58,660.43c-.09,.09-.18,.19-.25,.29-.29,.43-.41,.99-.31,1.5s.4,.96,.84,1.28c.43,.29,.99,.41,1.5,.31s.99-.41,1.28-.84c.29-.46,.41-.99,.31-1.5s-.41-.99-.84-1.28c-.1-.06-.21-.12-.31-.16-.56,.09-1.12,.17-1.69,.28-.18,.03-.35,.08-.53,.12Z" fill="#231815"/>
                <path d="M847.54,681.78c.15-.22,.25-.46,.3-.72-.37,.46-.73,.92-1.08,1.38,.31-.15,.59-.38,.78-.66h0Z" fill="#231815"/>
                <path d="M875.76,682.05c-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.45-.7-.47-.32-1.22-.15-1.51,.29h.01Z" fill="#231815"/>
                <path d="M909.29,664.7c.25,.17,.55,.24,.82,.19s.52-.22,.67-.44c.06-.07,.09-.16,.12-.25-.65-.26-1.31-.51-1.98-.75-.07,.19-.1,.39-.07,.58,.06,.3,.23,.55,.44,.67Z" fill="#231815"/>
                <path d="M841.96,695.5c-.06-.3-.23-.55-.44-.67-.47-.35-1.22-.18-1.49,.25-.18,.22-.25,.52-.19,.82,.05,.27,.22,.52,.44,.7,.11,.06,.23,.11,.36,.14h.53c.24-.08,.47-.22,.62-.42,.14-.25,.21-.54,.16-.81h.01Z" fill="#231815"/>
                <path d="M896.82,665.66c-.87-.62-2.19-.34-2.78,.53-.29,.43-.41,.96-.31,1.5,.1,.51,.4,.99,.84,1.28,.46,.29,.99,.41,1.5,.31s.99-.41,1.28-.84c.29-.43,.41-.99,.31-1.5-.1-.54-.41-.99-.84-1.28Z" fill="#231815"/>
                <path d="M901.04,674.91c-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.48-.69-.47-.32-1.16-.19-1.48,.28h0Z" fill="#231815"/>
                <path d="M889.14,661.5c.24,.14,.51,.22,.78,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.05-.27-.22-.52-.48-.69-.47-.32-1.16-.19-1.48,.28-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.48,.69h0Z" fill="#231815"/>
                <path d="M890.95,687.79c-.14,.25-.21,.54-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.06-.3-.23-.55-.48-.69-.47-.32-1.16-.19-1.48,.28h0Z" fill="#231815"/>
                <path d="M918.21,678.71c-.14,.25-.22,.51-.16,.81,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81s-.22-.52-.45-.7c-.5-.32-1.22-.15-1.51,.29h.01Z" fill="#231815"/>
                <path d="M913.93,672.47c-.18,.22-.25,.52-.19,.82,.05,.27,.22,.52,.44,.7,.25,.14,.54,.21,.81,.16,.27-.05,.52-.22,.7-.44,.14-.25,.21-.54,.16-.81-.06-.3-.23-.55-.45-.7-.5-.32-1.22-.15-1.48,.28h.01Z" fill="#231815"/>
              </g>
              <path d="M837.62,696.78s15.34-31.73,40.49-36.47c25.16-4.73,47.94,11.22,55.6,16.71,0,0-1.03,2.19-4.35,3.38s-46.2,12.06-46.2,12.06l-25.4,3.97-20.13,.34h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <line x1="832.81" y1="722.05" x2="847.77" y2="732.01" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="837.85" y1="739.19" x2="845.72" y2="741.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M870.12,758.18c-.39,.22-.87,.28-1.32,.19-.43-.15-.82-.44-1.05-.83-.25-.43-.3-.91-.15-1.35,.17-.79,1.18-1.43,2.1-1.21,.41,.11,.8,.4,1.05,.82,.22,.39,.27,.87,.19,1.32-.15,.43-.39,.8-.83,1.05h.01Z" fill="#231815"/>
            <path d="M876.77,767.21c-.39,.22-.87,.28-1.32,.19-.43-.15-.82-.44-1.05-.83s-.27-.87-.19-1.32c.24-.89,1.22-1.45,2.15-1.24,.43,.15,.83,.44,1.05,.82s.27,.87,.19,1.32c-.15,.43-.44,.82-.83,1.05h0Z" fill="#231815"/>
            <path d="M855.32,779.6c-.39,.22-.87,.28-1.32,.19-.43-.15-.82-.44-1.05-.83-.25-.43-.27-.87-.19-1.32,.22-.81,1.22-1.45,2.15-1.24,.43,.15,.83,.44,1.05,.82s.27,.87,.19,1.32c-.15,.43-.44,.82-.83,1.05h0Z" fill="#231815"/>
            <path d="M849.53,757.2c-.39,.22-.87,.28-1.32,.19-.43-.15-.82-.44-1.05-.83-.25-.43-.27-.87-.19-1.32,.22-.81,1.22-1.45,2.15-1.24,.43,.15,.83,.44,1.05,.82s.27,.87,.19,1.32c-.15,.43-.44,.82-.83,1.05h0Z" fill="#231815"/>
            <path d="M845.45,772.43c-.39,.22-.87,.28-1.32,.19-.43-.15-.82-.44-1.05-.83-.25-.43-.3-.91-.15-1.35,.17-.79,1.18-1.43,2.1-1.21,.41,.11,.8,.4,1.05,.82,.22,.39,.27,.87,.19,1.32-.15,.43-.44,.82-.83,1.05h.01Z" fill="#231815"/>
            <path d="M833.11,760.97c-.39,.22-.87,.28-1.34,.15-.38-.07-.78-.36-1.02-.78-.22-.39-.3-.91-.19-1.32,.24-.89,1.22-1.45,2.1-1.22,.48,.13,.87,.41,1.09,.8,.25,.43,.3,.91,.15,1.35-.11,.41-.39,.8-.78,1.02h0Z" fill="#231815"/>
            <path d="M955.21,713.21s-28.56,12.41-48.6,13.82" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M875.36,789.32c-18.29,1.8-34.54-.78-45.14-3.34,3.41,3.95,7.69,7.15,12.96,9.13,15.21,5.74,32.7,4.81,48.52,2.6,15.01-2.1,55.68,3.69,69.63-2.23,10.37-4.4,8.89-7.36,7.75-13.6-9.32,3.32-25.74,8.78-33.07,8.78-10.53,0-28.58-4.51-60.66-1.35h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M899.22,773.56c-.32,.31-.77,.48-1.23,.51-.45-.04-.91-.23-1.22-.55s-.48-.78-.51-1.23c.01-.84,.83-1.71,1.78-1.72,.46,.04,.91,.22,1.22,.54s.48,.78,.5,1.24c-.04,.45-.22,.9-.55,1.22h0Z" fill="#231815"/>
            <path d="M894.71,781.37c-.32,.31-.77,.48-1.23,.51-.45-.04-.91-.23-1.22-.55-.34-.35-.51-.81-.47-1.27-.02-.8,.79-1.67,1.74-1.69,.42,0,.88,.19,1.22,.54,.31,.32,.48,.78,.5,1.24-.04,.45-.22,.9-.55,1.22h0Z" fill="#231815"/>
          </g>
          <path d="M954.92,695.36c-.83-4.18-1.72-7.28-2.97-9.94,10.39-7.61,14.3-16.55,.38-22.6-7.38-3.21-18.33-3.63-31.28-2.41-.14,.25-.28,.5-.41,.76-3.06,6.24-3.06,13.89,9.17,19.54,.12,.06,.25,.1,.37,.16-.56,6.04-1.3,12.06-2.53,17.96-3.29,15.79-11.19,30.13-13.22,46.28-2.01,15.94,1.57,34.03,16.2,42.95,4.45,2.72,9.26,4.79,14.27,6.4,.26-.1,.52-.2,.78-.31,16.12-6.85,19.25-8.86,23.88-26.51,3.13-11.92-.83-45.48-5.71-55.47-5.16-10.56-6.18-2.88-8.94-16.83v.02Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <path d="M1056.96,662.57c7.92,10.04,11.04,21.5,11.14,33.42,.12,14.22-.14,21.06,2.97,32.39,2.94,10.72,2.24,33.23-3.05,44.36-7.83,16.48-13.54,19.93-30.66,23.66-14.81,3.23-37.96,3.33-53.1,2.59-15.96-.77-33.31-3.11-47.19-11.58-14.62-8.92-18.21-27.01-16.2-42.95,2.04-16.15,9.93-30.49,13.22-46.28,2.43-11.66,2.97-23.77,3.91-35.61" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M945.03,649.19c-7.58,.27-14.61,4.51-17.95,11.33-3.06,6.24-3.06,13.89,9.17,19.54,23.23,10.73,74.05,6.42,98.64,5.25,24.67-1.18,57.6-14.61,35.73-29.48-20.55-13.97-79.27-8.28-125.6-6.65h.01Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1070.63,661.58s-19.48,10.64-70.42,10.51c-18.93-.05-42.64,0-65.3-8.08" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M951.6,667.87s20.97-28.33,46.57-28.3,45.02,19.94,51.52,26.76c0,0-1.42,1.96-4.9,2.51-3.49,.56-47.64,3.25-47.64,3.25l-25.7-.82-19.84-3.41h0Z" fill="#968949" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <path d="M1028.76,650.01c.28,0,.56-.12,.74-.31,.07-.06,.12-.15,.17-.22-.59-.37-1.2-.74-1.81-1.1-.1,.17-.17,.37-.17,.56,0,.31,.12,.58,.31,.74,.22,.22,.49,.34,.77,.34h-.01Z" fill="#231815"/>
                <path d="M1017.98,658.17c-.18,.22-.31,.49-.31,.77s.12,.55,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.34-.77c-.4-.4-1.11-.4-1.51,0Z" fill="#231815"/>
                <path d="M1012.72,648.29c-.37,.37-.58,.86-.58,1.42,0,.52,.21,1.05,.58,1.42,.4,.37,.89,.59,1.42,.59s1.05-.22,1.42-.59c.37-.37,.59-.89,.59-1.42s-.22-1.05-.59-1.42c-.74-.77-2.09-.74-2.83,0h0Z" fill="#231815"/>
                <path d="M1031.1,658.17c-.22,.19-.34,.46-.34,.77,0,.28,.12,.55,.31,.77,.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77,0-.31-.12-.58-.31-.77-.43-.4-1.17-.37-1.51,0h0Z" fill="#231815"/>
                <path d="M1034.14,665.1c-.18,.22-.31,.46-.31,.77,0,.28,.12,.55,.31,.77,.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.31-.77c-.43-.4-1.17-.37-1.54,0Z" fill="#231815"/>
                <path d="M996.74,641.24c0,.52,.21,1.02,.58,1.42,.37,.37,.89,.59,1.42,.59s1.05-.22,1.42-.59c.37-.4,.59-.89,.59-1.42s-.22-1.05-.59-1.42c-.09-.08-.18-.15-.28-.22-.57-.02-1.14-.04-1.71-.04-.18,0-.36,.02-.55,.02-.11,.07-.21,.15-.3,.24-.37,.37-.58,.89-.58,1.42h0Z" fill="#231815"/>
                <path d="M955.79,668.18c.19-.22,.31-.49,.31-.77,0-.31-.12-.58-.31-.74-.4-.43-1.17-.4-1.51-.03-.22,.19-.34,.46-.34,.77,0,.28,.12,.55,.31,.77,.1,.08,.21,.15,.33,.2l.52,.09c.25-.02,.5-.12,.69-.29h0Z" fill="#231815"/>
                <path d="M1008.74,641.24c-.18,.22-.31,.49-.31,.77s.12,.55,.34,.77c.21,.19,.46,.31,.74,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.34-.77c-.4-.4-1.11-.4-1.51,0Z" fill="#231815"/>
                <path d="M967.34,662.79c-.18,.22-.31,.46-.31,.77,0,.28,.12,.55,.31,.77,.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.31-.77c-.4-.4-1.17-.37-1.54,0h0Z" fill="#231815"/>
                <path d="M975.03,666.17c.28,0,.56-.12,.74-.31,.22-.22,.34-.46,.34-.77,0-.28-.12-.55-.31-.77-.43-.4-1.14-.4-1.54,0-.18,.22-.31,.49-.31,.77s.12,.55,.31,.74c.22,.22,.49,.34,.77,.34h0Z" fill="#231815"/>
                <path d="M964.14,654.97c.18-.18,.33-.41,.43-.65-.45,.39-.89,.77-1.32,1.15,.33-.09,.65-.26,.89-.51h0Z" fill="#231815"/>
                <path d="M974.57,652.01c0-.28-.12-.55-.31-.77-.4-.4-1.14-.37-1.54,0-.18,.22-.31,.46-.31,.77s.12,.58,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77h0Z" fill="#231815"/>
                <path d="M1049.69,666.33c-.14-.15-.3-.31-.46-.48v.02c0,.25,.11,.49,.26,.69,.12-.14,.2-.23,.2-.23h0Z" fill="#231815"/>
                <path d="M991.81,660.48c-.18,.22-.31,.49-.31,.77s.12,.55,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.31-.77c-.4-.4-1.17-.37-1.54,0h0Z" fill="#231815"/>
                <path d="M998.74,651.24c-.18,.22-.31,.49-.31,.77s.12,.55,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.34-.77c-.4-.4-1.11-.4-1.51,0Z" fill="#231815"/>
                <path d="M982.73,658.94c-.18,.22-.31,.46-.31,.77s.12,.58,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77s-.12-.55-.34-.77c-.4-.4-1.11-.37-1.51,0Z" fill="#231815"/>
                <path d="M1005.67,668.95c-.18,.22-.31,.49-.31,.77s.12,.55,.31,.77c.22,.19,.49,.31,.77,.31s.56-.12,.77-.31c.19-.22,.31-.49,.31-.77,0-.31-.12-.58-.34-.77-.4-.4-1.11-.4-1.51,0Z" fill="#231815"/>
              </g>
              <path d="M951.6,667.87s20.97-28.33,46.57-28.3,45.02,19.94,51.52,26.76c0,0-1.42,1.96-4.9,2.51-3.49,.56-47.64,3.25-47.64,3.25l-25.7-.82-19.84-3.41h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <line x1="936.42" y1="757.73" x2="949.26" y2="770.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="938.46" y1="772.51" x2="945.73" y2="776.48" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1049.69,701.82s-4.23,6.49-8.73,10.34" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1053.3,713.48s-8.49,6.73-16.51,8.63" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1048.37,737.83c.07,.44-.04,.91-.28,1.3-.29,.35-.7,.62-1.14,.69-.49,.08-.96-.04-1.31-.33-.68-.43-.93-1.6-.41-2.39,.25-.35,.65-.62,1.14-.7,.44-.07,.91,.05,1.3,.28,.35,.29,.61,.65,.69,1.14h.01Z" fill="#231815"/>
            <path d="M1059.15,734.74c.07,.44-.04,.91-.28,1.3-.29,.35-.7,.62-1.14,.69s-.91-.05-1.3-.28c-.75-.53-.94-1.65-.42-2.44,.29-.35,.7-.62,1.14-.7s.91,.05,1.3,.28c.35,.29,.62,.69,.69,1.14h.01Z" fill="#231815"/>
            <path d="M1063.31,759.16c.07,.44-.04,.91-.28,1.3-.29,.35-.7,.62-1.14,.69-.49,.08-.91-.05-1.3-.28-.69-.49-.94-1.65-.42-2.44,.29-.35,.7-.62,1.14-.7s.91,.05,1.3,.28c.35,.29,.62,.69,.69,1.14h.01Z" fill="#231815"/>
            <path d="M1040.29,756.8c.07,.44-.04,.91-.28,1.3-.29,.35-.7,.62-1.14,.69-.49,.08-.91-.05-1.3-.28-.69-.49-.94-1.65-.42-2.44,.29-.35,.7-.62,1.14-.7s.91,.05,1.3,.28c.35,.29,.62,.69,.69,1.14h.01Z" fill="#231815"/>
            <path d="M999.88,776.66c-.44,0-.89-.19-1.24-.49-.3-.35-.49-.79-.49-1.24s.2-.89,.49-1.24c.65-.6,1.79-.65,2.43,0,.35,.3,.55,.75,.55,1.24,0,.44-.2,.89-.5,1.24-.34,.3-.79,.49-1.24,.49Z" fill="#231815"/>
            <path d="M962.72,758.08c-.45,0-.89-.19-1.24-.49-.3-.35-.49-.79-.49-1.24s.2-.89,.49-1.24c.6-.6,1.83-.65,2.48,0,.3,.35,.5,.79,.5,1.24s-.2,.89-.5,1.24c-.34,.3-.79,.49-1.24,.49Z" fill="#231815"/>
            <path d="M962.72,776.66c-.45,0-.89-.19-1.24-.49-.3-.35-.49-.79-.49-1.24s.2-.89,.49-1.24c.6-.6,1.79-.65,2.48,0,.3,.35,.5,.79,.5,1.24s-.2,.89-.5,1.24c-.34,.3-.79,.49-1.24,.49Z" fill="#231815"/>
            <path d="M946.86,746.93c-.44,0-.89-.19-1.24-.49-.3-.35-.49-.79-.49-1.24s.2-.89,.49-1.24c.6-.6,1.79-.65,2.48,0,.3,.35,.5,.79,.5,1.24s-.2,.89-.5,1.24c-.34,.3-.79,.49-1.24,.49Z" fill="#231815"/>
            <path d="M938.19,749.4c-.44,0-.89-.19-1.24-.49-.3-.35-.49-.79-.49-1.24,0-.49,.2-.94,.55-1.24,.54-.6,1.73-.65,2.42,0,.3,.3,.5,.75,.5,1.24,0,.44-.2,.89-.5,1.24-.34,.3-.79,.49-1.24,.49Z" fill="#231815"/>
            <path d="M1053.16,765.92c.07,.44-.04,.91-.28,1.3-.29,.35-.7,.62-1.14,.69-.49,.08-.96-.04-1.31-.33-.68-.43-.93-1.6-.41-2.39,.25-.35,.65-.62,1.14-.7,.44-.07,.91,.05,1.3,.28,.35,.29,.62,.69,.69,1.14h.01Z" fill="#231815"/>
            <path d="M1038.12,773.51c.07,.44-.04,.91-.33,1.31-.2,.33-.6,.6-1.09,.69-.44,.07-.96-.04-1.3-.28-.75-.53-.94-1.65-.41-2.39,.28-.4,.69-.67,1.13-.75,.49-.08,.96,.04,1.31,.33,.35,.24,.61,.65,.69,1.09Z" fill="#231815"/>
            <path d="M951.6,684.96s2.87,7.21,4.66,9.85" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M924.69,773.47c2.67,5.59,6.68,10.46,12.38,13.94,13.88,8.47,31.23,10.81,47.19,11.58,15.14,.73,38.29,.63,53.1-2.59,14.53-3.16,20.83-6.13,27.2-17.03-73.47,19.54-119.16,4.42-139.87-5.9h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <path d="M1020.23,718.55c-5.44-5.45-12.69-8.45-20.4-8.45s-14.96,3-20.4,8.45c-5.45,5.45-8.45,12.69-8.45,20.39s3,14.95,8.45,20.39c5.44,5.45,12.69,8.45,20.4,8.45s14.95-3,20.4-8.45c5.45-5.45,8.45-12.69,8.45-20.39s-3-14.95-8.45-20.39h0Z" fill="#fff"/>
              <polygon points="1018.29 740.41 1012.88 747.43 1012.88 744.23 1000.75 761.16 1010.36 750.63 1010.28 753.99 1015.55 746.82 1015.7 748.57 1022.1 739.72 1017.45 743.54 1018.29 740.41" fill="#e50012"/>
              <polygon points="1012.88 733.85 1012.73 730.65 1018.22 737.44 1017.53 734.31 1022.1 738.2 1015.47 729.04 1015.47 731.03 1010.13 723.86 1010.28 727.06 1000.52 716.76 1012.88 733.85" fill="#e50012"/>
              <polygon points="981.67 737.74 987.01 730.95 987.01 734 999.13 716.84 989.38 727.59 989.61 724.01 984.19 731.33 984.19 729.42 977.78 738.5 982.28 734.62 981.67 737.74" fill="#e50012"/>
              <polygon points="987.01 744.23 987.16 747.28 981.67 740.49 982.51 743.77 977.78 739.65 984.34 748.8 984.34 746.82 989.75 754.07 989.61 750.71 999.29 761.16 987.01 744.23" fill="#e50012"/>
              <g>
                <path d="M1020.23,718.55c-5.44-5.45-12.69-8.45-20.4-8.45s-14.96,3-20.4,8.45c-5.45,5.45-8.45,12.69-8.45,20.39s3,14.95,8.45,20.39c5.44,5.45,12.69,8.45,20.4,8.45s14.95-3,20.4-8.45c5.45-5.45,8.45-12.69,8.45-20.39s-3-14.95-8.45-20.39h0Zm-20.4,47.83c-15.13,0-27.43-12.31-27.43-27.43s12.3-27.43,27.43-27.43,27.43,12.31,27.43,27.43-12.3,27.43-27.43,27.43h0Z" fill="#0069ab"/>
                <path d="M999.76,712.51c-14.54,0-26.37,11.83-26.37,26.37s11.83,26.37,26.37,26.37,26.38-11.83,26.38-26.37-11.82-26.37-26.38-26.37Zm0,51.45c-13.83,0-25.08-11.25-25.08-25.08s11.25-25.08,25.08-25.08,25.09,11.25,25.09,25.08-11.25,25.08-25.09,25.08h0Z" fill="#0069ab"/>
                <path d="M999.76,714.91c-13.21,0-23.95,10.75-23.95,23.96s10.75,23.96,23.95,23.96,23.97-10.75,23.97-23.96-10.75-23.96-23.97-23.96Zm0,46.75c-12.56,0-22.78-10.22-22.78-22.79s10.22-22.79,22.78-22.79,22.8,10.23,22.8,22.79-10.22,22.79-22.8,22.79Z" fill="#0069ab"/>
                <path d="M1000.44,737.6h8.4l-.6-1.51h-16.69l-.68,1.51h8.05v1.23h-8.48l2.45,5.7h1.68l-1.75-4.15h1.48l1.85,4.15h1.63l-1.76-4.19h2.9v5.37h-5.61l3.04,6.85h2.61v5.97l1.13,2.47,2.98-7.17h-1.67l-.87,2.31v-3.58h3.06l2.8-6.85-5.94-.02v-5.36h3.31s-1.84,4.19-1.84,4.19h1.71l1.75-4.2h1.75l-1.75,4.17,1.51,.02,2.4-5.68h-8.84v-1.23h-.01Zm-4.14,10.79l-.56-1.2h3.18v1.2h-2.62Zm2.61,2.76h-1.42l-.6-1.29,2.04,.02-.02,1.27h0Zm3.67-.01h-2.13v-1.28h2.63l-.5,1.28Zm1.65-3.9l-.54,1.17h-3.24v-1.17h3.78Z" fill="#0069ab"/>
                <path d="M1004.49,727.85h-9.33l-2.81,6.45h15.03l-2.89-6.45Zm-8.31,1.49h7.35l1.49,3.41h-10.28l1.44-3.41h0Z" fill="#0069ab"/>
                <polygon points="1001.51 720.92 1000.66 722.77 1001.69 725.04 998.03 725.04 1000.73 719.13 999.88 717.09 995.77 726.53 1003.95 726.53 1001.51 720.92" fill="#0069ab"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 1,
  },
  {
    tag : (
      <g id="_02">
        <g>
          <g>
            <path d="M1460.91,670.64c7.92,10.04,8.08,21.5,8.18,33.42,.12,14.22,.98,17.72,4.09,29.06,2.94,10.72,4.85,31.03-.44,42.16-7.83,16.48-18.97,20.38-36.43,21.76-19.63,1.55-32.95,.53-48.09-.2-15.96-.77-33.31-3.11-47.19-11.58-14.62-8.92-13.7-23.17-11.69-39.11,2.04-16.15,4.35-17.02,7.64-32.81,2.43-11.66,4.04-30.85,4.98-42.69" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1348.98,657.26c-7.58,.27-14.61,4.51-17.95,11.33-3.06,6.24-3.06,13.89,9.17,19.54,23.23,10.73,74.05,6.42,98.64,5.25,24.67-1.18,57.6-14.61,35.73-29.48-20.55-13.97-79.27-8.28-125.6-6.65h0Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1474.58,669.66s-19.48,10.64-70.42,10.51c-18.93-.05-42.64,0-65.3-8.08" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1460.91,674.36s-37.55,9.96-72.98,5.81c-7.07-.83-13.26-.72-18.15-1.34-19.6-2.49-22.89-4.22-22.89-4.22,0,0,3.14-14.69,18.01-22.57,14.87-7.87,27.12-3.5,43.73,0,16.62,3.5,34.65-6.04,43.72,3.12,8.56,8.65,8.56,19.19,8.56,19.19" fill="#5d4f41" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1346.88,713.33s.94,8.88,11.05,10.99" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1402.13,785.19c-32.37,0-59.19-11.07-73.47-18.44,1.11,7.34,4.47,13.69,12.36,18.5,13.88,8.47,31.23,10.81,47.19,11.58,15.14,.73,28.47,1.75,48.09,.2,16.8-1.33,27.74-4.99,35.53-19.94-15.37,3.59-40.48,8.1-69.69,8.1h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <path d="M1268.71,673.83c7.69,9.74,7.84,20.86,7.94,32.43,.11,13.8,.95,17.2,3.97,28.2,2.85,10.4,4.71,30.11-.43,40.91-7.6,15.99-18.41,19.77-35.36,21.11-19.05,1.5-31.98,.51-46.67-.2-15.48-.75-32.32-3.02-45.79-11.24-14.19-8.66-13.29-22.49-11.34-37.95,1.98-15.67,4.22-16.52,7.41-31.84,2.36-11.31,3.92-29.94,4.84-41.43" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1160.09,660.84c-7.36,.26-14.18,4.38-17.42,10.99-2.97,6.06-2.97,13.48,8.9,18.97,22.54,10.41,71.86,6.23,95.73,5.09,23.94-1.14,55.9-14.17,34.68-28.6-19.94-13.56-76.92-8.04-121.89-6.45Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1281.98,672.87s-18.91,10.33-68.34,10.2c-18.37-.05-41.37,0-63.37-7.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1268.71,677.44s-36.44,9.67-70.82,5.63c-6.86-.8-12.87-.7-17.61-1.3-19.02-2.41-22.22-4.09-22.22-4.09,0,0,3.05-14.26,17.48-21.9,14.43-7.64,26.31-3.4,42.44,0,16.13,3.4,33.62-5.87,42.43,3.03,8.31,8.39,8.31,18.63,8.31,18.63" fill="#5d4f41" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1222.1,710.73s12.21,3.56,18.97,2.31" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1267.56,701.99s-15.88,7.07-26.15,5.82" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1158.06,715.25s.91,8.61,10.72,10.67" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1211.67,784.99c-31.41,0-57.44-10.74-71.3-17.9,1.08,7.12,4.34,13.29,11.99,17.95,13.47,8.22,30.31,10.49,45.79,11.24,14.69,.71,27.62,1.7,46.67,.2,16.3-1.29,26.92-4.84,34.47-19.35-14.92,3.48-39.29,7.86-67.63,7.86h.01Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <path d="M1372.12,664.54c-4.91-3.34-12.03-5.55-20.62-6.97-.5-.6-1.04-1.19-1.62-1.77-9.07-9.16-27.1,.38-43.72-3.12s-28.86-7.87-43.73,0c-2.71,1.44-5.03,3.1-7.01,4.86-3.01,.13-5.98,.25-8.9,.35-7.58,.27-14.61,4.51-17.95,11.33-3.06,6.24-3.06,13.89,9.17,19.54,.07,.03,.14,.06,.21,.09-.89,8.96-2.03,18.31-3.44,25.11-3.29,15.79-5.6,16.66-7.64,32.81-2.01,15.94-2.94,30.19,11.69,39.11,13.88,8.47,31.23,10.81,47.19,11.58,15.14,.73,28.47,1.75,48.09,.2,17.46-1.38,28.6-5.28,36.43-21.76,5.29-11.13,3.38-31.44,.44-42.16-3.11-11.33-3.97-14.83-4.09-29.06-.05-5.85-.11-11.59-1.1-17.11,12.97-5.55,19.85-14.03,6.6-23.04h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1387.17,664.54c-4.91-3.34-12.03-5.55-20.62-6.97-.5-.6-1.04-1.19-1.62-1.77-9.07-9.16-27.1,.38-43.72-3.12s-28.86-7.87-43.73,0c-2.71,1.44-5.03,3.1-7.01,4.86-3.01,.13-5.98,.25-8.9,.35-7.58,.27-14.61,4.51-17.95,11.33-3.06,6.24-3.06,13.89,9.17,19.54,.07,.03,.14,.06,.21,.09-.89,8.96-2.03,18.31-3.44,25.11-3.29,15.79-5.6,16.66-7.64,32.81-2.01,15.94-2.94,30.19,11.69,39.11,13.88,8.47,31.23,10.81,47.19,11.58,15.14,.73,28.47,1.75,48.09,.2,17.46-1.38,28.6-5.28,36.43-21.76,5.29-11.13,3.38-31.44,.44-42.16-3.11-11.33-3.97-14.83-4.09-29.06-.05-5.85-.12-11.59-1.1-17.11,12.97-5.55,19.85-14.03,6.6-23.04h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <path d="M1367.34,671.28c7.92,10.04,8.08,21.5,8.18,33.42,.12,14.22,.98,17.72,4.09,29.06,2.94,10.72,4.85,31.03-.44,42.16-7.83,16.48-18.97,20.38-36.43,21.76-19.63,1.55-32.95,.53-48.09-.2-15.96-.77-33.31-3.11-47.19-11.58-14.62-8.92-13.7-23.17-11.69-39.11,2.04-16.15,4.35-17.02,7.64-32.81,2.43-11.66,4.04-30.85,4.98-42.69" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1255.41,657.9c-7.58,.27-14.61,4.51-17.95,11.33-3.06,6.24-3.06,13.89,9.17,19.54,23.23,10.73,74.05,6.42,98.64,5.25,24.67-1.18,57.6-14.61,35.73-29.48-20.55-13.97-79.27-8.28-125.6-6.65h0Z" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1381.01,670.29s-19.48,10.64-70.42,10.51c-18.93-.05-42.64,0-65.3-8.08" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1367.34,675s-37.55,9.96-72.98,5.81c-7.07-.83-13.26-.72-18.15-1.34-19.6-2.49-22.89-4.22-22.89-4.22,0,0,3.14-14.69,18.01-22.57,14.87-7.87,27.12-3.5,43.73,0,16.62,3.5,34.65-6.04,43.72,3.12,8.56,8.65,8.56,19.19,8.56,19.19" fill="#5d4f41" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1325.92,713.93s12.58,3.67,19.55,2.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1366.15,700.29s-16.36,7.28-26.95,6" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1253.31,713.97s.94,8.88,11.05,10.99" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1308.56,785.82c-32.37,0-59.19-11.07-73.47-18.44,1.11,7.34,4.47,13.69,12.36,18.5,13.88,8.47,31.23,10.81,47.19,11.58,15.14,.73,28.47,1.75,48.09,.2,16.8-1.33,27.74-4.99,35.53-19.94-15.37,3.59-40.48,8.1-69.69,8.1h-.01Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <path d="M1330.78,725.71c-5.44-5.45-12.69-8.45-20.4-8.45s-14.96,3-20.4,8.45c-5.45,5.45-8.45,12.69-8.45,20.39s3,14.95,8.45,20.39c5.44,5.45,12.69,8.45,20.4,8.45s14.95-3,20.4-8.45c5.45-5.45,8.45-12.69,8.45-20.39s-3-14.95-8.45-20.39Z" fill="#fff"/>
              <polygon points="1328.84 747.58 1323.43 754.6 1323.43 751.4 1311.3 768.33 1320.91 757.8 1320.83 761.16 1326.1 753.99 1326.25 755.74 1332.65 746.89 1328 750.71 1328.84 747.58" fill="#e50012"/>
              <polygon points="1323.43 741.02 1323.28 737.82 1328.77 744.61 1328.08 741.48 1332.65 745.37 1326.02 736.21 1326.02 738.2 1320.68 731.03 1320.83 734.23 1311.08 723.93 1323.43 741.02" fill="#e50012"/>
              <polygon points="1292.22 744.91 1297.56 738.12 1297.56 741.17 1309.69 724.01 1299.93 734.76 1300.16 731.18 1294.74 738.5 1294.74 736.59 1288.33 745.67 1292.84 741.78 1292.22 744.91" fill="#e50012"/>
              <polygon points="1297.56 751.4 1297.71 754.45 1292.22 747.66 1293.06 750.94 1288.33 746.82 1294.89 755.97 1294.89 753.99 1300.3 761.24 1300.16 757.88 1309.84 768.33 1297.56 751.4" fill="#e50012"/>
              <g>
                <path d="M1330.78,725.71c-5.44-5.45-12.69-8.45-20.4-8.45s-14.96,3-20.4,8.45c-5.45,5.45-8.45,12.69-8.45,20.39s3,14.95,8.45,20.39c5.44,5.45,12.69,8.45,20.4,8.45s14.95-3,20.4-8.45c5.45-5.45,8.45-12.69,8.45-20.39s-3-14.95-8.45-20.39Zm-20.4,47.83c-15.13,0-27.43-12.31-27.43-27.43s12.3-27.43,27.43-27.43,27.43,12.31,27.43,27.43-12.3,27.43-27.43,27.43Z" fill="#0069ab"/>
                <path d="M1310.31,719.68c-14.54,0-26.37,11.83-26.37,26.37s11.83,26.37,26.37,26.37,26.38-11.83,26.38-26.37-11.82-26.37-26.38-26.37Zm0,51.45c-13.83,0-25.08-11.25-25.08-25.08s11.25-25.08,25.08-25.08,25.09,11.25,25.09,25.08-11.25,25.08-25.09,25.08Z" fill="#0069ab"/>
                <path d="M1310.31,722.08c-13.21,0-23.95,10.75-23.95,23.96s10.75,23.96,23.95,23.96,23.97-10.75,23.97-23.96-10.75-23.96-23.97-23.96Zm0,46.75c-12.56,0-22.78-10.22-22.78-22.79s10.22-22.79,22.78-22.79,22.8,10.23,22.8,22.79-10.22,22.79-22.8,22.79Z" fill="#0069ab"/>
                <path d="M1311,744.77h8.4l-.6-1.51h-16.69l-.68,1.51h8.05v1.23h-8.48l2.45,5.7h1.68l-1.75-4.15h1.48l1.85,4.15h1.63l-1.76-4.19h2.9v5.37h-5.61l3.04,6.85h2.61v5.97l1.13,2.47,2.98-7.17h-1.67l-.87,2.31v-3.58h3.06l2.8-6.85-5.94-.02v-5.36h3.31s-1.84,4.19-1.84,4.19h1.71l1.75-4.2h1.75l-1.75,4.17,1.51,.02,2.4-5.68h-8.84v-1.23h-.01Zm-4.14,10.79l-.56-1.2h3.18v1.2h-2.62Zm2.61,2.76h-1.42l-.6-1.29,2.04,.02-.02,1.27h0Zm3.67-.01h-2.13v-1.28h2.63l-.5,1.28Zm1.65-3.9l-.54,1.17h-3.24v-1.17h3.78Z" fill="#0069ab"/>
                <path d="M1315.04,735.02h-9.33l-2.81,6.45h15.03l-2.89-6.45Zm-8.31,1.49h7.35l1.49,3.41h-10.28l1.44-3.41h0Z" fill="#0069ab"/>
                <polygon points="1312.06 728.09 1311.21 729.94 1312.24 732.21 1308.58 732.21 1311.28 726.29 1310.43 724.26 1306.32 733.7 1314.5 733.7 1312.06 728.09" fill="#0069ab"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 2,
  },
  {
    tag : (
      <g id="_03">
        <g>
          <g>
            <path d="M1669.21,531.33c.28-.07,.58-.09,.87-.08,1.66,.06,3.09-.33,4.19-1.21,2.55-2.06,2.87-6.39,1.33-11.74,.81-1.32,1.85-2.53,3.13-3.56,1.28-1.03,2.68-1.78,4.15-2.29,4.9,2.65,9.2,3.27,11.75,1.21l5.72-4.6h0c2.77-2.23,2.91-7.13,.9-13.1l2.56-2.06c3.38-2.72,.84-11.48-5.66-19.56-6.51-8.08-14.52-12.43-17.9-9.71l-2.56,2.06c-5.4-3.24-10.22-4.15-12.99-1.92l-5.72,4.6c-2.55,2.06-2.87,6.39-1.33,11.74-.81,1.32-1.85,2.53-3.13,3.56-1.28,1.03-2.68,1.78-4.15,2.29-4.9-2.65-9.2-3.27-11.75-1.21-1.1,.89-1.78,2.2-2.08,3.84-.05,.29-.14,.57-.27,.83h0c-.37,.74-1.04,1.28-1.84,1.48h0c-.28,.07-.58,.09-.87,.08-1.66-.06-3.09,.33-4.19,1.21-5.11,4.11-1.28,17.36,8.57,29.59,9.84,12.23,21.97,18.81,27.08,14.7,1.1-.89,1.78-2.2,2.08-3.84,.05-.29,.14-.57,.27-.83,.37-.74,1.04-1.28,1.84-1.48h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1677.43,508.8c-3.32-2.59-6.72-5.92-9.88-9.85s-5.69-7.95-7.51-11.76" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <g>
              <path d="M1645.61,487.73c3.13,.05,6.29-.96,8.92-3.07,1.28-1.03,2.32-2.24,3.13-3.56" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1682.88,512.43c-1.46,.51-2.87,1.27-4.15,2.29-2.63,2.11-4.28,4.99-4.91,8.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1634.19,501.03c1.21,5.07,4.44,11.33,9.26,17.32,4.82,5.99,10.24,10.48,14.94,12.75" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <ellipse cx="1692.69" cy="478.62" rx="2.25" ry="5.39" transform="translate(73.97 1167.11) rotate(-38.83)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1691.8,509.3c-5.61-2.09-12.49-7.46-18.52-14.95-6.03-7.5-9.8-15.37-10.64-21.29" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1664.91,529.96c-5.45-2.29-11.97-7.49-17.71-14.62-5.74-7.14-9.43-14.61-10.5-20.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1696.6,493.37c-3.38-1.69-7.25-4.91-10.69-9.19-3.45-4.28-5.77-8.75-6.7-12.41" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1674.15,475.84c.93,3.66,3.25,8.13,6.7,12.41s7.31,7.5,10.69,9.19" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1676.08,520.2l6.8-7.77c4.9,2.65,9.2,3.27,11.75,1.21l5.72-4.6h0c2.77-2.23,2.91-7.13,.9-13.1l2.56-2.06c1.15-.92,1.6-2.55,1.46-4.6l-53.08,36.64-4.06-5.88c10.2-8.32,15.62-17.92,12.33-22.68-.8-1.16-2.07-1.94-3.67-2.36-.28-.07-.56-.18-.81-.33-.71-.42-1.2-1.13-1.34-1.95h0c-.05-.29-.05-.58-.02-.87,.18-1.65-.1-3.11-.9-4.27-.79-1.14-2.03-1.91-3.59-2.33l-1.49,.82c-1.12-.51-2.2-.89-3.23-1.15-.45,.03-.9,.07-1.37,.14l-.46-.47c-1.98-.21-3.68,.13-4.94,1.15-1.1,.89-1.78,2.2-2.08,3.84-.05,.29-.14,.57-.27,.83h0c-.37,.74-1.04,1.28-1.84,1.48h0c-.28,.07-.58,.09-.87,.08-1.66-.06-3.09,.33-4.19,1.21-5.11,4.11-1.27,17.36,8.57,29.59,8.1,10.06,17.74,16.3,23.75,15.9l4.9-3.39c.22-.51,.4-1.05,.51-1.65,.05-.29,.14-.57,.27-.83,.37-.74,1.04-1.28,1.84-1.48,.28-.07,.58-.09,.87-.08,1.29,.04,2.44-.19,3.4-.7l.91-.63c2.13-1.84,2.63-5.34,1.69-9.72h-.02Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <path d="M1676.08,520.2l1.78-2.03c-1.06,.68-1.85,1.53-2.3,2.58-.73,1.74-.45,3.8,.66,5.99,.45-1.79,.4-4.01-.14-6.53h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1700.35,509.05c2.77-2.23,2.91-7.13,.9-13.1l2.56-2.06c3.28-2.64,.98-10.96-5.11-18.84-2.74,.69-4.69,1.99-5.5,3.91l-2.85,6.76c-1.27,3.02,.5,6.99,4.4,10.97l-5.6,8.67c-4.55,.31-7.84,1.79-8.96,4.44-.55,1.3-.53,2.78-.01,4.37,.09,.28,.15,.57,.16,.86h0c0,.12-.02,.24-.03,.36l2.58-2.95c4.9,2.65,9.2,3.27,11.75,1.21l5.72-4.6h-.01Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <path d="M1735.74,536.67c.22-.2,.46-.36,.73-.49,1.49-.74,2.57-1.75,3.12-3.06,1.27-3.02-.5-6.99-4.4-10.97,.09-1.55,.43-3.11,1.07-4.62s1.52-2.84,2.57-3.99c5.57,.02,9.65-1.48,10.93-4.5l2.85-6.76h0c1.38-3.27-.82-7.66-5.42-11.96l1.28-3.02c1.69-3.99-4.7-10.5-14.26-14.54-9.56-4.03-18.68-4.07-20.36-.07l-1.28,3.02c-6.29-.29-10.97,1.19-12.35,4.46l-2.85,6.76c-1.27,3.02,.5,6.99,4.4,10.97-.09,1.55-.43,3.11-1.07,4.62-.64,1.51-1.52,2.84-2.57,3.99-5.57-.02-9.65,1.48-10.93,4.5-.55,1.3-.53,2.78-.01,4.37,.09,.28,.15,.57,.16,.86h0c.02,.82-.31,1.62-.92,2.18h0c-.22,.2-.46,.36-.73,.49-1.49,.74-2.57,1.75-3.12,3.06-2.55,6.04,7.11,15.89,21.57,22,14.46,6.1,28.26,6.15,30.81,.11,.55-1.3,.53-2.78,.01-4.37-.09-.28-.15-.57-.16-.86-.02-.82,.31-1.62,.92-2.18h.01Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1732.3,512.94c-4.16-.71-8.72-2.03-13.37-3.99-4.64-1.96-8.78-4.31-12.18-6.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <g>
              <path d="M1694.29,509.47c2.78-1.44,5.08-3.82,6.4-6.93,.64-1.51,.98-3.07,1.07-4.62" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1738.81,513.56c-1.05,1.14-1.93,2.48-2.57,3.99-1.31,3.11-1.41,6.42-.5,9.42" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1690.54,526.59c3.47,3.89,9.28,7.87,16.36,10.86,7.09,2.99,13.99,4.37,19.2,4.14" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <ellipse cx="1731.47" cy="479.15" rx="2.25" ry="5.39" transform="translate(616.81 1888.1) rotate(-67.12)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1745.18,506.57c-5.93,.82-14.53-.64-23.4-4.39-8.86-3.74-15.92-8.89-19.47-13.7" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1731.29,537.51c-5.88,.57-14.08-.92-22.53-4.48-8.44-3.56-15.23-8.4-18.93-13.01" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1741.86,490.27c-3.78,.11-8.71-.89-13.77-3.03-5.07-2.14-9.22-4.97-11.78-7.76" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1713.79,485.47c2.55,2.79,6.71,5.62,11.78,7.76,5.07,2.14,10,3.14,13.77,3.03" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <g>
            <path d="M1604.91,501.28c-.2-.22-.36-.46-.49-.73-.74-1.49-1.75-2.57-3.06-3.12-3.02-1.27-6.99,.5-10.97,4.4-1.55-.09-3.11-.43-4.62-1.07s-2.84-1.52-3.99-2.57c.02-5.57-1.48-9.65-4.5-10.93l-6.76-2.85h0c-3.27-1.38-7.66,.82-11.96,5.42l-3.02-1.28c-3.99-1.69-10.5,4.7-14.54,14.26-4.03,9.56-4.07,18.68-.07,20.36l3.02,1.28c-.29,6.29,1.19,10.97,4.46,12.35l6.76,2.85c3.02,1.27,6.99-.5,10.97-4.4,1.55,.09,3.11,.43,4.62,1.07s2.84,1.52,3.99,2.57c-.02,5.57,1.48,9.65,4.5,10.93,1.3,.55,2.78,.53,4.37,.01,.28-.09,.57-.15,.86-.16h0c.82-.02,1.62,.31,2.18,.92h0c.2,.22,.36,.46,.49,.73,.74,1.49,1.75,2.57,3.06,3.12,6.04,2.55,15.89-7.11,22-21.57,6.1-14.46,6.15-28.26,.11-30.81-1.3-.55-2.78-.53-4.37-.01-.28,.09-.57,.15-.86,.16-.82,.02-1.62-.31-2.18-.92h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1581.18,504.72c-.71,4.16-2.03,8.72-3.99,13.37-1.96,4.64-4.31,8.78-6.79,12.18" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <g>
              <path d="M1577.71,542.73c-1.44-2.78-3.82-5.08-6.93-6.4-1.51-.64-3.07-.98-4.62-1.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1581.8,498.2c1.14,1.05,2.48,1.93,3.99,2.57,3.11,1.31,6.42,1.41,9.42,.5" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1594.83,546.48c3.89-3.47,7.87-9.28,10.86-16.36,2.99-7.09,4.37-13.99,4.14-19.2" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <ellipse cx="1547.4" cy="505.51" rx="5.39" ry="2.25" transform="translate(480.03 1734.62) rotate(-67.12)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1574.81,491.84c.82,5.93-.64,14.53-4.39,23.4-3.74,8.86-8.89,15.92-13.7,19.47" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1605.74,505.72c.57,5.88-.92,14.08-4.48,22.53-3.56,8.44-8.4,15.23-13.01,18.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1558.51,495.16c.11,3.78-.89,8.71-3.03,13.77-2.14,5.07-4.97,9.22-7.76,11.78" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1553.71,523.23c2.79-2.55,5.62-6.71,7.76-11.78s3.14-10,3.03-13.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1612.31,502.06c-1.3-.55-2.78-.53-4.37-.01-.28,.09-.57,.15-.86,.16-.82,.02-1.62-.31-2.18-.92h0c-.2-.22-.36-.46-.49-.73-.74-1.49-1.75-2.57-3.06-3.12-2.65-1.12-6.04,.11-9.51,3.07l-10.06-2.3c.02-5.44-1.42-9.45-4.29-10.82,.01,.02,.02,.03,.03,.05l1.86,2.7c-3.63,5.15-4.89,9.89-2.87,12.81h0l4.17,6.04c1.86,2.7,6.16,3.34,11.61,2.19l4.3,9.38c-2.19,4.01-2.7,7.57-1.07,9.94,.8,1.16,2.07,1.94,3.67,2.36,.28,.07,.56,.18,.81,.33,.71,.42,1.2,1.13,1.34,1.95,.05,.29,.05,.58,.02,.87-.18,1.65,.1,3.11,.9,4.27,.96,1.4,2.59,2.22,4.68,2.55,1.91-2.93,3.69-6.28,5.24-9.95,6.1-14.46,6.15-28.26,.11-30.81h.02Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1713.84,472.7l-1.28,3.02c-.7-.03-1.38-.04-2.04-.03,1.75,6.38-.58,13.44-6.33,17.41l-4.16,2.87c.52,.64,1.09,1.29,1.72,1.94l-5.6,8.67c-4.55,.31-7.84,1.79-8.96,4.44-.55,1.3-.53,2.78-.01,4.37,.09,.28,.15,.57,.16,.86h0c.02,.82-.31,1.62-.92,2.18h0c-.22,.2-.46,.36-.73,.49-1.49,.74-2.57,1.75-3.12,3.06-.64,1.52-.51,3.29,.28,5.18l40.84-28.19c8.73-6.03,10.93-17.99,4.9-26.73l-.99-1.43h0l-.17-.25c-6.77-1.59-12.32-.88-13.6,2.15h.01Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <g>
              <path d="M1651.87,535.54l4.25,6.16c.71,1.02-.71,3.23-3.16,4.92s-5.01,2.23-5.72,1.21l-4.25-6.16,8.88-6.13Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <ellipse cx="1647.4" cy="538.47" rx="5.39" ry="2.25" transform="translate(-14.23 1031.28) rotate(-34.62)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <path d="M1662.99,411.24l-10.48,7.23-75.06,51.81h0l-2.35-3.4h0l-6.68-9.68,79.61-54.95c12.01-8.29,28.46-5.27,36.75,6.73l4.94,7.15c-6.03-8.73-17.99-10.92-26.73-4.9h0Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1693.11,449.11l-.95-1.37-17.38-25.18c-5.02-7.28-15-9.1-22.27-4.08l10.48-7.23c8.73-6.03,20.7-3.84,26.73,4.9l1.58,2.29,2.35,3.4,11.63,16.85,.95,1.37,6.55,9.5,11.98,17.35h0l.99,1.43c6.03,8.73,3.84,20.7-4.9,26.73l-85.54,59.05h0l-2.35-3.4-6.68-9.68,75.07-51.82c7.28-5.02,9.1-14.99,4.08-22.27l-5.75-8.33-6.55-9.5h-.02Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1636.36,522.56c2.45-1.69,5.01-2.23,5.72-1.21l7.44,10.78c.71,1.02-.71,3.23-3.16,4.92s-5.01,2.23-5.72,1.21l-7.44-10.78c-.71-1.02,.71-3.23,3.16-4.92Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1605.39,523.93c-.25-.15-.53-.26-.81-.33-1.61-.42-2.87-1.19-3.67-2.36-1.86-2.7-.93-6.95,2.07-11.63-.4-1.5-1.05-2.96-1.98-4.31s-2.06-2.48-3.32-3.38c-5.45,1.15-9.75,.51-11.61-2.19l-4.17-6.04h0c-2.02-2.92-.75-7.66,2.87-12.81l-1.86-2.7c-2.46-3.57,2.46-11.24,11-17.13s17.46-7.78,19.92-4.21l1.86,2.7c6.1-1.56,10.98-1.06,13,1.86l4.17,6.04c1.86,2.7,.93,6.95-2.07,11.63,.4,1.5,1.05,2.96,1.98,4.31,.93,1.35,2.06,2.48,3.32,3.38,5.45-1.15,9.75-.51,11.61,2.19,.8,1.16,1.08,2.62,.9,4.27-.03,.29-.03,.59,.02,.87h0c.15,.81,.64,1.52,1.34,1.95h0c.25,.15,.53,.26,.81,.33,1.61,.42,2.87,1.19,3.67,2.36,3.73,5.4-3.73,17.01-16.65,25.93s-26.42,11.77-30.14,6.37c-.8-1.16-1.08-2.62-.9-4.27,.03-.29,.03-.59-.02-.87-.15-.81-.64-1.52-1.34-1.95h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M1603.93,499.99c3.92-1.54,8.13-3.76,12.28-6.63,4.15-2.86,7.72-6,10.55-9.13" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <g>
              <path d="M1640.43,488.86c-3.01-.85-5.76-2.71-7.67-5.49-.93-1.35-1.58-2.81-1.98-4.31" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <path d="M1597.68,501.92c1.26,.91,2.39,2.03,3.32,3.38,1.92,2.78,2.68,6,2.41,9.12" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1647.59,504.86c-2.61,4.52-7.48,9.6-13.81,13.96-6.33,4.37-12.81,7.12-17.95,7.96" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1597.35,456.55l4.94,7.16c.71,1.02-.71,3.23-3.16,4.92s-5.01,2.23-5.72,1.21l-4.94-7.16,8.88-6.13Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <path d="M1587.41,442.16l4.25,6.16c.71,1.02-.71,3.23-3.16,4.92-2.45,1.69-5.01,2.23-5.72,1.21l-4.25-6.16,8.88-6.13Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <ellipse cx="1582.94" cy="445.11" rx="5.39" ry="2.25" transform="translate(27.4 978.13) rotate(-34.62)" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <path d="M1590.02,496.38c5.97-.4,14.1-3.59,22.02-9.05,7.92-5.47,13.78-11.94,16.27-17.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1609.91,523.84c5.88-.64,13.6-3.77,21.14-8.97,7.54-5.2,13.2-11.32,15.89-16.59" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1589.95,479.74c3.72-.66,8.34-2.64,12.87-5.77,4.52-3.12,8.02-6.74,9.95-9.99" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M1616.47,469.33c-1.93,3.25-5.43,6.86-9.95,9.99-4.52,3.12-9.15,5.11-12.87,5.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <path d="M1738.81,513.56c2.78,0,5.18-.37,7.06-1.12h-207.62c-.79,5.43,.09,9.66,2.68,10.75l3.02,1.28c-.29,6.29,1.19,10.97,4.46,12.35l6.76,2.85c3.02,1.27,6.99-.5,10.97-4.4l8.67,5.6c.31,4.55,1.79,7.84,4.44,8.96,1.3,.55,2.78,.53,4.37,.01,.28-.09,.57-.15,.86-.16h0c.82-.02,1.62,.31,2.18,.92,.2,.22,.36,.46,.49,.73,.74,1.49,1.75,2.57,3.06,3.12,5.95,2.51,15.6-6.82,21.72-20.93,5.03,.99,13.04-1.2,21.31-5.98l4.06,5.88-11.02,7.61,6.68,9.68,2.35,3.41h0l11.02-7.61,.92,1.33c.71,1.02,3.27,.48,5.72-1.21s3.87-3.9,3.16-4.92l-.92-1.33,3.01-2.08c2.79,.72,5.16,.51,6.8-.81,1.1-.89,1.78-2.2,2.08-3.84,.05-.29,.14-.57,.27-.83,.37-.74,1.04-1.28,1.84-1.48,.28-.07,.58-.09,.87-.08,1.66,.06,3.09-.33,4.19-1.21,1.2-.97,1.89-2.46,2.14-4.3l6.6-4.56c-.17,.25-.32,.52-.44,.8-2.55,6.04,7.11,15.89,21.57,22,14.46,6.1,28.26,6.15,30.81,.11,.55-1.3,.53-2.78,.01-4.37-.09-.28-.15-.57-.16-.86-.02-.82,.31-1.62,.92-2.18,.22-.2,.46-.36,.73-.49,1.49-.74,2.57-1.75,3.12-3.06,1.12-2.65-.11-6.04-3.07-9.51l2.3-10.06h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <rect x="1532.22" y="516.47" width="224.32" height="141.43" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="1532.22" y1="536.15" x2="1756.54" y2="536.15" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <rect x="1557.63" y="626.24" width="94.38" height="8.1" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.38"/>
          <rect x="1557.63" y="626.24" width="94.38" height="8.1" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
            </g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
            </g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
            </g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
            </g>
            <g>
              <path d="M1587.45,583.19c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
              <path d="M1617.33,591.38c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
              <path d="M1648.93,611.66c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
            </g>
          </g>
        </g>
      </g>
    ),
    key: 3,
  },
  {
    tag : (
      <g id="_07">
        <g>
          <g>
            <polygon points="100.43 196.89 93.71 203.74 93.71 240.62 93.71 240.62 97.97 240.62 443.88 240.62 443.88 196.89 100.43 196.89" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <circle cx="116.4" cy="222.18" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <circle cx="225.81" cy="222.18" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <circle cx="421.5" cy="222.18" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle cx="340.83" cy="222.18" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="111.88" y1="209.93" x2="133.89" y2="209.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <rect x="125.61" y="196.89" width="90.72" height="43.72" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <path d="M141.34,229.9c-.32-.96,.17-1.41,1.47-1.37h.69c.32-.02,.48-.16,.47-.42v-1.25l-7.48,.08,.03,2.77c0,.81-.39,1.21-1.2,1.22-.81,0-1.21-.39-1.22-1.2l-.08-7.86c-.54,.6-1.11,1.17-1.73,1.71-.65,.49-1.23,.45-1.73-.13-.58-.65-.5-1.26,.23-1.84,1.57-1.37,2.89-3.13,3.97-5.3l-3.36,.04c-.78,0-1.17-.38-1.18-1.16,0-.78,.38-1.17,1.16-1.18l4.4-.05c.11-.29,.26-.73,.45-1.32,.21-.79,.73-1.11,1.55-.93,.88,.2,1.18,.73,.89,1.58-.05,.2-.13,.41-.22,.65l8.93-.09c.78,0,1.17,.38,1.18,1.16,0,.78-.38,1.17-1.16,1.18l-4.36,.05-5.53,.06c-.26,.59-.53,1.14-.82,1.66l8.22-.09c.4,0,.73,.12,.98,.37s.38,.57,.39,.98l.09,8.95c0,.64-.07,1.13-.21,1.46-.15,.36-.43,.64-.85,.82-.48,.24-1.23,.38-2.25,.42-.93,.04-1.5-.28-1.72-.94v-.03Zm-4.91-8.46l3.73-.04,3.73-.04v-.83c0-.33-.18-.5-.51-.5l-6.97,.07v1.33h.02Zm.05,3.43l7.47-.08v-1.36l-3.75,.04-3.73,.04v1.36h0Z" fill="#ffc900"/>
                <path d="M160.2,220.54c-.79,0-1.19-.38-1.2-1.18l-.03-3.15-5.13,.05c-.33,0-.5,.17-.5,.51v1c.02,.72-.34,1.09-1.06,1.1-.72,0-1.09-.35-1.09-1.07l-.02-1.79c0-.4,.12-.73,.37-.98s.57-.38,.98-.39l6.45-.07v-.79l-5.47,.06c-.61,0-.92-.3-.93-.91,0-.61,.3-.92,.91-.93l6.67-.07,6.7-.07c.61,0,.92,.3,.93,.91,0,.61-.3,.92-.91,.93l-5.53,.06v.79l6.62-.07c.4,0,.73,.12,.98,.37s.38,.57,.38,.98l.02,1.75c0,.75-.36,1.13-1.11,1.14-.77,0-1.15-.36-1.16-1.11v-.96c0-.33-.18-.5-.52-.5l-5.19,.05,.03,3.15c0,.79-.38,1.19-1.18,1.2h0Zm2.4,10.04c-1.42,.01-2.38-.17-2.87-.54-.5-.38-.75-1.12-.76-2.21v-.06l-3.44,.04v.04c0,.58-.32,.88-.99,.89-.4,0-.73-.09-.97-.29-.25-.2-.37-.49-.37-.86l-.05-5.05c0-.4,.12-.73,.36-.98,.25-.25,.57-.38,.98-.39l5.8-.06,5.8-.06c.4,0,.73,.12,.98,.37s.38,.57,.39,.98l.04,3.94c0,.4-.12,.73-.37,.98s-.57,.38-.98,.39l-4.82,.05v.06c0,.39,.09,.64,.27,.74,.17,.1,.58,.15,1.22,.14l3.9-.04c.43,0,.73-.09,.89-.26,.12-.53,.31-.92,.57-1.17,.3-.31,.66-.38,1.08-.22,.56,.2,.75,.79,.58,1.77-.19,.66-.5,1.12-.95,1.39-.43,.25-1.08,.39-1.96,.4l-4.34,.05v-.04Zm-8.13-10.2c-.45,0-.67-.22-.68-.68,0-.44,.22-.67,.66-.67l3.13-.03c.45,0,.67,.22,.67,.66s-.21,.69-.66,.7l-1.56,.02-1.56,.02v-.02Zm.38-2.09c-.45,0-.67-.22-.68-.66,0-.44,.22-.67,.66-.67h1.36l1.38-.03c.45,0,.67,.22,.67,.66s-.21,.67-.66,.67l-2.73,.03h0Zm.64,5.44l1.71-.02,1.73-.02v-.92l-2.95,.03c-.33,0-.5,.17-.5,.51v.42h0Zm.41,2.39l3.07-.03v-.9l-1.74,.02-1.71,.02v.52c0,.25,.13,.37,.38,.37h0Zm5.4-2.46l1.86-.02,1.88-.02v-.52c0-.26-.14-.39-.41-.39l-3.34,.03v.92h0Zm.04,2.4l3.23-.03c.33,0,.5-.17,.49-.51v-.4l-1.88,.02-1.86,.02v.9h.02Zm1.53-7.86c-.45,0-.67-.22-.68-.66,0-.44,.22-.67,.66-.67l2.69-.03c.45,0,.67,.22,.67,.66s-.21,.67-.66,.67h-1.36l-1.33,.03h0Zm.02,2.09c-.45,0-.67-.22-.68-.68,0-.44,.22-.67,.66-.67l3.17-.03c.45,0,.67,.22,.67,.66s-.21,.69-.66,.7l-1.58,.02-1.58,.02v-.02Z" fill="#ffc900"/>
                <path d="M185.11,230.27c-.45,0-.81-.13-1.11-.35-.29-.23-.48-.53-.55-.9v-.02l-.02-.04c-.17-.37-.18-.66-.03-.84,.15-.19,.47-.27,.96-.25,.32,.01,.7,.01,1.15,0,.36,0,.65-.14,.85-.41,.28-.32,.5-1.24,.68-2.76,.18-1.56,.32-3.97,.42-7.24,0-.38-.02-.63-.09-.77-.07-.12-.2-.19-.38-.18h-1.23c-1.16,5.84-3.04,10.18-5.62,13.05-.3,.31-.58,.47-.84,.49s-.56-.11-.91-.37c-.43-.33-.66-.64-.68-.93-.02-.29,.17-.62,.57-.99,2.33-2.43,3.96-6.17,4.91-11.21l-2.08,.02c-1.4,4.52-3.38,8.09-5.94,10.7-.33,.31-.63,.47-.89,.48s-.57-.13-.92-.42c-.41-.34-.61-.66-.6-.95,0-.29,.21-.61,.63-.97,2.26-2.17,3.96-5.1,5.1-8.81l-1.94,.02c-.67,1.41-1.42,2.71-2.25,3.88-.29,.35-.57,.55-.85,.59s-.6-.07-.96-.34c-.44-.32-.67-.62-.71-.9-.04-.28,.12-.62,.46-1.02,1.36-1.72,2.45-3.88,3.27-6.48,.27-.86,.81-1.21,1.62-1.04,.86,.19,1.18,.7,.93,1.55-.19,.59-.34,1.02-.45,1.3l6.24-.07,4.86-.05c.46,0,.79,.17,.99,.53,.24,.41,.34,1.13,.29,2.15-.14,4.16-.31,7.12-.51,8.89-.21,1.74-.5,2.87-.88,3.39-.25,.36-.53,.64-.85,.82-.26,.14-.62,.26-1.08,.35-.35,.06-.86,.09-1.54,.1h-.02Z" fill="#ffc900"/>
                <path d="M192.79,228.73c-.36-.75-.2-1.35,.46-1.8,.47-.31,1.04-.73,1.72-1.27l-.03-2.61c0-.33-.17-.5-.51-.5h-.65c-.76,.01-1.15-.36-1.16-1.13,0-.76,.37-1.15,1.14-1.16h1.19l1.25-.03c.33,0,.6,.09,.81,.29,.2,.2,.31,.47,.31,.8l.04,4.13c.82,1.27,2.35,1.95,4.59,2.04,2.53,.09,5.3,.03,8.3-.17,.55-.05,.92,.04,1.1,.25,.18,.21,.18,.59,.02,1.14-.11,.35-.25,.6-.43,.76-.18,.15-.45,.24-.81,.26-3.11,.13-5.84,.17-8.18,.11-1.32-.06-2.41-.25-3.26-.57-.92-.35-1.7-.92-2.33-1.71-.72,.65-1.27,1.14-1.65,1.48-.84,.73-1.48,.63-1.92-.31h0Zm2.35-10.31c-.38-.33-.83-.67-1.37-1.01-.38-.23-.59-.49-.63-.79-.04-.29,.08-.59,.39-.9,.43-.44,.89-.52,1.4-.26,.42,.25,.8,.49,1.15,.72,.13,.08,.32,.22,.59,.41,.07,.04,.12,.08,.15,.1,.55,.48,.55,1.01,.02,1.59-.3,.31-.58,.48-.83,.51-.28,.03-.56-.09-.86-.37h0Zm.67-4.14c-.37-.34-.79-.69-1.28-1.03-.38-.23-.59-.5-.62-.8-.04-.3,.1-.6,.42-.89,.43-.41,.89-.48,1.4-.2,.41,.25,.78,.49,1.11,.74,.22,.15,.45,.33,.69,.54,.55,.49,.55,1.02,0,1.56-.61,.59-1.17,.62-1.71,.08h0Zm10.98,12.51c-.82,0-1.23-.39-1.24-1.2l-.07-6.59-3.07,.03c-.15,2.6-.78,4.76-1.89,6.47-.3,.39-.59,.62-.85,.68-.29,.07-.61-.04-.94-.34-.6-.56-.6-1.26,0-2.09,.93-1.62,1.38-3.86,1.35-6.71l-.03-3.27c0-.46,.13-.8,.4-1.03,.27-.23,.63-.3,1.07-.22l.19,.04c2.4-.46,4.36-.97,5.89-1.54,.3-.11,.57-.14,.79-.07,.21,.07,.45,.22,.71,.45,.45,.41,.65,.76,.62,1.04-.04,.28-.34,.51-.89,.7-1.75,.53-3.87,1.01-6.37,1.42l.02,2.19,4.42-.05,3.3-.03c.76,0,1.15,.37,1.16,1.14,0,.77-.37,1.15-1.13,1.16l-2.29,.02,.07,6.59c0,.81-.39,1.21-1.2,1.22h-.02Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M141.34,229.9c-.32-.96,.17-1.41,1.47-1.37h.69c.32-.02,.48-.16,.47-.42v-1.25l-7.48,.08,.03,2.77c0,.81-.39,1.21-1.2,1.22-.81,0-1.21-.39-1.22-1.2l-.08-7.86c-.54,.6-1.11,1.17-1.73,1.71-.65,.49-1.23,.45-1.73-.13-.58-.65-.5-1.26,.23-1.84,1.57-1.37,2.89-3.13,3.97-5.3l-3.36,.04c-.78,0-1.17-.38-1.18-1.16,0-.78,.38-1.17,1.16-1.18l4.4-.05c.11-.29,.26-.73,.45-1.32,.21-.79,.73-1.11,1.55-.93,.88,.2,1.18,.73,.89,1.58-.05,.2-.13,.41-.22,.65l8.93-.09c.78,0,1.17,.38,1.18,1.16,0,.78-.38,1.17-1.16,1.18l-4.36,.05-5.53,.06c-.26,.59-.53,1.14-.82,1.66l8.22-.09c.4,0,.73,.12,.98,.37s.38,.57,.39,.98l.09,8.95c0,.64-.07,1.13-.21,1.46-.15,.36-.43,.64-.85,.82-.48,.24-1.23,.38-2.25,.42-.93,.04-1.5-.28-1.72-.94v-.03Zm-4.91-8.46l3.73-.04,3.73-.04v-.83c0-.33-.18-.5-.51-.5l-6.97,.07v1.33h.02Zm.05,3.43l7.47-.08v-1.36l-3.75,.04-3.73,.04v1.36h0Z" fill="#013"/>
                <path d="M160.2,220.54c-.79,0-1.19-.38-1.2-1.18l-.03-3.15-5.13,.05c-.33,0-.5,.17-.5,.51v1c.02,.72-.34,1.09-1.06,1.1-.72,0-1.09-.35-1.09-1.07l-.02-1.79c0-.4,.12-.73,.37-.98s.57-.38,.98-.39l6.45-.07v-.79l-5.47,.06c-.61,0-.92-.3-.93-.91,0-.61,.3-.92,.91-.93l6.67-.07,6.7-.07c.61,0,.92,.3,.93,.91,0,.61-.3,.92-.91,.93l-5.53,.06v.79l6.62-.07c.4,0,.73,.12,.98,.37s.38,.57,.38,.98l.02,1.75c0,.75-.36,1.13-1.11,1.14-.77,0-1.15-.36-1.16-1.11v-.96c0-.33-.18-.5-.52-.5l-5.19,.05,.03,3.15c0,.79-.38,1.19-1.18,1.2h0Zm2.4,10.04c-1.42,.01-2.38-.17-2.87-.54-.5-.38-.75-1.12-.76-2.21v-.06l-3.44,.04v.04c0,.58-.32,.88-.99,.89-.4,0-.73-.09-.97-.29-.25-.2-.37-.49-.37-.86l-.05-5.05c0-.4,.12-.73,.36-.98,.25-.25,.57-.38,.98-.39l5.8-.06,5.8-.06c.4,0,.73,.12,.98,.37s.38,.57,.39,.98l.04,3.94c0,.4-.12,.73-.37,.98s-.57,.38-.98,.39l-4.82,.05v.06c0,.39,.09,.64,.27,.74,.17,.1,.58,.15,1.22,.14l3.9-.04c.43,0,.73-.09,.89-.26,.12-.53,.31-.92,.57-1.17,.3-.31,.66-.38,1.08-.22,.56,.2,.75,.79,.58,1.77-.19,.66-.5,1.12-.95,1.39-.43,.25-1.08,.39-1.96,.4l-4.34,.05v-.04Zm-8.13-10.2c-.45,0-.67-.22-.68-.68,0-.44,.22-.67,.66-.67l3.13-.03c.45,0,.67,.22,.67,.66s-.21,.69-.66,.7l-1.56,.02-1.56,.02v-.02Zm.38-2.09c-.45,0-.67-.22-.68-.66,0-.44,.22-.67,.66-.67h1.36l1.38-.03c.45,0,.67,.22,.67,.66s-.21,.67-.66,.67l-2.73,.03h0Zm.64,5.44l1.71-.02,1.73-.02v-.92l-2.95,.03c-.33,0-.5,.17-.5,.51v.42h0Zm.41,2.39l3.07-.03v-.9l-1.74,.02-1.71,.02v.52c0,.25,.13,.37,.38,.37h0Zm5.4-2.46l1.86-.02,1.88-.02v-.52c0-.26-.14-.39-.41-.39l-3.34,.03v.92h0Zm.04,2.4l3.23-.03c.33,0,.5-.17,.49-.51v-.4l-1.88,.02-1.86,.02v.9h.02Zm1.53-7.86c-.45,0-.67-.22-.68-.66,0-.44,.22-.67,.66-.67l2.69-.03c.45,0,.67,.22,.67,.66s-.21,.67-.66,.67h-1.36l-1.33,.03h0Zm.02,2.09c-.45,0-.67-.22-.68-.68,0-.44,.22-.67,.66-.67l3.17-.03c.45,0,.67,.22,.67,.66s-.21,.69-.66,.7l-1.58,.02-1.58,.02v-.02Z" fill="#013"/>
                <path d="M185.11,230.27c-.45,0-.81-.13-1.11-.35-.29-.23-.48-.53-.55-.9v-.02l-.02-.04c-.17-.37-.18-.66-.03-.84,.15-.19,.47-.27,.96-.25,.32,.01,.7,.01,1.15,0,.36,0,.65-.14,.85-.41,.28-.32,.5-1.24,.68-2.76,.18-1.56,.32-3.97,.42-7.24,0-.38-.02-.63-.09-.77-.07-.12-.2-.19-.38-.18h-1.23c-1.16,5.84-3.04,10.18-5.62,13.05-.3,.31-.58,.47-.84,.49s-.56-.11-.91-.37c-.43-.33-.66-.64-.68-.93-.02-.29,.17-.62,.57-.99,2.33-2.43,3.96-6.17,4.91-11.21l-2.08,.02c-1.4,4.52-3.38,8.09-5.94,10.7-.33,.31-.63,.47-.89,.48s-.57-.13-.92-.42c-.41-.34-.61-.66-.6-.95,0-.29,.21-.61,.63-.97,2.26-2.17,3.96-5.1,5.1-8.81l-1.94,.02c-.67,1.41-1.42,2.71-2.25,3.88-.29,.35-.57,.55-.85,.59s-.6-.07-.96-.34c-.44-.32-.67-.62-.71-.9-.04-.28,.12-.62,.46-1.02,1.36-1.72,2.45-3.88,3.27-6.48,.27-.86,.81-1.21,1.62-1.04,.86,.19,1.18,.7,.93,1.55-.19,.59-.34,1.02-.45,1.3l6.24-.07,4.86-.05c.46,0,.79,.17,.99,.53,.24,.41,.34,1.13,.29,2.15-.14,4.16-.31,7.12-.51,8.89-.21,1.74-.5,2.87-.88,3.39-.25,.36-.53,.64-.85,.82-.26,.14-.62,.26-1.08,.35-.35,.06-.86,.09-1.54,.1h-.02Z" fill="#013"/>
                <path d="M192.79,228.73c-.36-.75-.2-1.35,.46-1.8,.47-.31,1.04-.73,1.72-1.27l-.03-2.61c0-.33-.17-.5-.51-.5h-.65c-.76,.01-1.15-.36-1.16-1.13,0-.76,.37-1.15,1.14-1.16h1.19l1.25-.03c.33,0,.6,.09,.81,.29,.2,.2,.31,.47,.31,.8l.04,4.13c.82,1.27,2.35,1.95,4.59,2.04,2.53,.09,5.3,.03,8.3-.17,.55-.05,.92,.04,1.1,.25,.18,.21,.18,.59,.02,1.14-.11,.35-.25,.6-.43,.76-.18,.15-.45,.24-.81,.26-3.11,.13-5.84,.17-8.18,.11-1.32-.06-2.41-.25-3.26-.57-.92-.35-1.7-.92-2.33-1.71-.72,.65-1.27,1.14-1.65,1.48-.84,.73-1.48,.63-1.92-.31h0Zm2.35-10.31c-.38-.33-.83-.67-1.37-1.01-.38-.23-.59-.49-.63-.79-.04-.29,.08-.59,.39-.9,.43-.44,.89-.52,1.4-.26,.42,.25,.8,.49,1.15,.72,.13,.08,.32,.22,.59,.41,.07,.04,.12,.08,.15,.1,.55,.48,.55,1.01,.02,1.59-.3,.31-.58,.48-.83,.51-.28,.03-.56-.09-.86-.37h0Zm.67-4.14c-.37-.34-.79-.69-1.28-1.03-.38-.23-.59-.5-.62-.8-.04-.3,.1-.6,.42-.89,.43-.41,.89-.48,1.4-.2,.41,.25,.78,.49,1.11,.74,.22,.15,.45,.33,.69,.54,.55,.49,.55,1.02,0,1.56-.61,.59-1.17,.62-1.71,.08h0Zm10.98,12.51c-.82,0-1.23-.39-1.24-1.2l-.07-6.59-3.07,.03c-.15,2.6-.78,4.76-1.89,6.47-.3,.39-.59,.62-.85,.68-.29,.07-.61-.04-.94-.34-.6-.56-.6-1.26,0-2.09,.93-1.62,1.38-3.86,1.35-6.71l-.03-3.27c0-.46,.13-.8,.4-1.03,.27-.23,.63-.3,1.07-.22l.19,.04c2.4-.46,4.36-.97,5.89-1.54,.3-.11,.57-.14,.79-.07,.21,.07,.45,.22,.71,.45,.45,.41,.65,.76,.62,1.04-.04,.28-.34,.51-.89,.7-1.75,.53-3.87,1.01-6.37,1.42l.02,2.19,4.42-.05,3.3-.03c.76,0,1.15,.37,1.16,1.14,0,.77-.37,1.15-1.13,1.16l-2.29,.02,.07,6.59c0,.81-.39,1.21-1.2,1.22h-.02Z" fill="#013"/>
              </g>
            </g>
            <line x1="248.6" y1="209.93" x2="269.92" y2="209.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="265.36" y1="216.76" x2="281.97" y2="216.76" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="313.46" y1="232.77" x2="346.76" y2="232.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="278.12" y1="209.93" x2="285.83" y2="209.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="296.79" y1="232.77" x2="304.49" y2="232.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="93.71" y1="234" x2="110.75" y2="234" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="93.71" y1="229.18" x2="102.23" y2="229.18" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="381.14" y1="209.93" x2="390.96" y2="209.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="369.81" y1="216.76" x2="386.05" y2="216.76" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="428.56" y1="209.93" x2="442.89" y2="209.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="430.44" y1="203.73" x2="100.43" y2="203.73" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <polygon points="443.88 196.89 458.47 196.89 458.47 240.62 437.16 240.62 437.16 203.73 443.88 196.89" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <g>
            <polygon points="100.43 272.81 93.71 279.65 93.71 316.53 93.71 316.53 97.97 316.53 443.88 316.53 443.88 272.81 100.43 272.81" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle cx="116.4" cy="298.09" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle cx="225.81" cy="298.09" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle cx="421.5" cy="298.09" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle cx="340.83" cy="298.09" r="1.66" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="118.06" y1="285.84" x2="133.89" y2="285.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="296.79" y1="285.84" x2="318.11" y2="285.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="173.52" y1="308.68" x2="206.82" y2="308.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="281.97" y1="292.68" x2="303.3" y2="292.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="270.12" y1="292.68" x2="277.82" y2="292.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="216.44" y1="308.68" x2="224.15" y2="308.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="93.71" y1="309.92" x2="107.12" y2="309.92" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="93.71" y1="305.1" x2="113.69" y2="305.1" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="163.54" y1="291.6" x2="183.51" y2="291.6" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="158.67" y1="296.43" x2="167.18" y2="296.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="369.81" y1="292.68" x2="386.05" y2="292.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="301.87" y1="308.68" x2="318.11" y2="308.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="428.56" y1="308.68" x2="442.89" y2="308.68" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <line x1="430.44" y1="279.65" x2="100.43" y2="279.65" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <polygon points="443.88 272.81 458.47 272.81 458.47 316.53 437.16 316.53 437.16 279.65 443.88 272.81" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
      </g>
    ),
    key: 7,
  },
  {
    tag : (
      <g id="_08">
        <g>
          <rect x="397.19" y="699.91" width="156.23" height="60.98" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="487.9 699.91 487.9 728.95 459.15 728.95 459.15 699.91" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polygon points="553.42 527.87 397.19 527.87 397.19 699.91 459.15 699.91 459.15 709.06 487.9 709.06 487.9 699.91 553.42 699.91 553.42 527.87" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <g>
              <g>
                <path d="M459.59,548.86c0,.43,0,.81-.04,1.11-.03,.31-.09,.56-.16,.74-.07,.19-.17,.33-.28,.42-.11,.09-.23,.14-.37,.14l-7.28,.08,.25,23.43c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.53,.14-.91,.19-.86,.08-1.43,.08-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.39-.17-.49-.29-.1-.12-.15-.25-.15-.4l-.25-23.43-7.28,.08c-.15,0-.28-.04-.38-.13s-.2-.23-.27-.41c-.08-.19-.14-.43-.18-.74-.04-.31-.06-.68-.07-1.11,0-.45,0-.83,.04-1.15,.03-.31,.09-.57,.16-.75,.07-.19,.16-.32,.26-.41,.1-.08,.23-.13,.38-.13l20.48-.21c.13,0,.26,.04,.37,.12s.21,.22,.29,.4c.08,.19,.13,.44,.18,.75,.04,.31,.06,.7,.07,1.15l.02-.02Z" fill="#ffc900"/>
                <path d="M483,555.02c.02,1.63-.22,3.08-.72,4.34s-1.23,2.33-2.19,3.2-2.15,1.54-3.56,2-3.07,.71-4.99,.73l-2.42,.03,.1,9.28c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.52,.14-.9,.19c-.37,.05-.85,.08-1.44,.08s-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.38-.17-.47-.29s-.14-.25-.14-.4l-.27-26.14c0-.7,.17-1.23,.53-1.59,.36-.36,.84-.54,1.44-.54l6.85-.07c.69,0,1.34,.01,1.97,.06,.62,.05,1.37,.15,2.24,.31s1.75,.47,2.65,.93c.9,.45,1.66,1.03,2.3,1.74,.64,.7,1.12,1.53,1.46,2.48,.34,.95,.52,2.01,.53,3.2v-.03Zm-6.18,.49c-.01-1.02-.2-1.85-.57-2.51-.37-.65-.81-1.14-1.34-1.45s-1.08-.5-1.66-.58c-.58-.08-1.17-.11-1.79-.1l-2.51,.03,.1,9.86,2.65-.03c.94,0,1.73-.15,2.36-.41,.63-.26,1.15-.62,1.55-1.08s.7-1.01,.9-1.65c.2-.64,.3-1.33,.29-2.08h.02Z" fill="#ffc900"/>
                <path d="M507.84,570.59c0,.37,0,.69-.02,.95s-.05,.49-.09,.67c-.04,.19-.1,.35-.17,.48-.07,.14-.19,.29-.35,.46-.16,.17-.48,.4-.95,.67-.47,.27-1.04,.54-1.72,.79s-1.46,.47-2.33,.64c-.88,.17-1.82,.27-2.84,.28-1.99,.02-3.79-.27-5.4-.86-1.61-.6-2.98-1.5-4.12-2.71-1.14-1.21-2.01-2.72-2.63-4.54s-.94-3.94-.96-6.37c-.03-2.47,.28-4.67,.92-6.59,.64-1.92,1.54-3.54,2.71-4.86,1.17-1.31,2.58-2.32,4.22-3.01,1.65-.69,3.47-1.05,5.46-1.07,.81,0,1.59,.05,2.34,.18s1.45,.3,2.08,.51c.64,.21,1.21,.46,1.73,.73,.51,.28,.87,.52,1.07,.72,.21,.2,.34,.37,.42,.5,.08,.13,.14,.31,.19,.51,.05,.21,.08,.46,.11,.74,.02,.28,.04,.64,.04,1.06,0,.45,0,.83-.03,1.15-.03,.31-.08,.57-.15,.77s-.16,.34-.26,.43-.22,.14-.36,.14c-.22,0-.51-.13-.86-.38-.35-.26-.8-.55-1.35-.86-.55-.32-1.2-.6-1.96-.86-.76-.25-1.66-.38-2.71-.36-1.15,.01-2.18,.26-3.08,.74s-1.67,1.16-2.29,2.05c-.63,.88-1.1,1.95-1.42,3.19-.32,1.25-.47,2.65-.45,4.21,.02,1.71,.21,3.18,.58,4.43,.36,1.25,.88,2.27,1.54,3.07s1.45,1.4,2.38,1.78c.92,.39,1.96,.57,3.12,.56,1.05-.01,1.95-.14,2.71-.4,.76-.26,1.41-.53,1.96-.84,.54-.31,.99-.58,1.34-.82s.62-.37,.82-.37c.15,0,.27,.03,.36,.09s.17,.18,.23,.36,.11,.43,.14,.75c.03,.32,.05,.75,.06,1.29h-.03Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M459.59,548.86c0,.43,0,.81-.04,1.11-.03,.31-.09,.56-.16,.74-.07,.19-.17,.33-.28,.42-.11,.09-.23,.14-.37,.14l-7.28,.08,.25,23.43c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.53,.14-.91,.19-.86,.08-1.43,.08-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.39-.17-.49-.29-.1-.12-.15-.25-.15-.4l-.25-23.43-7.28,.08c-.15,0-.28-.04-.38-.13s-.2-.23-.27-.41c-.08-.19-.14-.43-.18-.74-.04-.31-.06-.68-.07-1.11,0-.45,0-.83,.04-1.15,.03-.31,.09-.57,.16-.75,.07-.19,.16-.32,.26-.41,.1-.08,.23-.13,.38-.13l20.48-.21c.13,0,.26,.04,.37,.12s.21,.22,.29,.4c.08,.19,.13,.44,.18,.75,.04,.31,.06,.7,.07,1.15l.02-.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M483,555.02c.02,1.63-.22,3.08-.72,4.34s-1.23,2.33-2.19,3.2-2.15,1.54-3.56,2-3.07,.71-4.99,.73l-2.42,.03,.1,9.28c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.52,.14-.9,.19c-.37,.05-.85,.08-1.44,.08s-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.38-.17-.47-.29s-.14-.25-.14-.4l-.27-26.14c0-.7,.17-1.23,.53-1.59,.36-.36,.84-.54,1.44-.54l6.85-.07c.69,0,1.34,.01,1.97,.06,.62,.05,1.37,.15,2.24,.31s1.75,.47,2.65,.93c.9,.45,1.66,1.03,2.3,1.74,.64,.7,1.12,1.53,1.46,2.48,.34,.95,.52,2.01,.53,3.2v-.03Zm-6.18,.49c-.01-1.02-.2-1.85-.57-2.51-.37-.65-.81-1.14-1.34-1.45s-1.08-.5-1.66-.58c-.58-.08-1.17-.11-1.79-.1l-2.51,.03,.1,9.86,2.65-.03c.94,0,1.73-.15,2.36-.41,.63-.26,1.15-.62,1.55-1.08s.7-1.01,.9-1.65c.2-.64,.3-1.33,.29-2.08h.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M507.84,570.59c0,.37,0,.69-.02,.95s-.05,.49-.09,.67c-.04,.19-.1,.35-.17,.48-.07,.14-.19,.29-.35,.46-.16,.17-.48,.4-.95,.67-.47,.27-1.04,.54-1.72,.79s-1.46,.47-2.33,.64c-.88,.17-1.82,.27-2.84,.28-1.99,.02-3.79-.27-5.4-.86-1.61-.6-2.98-1.5-4.12-2.71-1.14-1.21-2.01-2.72-2.63-4.54s-.94-3.94-.96-6.37c-.03-2.47,.28-4.67,.92-6.59,.64-1.92,1.54-3.54,2.71-4.86,1.17-1.31,2.58-2.32,4.22-3.01,1.65-.69,3.47-1.05,5.46-1.07,.81,0,1.59,.05,2.34,.18s1.45,.3,2.08,.51c.64,.21,1.21,.46,1.73,.73,.51,.28,.87,.52,1.07,.72,.21,.2,.34,.37,.42,.5,.08,.13,.14,.31,.19,.51,.05,.21,.08,.46,.11,.74,.02,.28,.04,.64,.04,1.06,0,.45,0,.83-.03,1.15-.03,.31-.08,.57-.15,.77s-.16,.34-.26,.43-.22,.14-.36,.14c-.22,0-.51-.13-.86-.38-.35-.26-.8-.55-1.35-.86-.55-.32-1.2-.6-1.96-.86-.76-.25-1.66-.38-2.71-.36-1.15,.01-2.18,.26-3.08,.74s-1.67,1.16-2.29,2.05c-.63,.88-1.1,1.95-1.42,3.19-.32,1.25-.47,2.65-.45,4.21,.02,1.71,.21,3.18,.58,4.43,.36,1.25,.88,2.27,1.54,3.07s1.45,1.4,2.38,1.78c.92,.39,1.96,.57,3.12,.56,1.05-.01,1.95-.14,2.71-.4,.76-.26,1.41-.53,1.96-.84,.54-.31,.99-.58,1.34-.82s.62-.37,.82-.37c.15,0,.27,.03,.36,.09s.17,.18,.23,.36,.11,.43,.14,.75c.03,.32,.05,.75,.06,1.29h-.03Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              </g>
              <g>
                <path d="M459.59,548.86c0,.43,0,.81-.04,1.11-.03,.31-.09,.56-.16,.74-.07,.19-.17,.33-.28,.42-.11,.09-.23,.14-.37,.14l-7.28,.08,.25,23.43c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.53,.14-.91,.19-.86,.08-1.43,.08-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.39-.17-.49-.29-.1-.12-.15-.25-.15-.4l-.25-23.43-7.28,.08c-.15,0-.28-.04-.38-.13s-.2-.23-.27-.41c-.08-.19-.14-.43-.18-.74-.04-.31-.06-.68-.07-1.11,0-.45,0-.83,.04-1.15,.03-.31,.09-.57,.16-.75,.07-.19,.16-.32,.26-.41,.1-.08,.23-.13,.38-.13l20.48-.21c.13,0,.26,.04,.37,.12s.21,.22,.29,.4c.08,.19,.13,.44,.18,.75,.04,.31,.06,.7,.07,1.15l.02-.02Z" fill="#ffc900"/>
                <path d="M483,555.02c.02,1.63-.22,3.08-.72,4.34s-1.23,2.33-2.19,3.2-2.15,1.54-3.56,2-3.07,.71-4.99,.73l-2.42,.03,.1,9.28c0,.15-.05,.29-.14,.41-.1,.12-.26,.22-.48,.3s-.52,.14-.9,.19c-.37,.05-.85,.08-1.44,.08s-1.04-.01-1.43-.05c-.38-.04-.69-.1-.91-.17-.23-.07-.38-.17-.47-.29s-.14-.25-.14-.4l-.27-26.14c0-.7,.17-1.23,.53-1.59,.36-.36,.84-.54,1.44-.54l6.85-.07c.69,0,1.34,.01,1.97,.06,.62,.05,1.37,.15,2.24,.31s1.75,.47,2.65,.93c.9,.45,1.66,1.03,2.3,1.74,.64,.7,1.12,1.53,1.46,2.48,.34,.95,.52,2.01,.53,3.2v-.03Zm-6.18,.49c-.01-1.02-.2-1.85-.57-2.51-.37-.65-.81-1.14-1.34-1.45s-1.08-.5-1.66-.58c-.58-.08-1.17-.11-1.79-.1l-2.51,.03,.1,9.86,2.65-.03c.94,0,1.73-.15,2.36-.41,.63-.26,1.15-.62,1.55-1.08s.7-1.01,.9-1.65c.2-.64,.3-1.33,.29-2.08h.02Z" fill="#ffc900"/>
                <path d="M507.84,570.59c0,.37,0,.69-.02,.95s-.05,.49-.09,.67c-.04,.19-.1,.35-.17,.48-.07,.14-.19,.29-.35,.46-.16,.17-.48,.4-.95,.67-.47,.27-1.04,.54-1.72,.79s-1.46,.47-2.33,.64c-.88,.17-1.82,.27-2.84,.28-1.99,.02-3.79-.27-5.4-.86-1.61-.6-2.98-1.5-4.12-2.71-1.14-1.21-2.01-2.72-2.63-4.54s-.94-3.94-.96-6.37c-.03-2.47,.28-4.67,.92-6.59,.64-1.92,1.54-3.54,2.71-4.86,1.17-1.31,2.58-2.32,4.22-3.01,1.65-.69,3.47-1.05,5.46-1.07,.81,0,1.59,.05,2.34,.18s1.45,.3,2.08,.51c.64,.21,1.21,.46,1.73,.73,.51,.28,.87,.52,1.07,.72,.21,.2,.34,.37,.42,.5,.08,.13,.14,.31,.19,.51,.05,.21,.08,.46,.11,.74,.02,.28,.04,.64,.04,1.06,0,.45,0,.83-.03,1.15-.03,.31-.08,.57-.15,.77s-.16,.34-.26,.43-.22,.14-.36,.14c-.22,0-.51-.13-.86-.38-.35-.26-.8-.55-1.35-.86-.55-.32-1.2-.6-1.96-.86-.76-.25-1.66-.38-2.71-.36-1.15,.01-2.18,.26-3.08,.74s-1.67,1.16-2.29,2.05c-.63,.88-1.1,1.95-1.42,3.19-.32,1.25-.47,2.65-.45,4.21,.02,1.71,.21,3.18,.58,4.43,.36,1.25,.88,2.27,1.54,3.07s1.45,1.4,2.38,1.78c.92,.39,1.96,.57,3.12,.56,1.05-.01,1.95-.14,2.71-.4,.76-.26,1.41-.53,1.96-.84,.54-.31,.99-.58,1.34-.82s.62-.37,.82-.37c.15,0,.27,.03,.36,.09s.17,.18,.23,.36,.11,.43,.14,.75c.03,.32,.05,.75,.06,1.29h-.03Z" fill="#ffc900"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M438.39,666.78c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="#ffc900"/>
                <path d="M457.23,666.59c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="#ffc900"/>
                <path d="M466.37,665.82c.01,1.13-.18,1.88-.57,2.28-.39,.39-1.09,.59-2.11,.6-1.02,.01-1.72-.17-2.11-.55-.39-.38-.59-1.1-.6-2.16-.01-1.14,.18-1.9,.57-2.29,.39-.39,1.1-.59,2.13-.6,1-.01,1.7,.17,2.09,.55,.39,.38,.59,1.1,.6,2.18h0Z" fill="#ffc900"/>
                <path d="M486.32,661.93c.01,1.07-.16,2.02-.52,2.86s-.9,1.55-1.61,2.13-1.6,1.03-2.66,1.34-2.29,.47-3.68,.48c-1.33,.01-2.5-.11-3.51-.36-1.01-.26-1.85-.64-2.54-1.14-.68-.51-1.2-1.14-1.54-1.89-.35-.75-.53-1.62-.54-2.6,0-.67,.09-1.29,.28-1.88s.48-1.14,.87-1.64c.39-.51,.87-.98,1.46-1.43,.58-.45,1.25-.88,2.01-1.28-.62-.33-1.18-.69-1.68-1.08s-.93-.81-1.28-1.28c-.36-.46-.63-.97-.81-1.53-.19-.55-.29-1.16-.29-1.81,0-.94,.15-1.8,.47-2.57s.81-1.44,1.47-2.01c.65-.57,1.48-1.01,2.46-1.32,.99-.31,2.14-.48,3.45-.49,1.29-.01,2.41,.11,3.33,.38,.93,.27,1.69,.65,2.29,1.16,.6,.5,1.04,1.11,1.33,1.81,.29,.71,.44,1.49,.45,2.35,0,.57-.08,1.12-.26,1.66-.18,.54-.44,1.05-.77,1.54s-.75,.95-1.24,1.38-1.05,.83-1.68,1.19c.74,.38,1.41,.78,1.99,1.21s1.08,.89,1.49,1.39,.72,1.03,.93,1.6c.22,.57,.33,1.18,.34,1.85v-.02Zm-4.73,.38c0-.45-.09-.86-.24-1.22-.16-.37-.4-.71-.72-1.04-.32-.32-.73-.63-1.22-.93s-1.05-.6-1.7-.91c-.55,.31-1.04,.62-1.46,.92-.42,.31-.77,.63-1.04,.96-.28,.34-.48,.69-.62,1.06-.14,.37-.2,.77-.2,1.19,0,.94,.32,1.66,.94,2.15,.62,.49,1.53,.73,2.74,.72,1.19-.01,2.07-.28,2.65-.79,.58-.51,.87-1.22,.86-2.12h.01Zm-.67-11.71c0-.42-.07-.8-.19-1.14s-.32-.62-.58-.86c-.26-.23-.59-.41-.97-.53-.38-.12-.83-.17-1.35-.17-1.02,.01-1.77,.25-2.26,.72-.49,.47-.73,1.12-.72,1.94,0,.39,.07,.74,.2,1.07s.33,.63,.6,.92c.27,.29,.62,.57,1.04,.84,.43,.27,.93,.57,1.52,.87,.88-.53,1.55-1.09,2.02-1.69s.7-1.26,.7-1.99v.02Z" fill="#ffc900"/>
                <path d="M507.22,667.3c0,.13-.03,.25-.1,.36-.07,.1-.19,.19-.38,.26s-.44,.12-.77,.16-.74,.06-1.23,.07c-.79,0-1.35-.02-1.7-.07-.35-.06-.59-.15-.75-.26-.15-.12-.27-.26-.36-.43l-7.21-11.07,.12,11.15c0,.13-.03,.25-.11,.35-.07,.1-.2,.18-.38,.25s-.42,.12-.73,.16c-.3,.04-.69,.06-1.16,.07-.46,0-.84,0-1.15-.04-.31-.03-.55-.08-.74-.15-.18-.06-.31-.14-.39-.24-.08-.1-.12-.21-.12-.34l-.23-22.13c0-.13,.04-.25,.11-.35,.08-.1,.21-.18,.39-.24,.18-.06,.43-.11,.73-.15,.31-.04,.69-.06,1.15-.07,.47,0,.86,0,1.16,.04,.3,.03,.55,.08,.73,.14,.18,.06,.31,.14,.38,.23,.07,.1,.11,.21,.11,.34l.1,10,6.78-10.06c.08-.16,.18-.29,.3-.39,.12-.1,.28-.19,.47-.25s.44-.11,.74-.13c.3-.03,.69-.04,1.16-.05,.48,0,.88,0,1.2,.04,.32,.03,.57,.08,.75,.15,.19,.06,.32,.15,.39,.24,.07,.1,.11,.2,.11,.33,0,.21-.05,.42-.16,.64s-.31,.57-.61,1.04l-6.36,8.51,7.14,10.55c.27,.49,.44,.82,.49,.98,.06,.16,.08,.29,.09,.39l.04-.03Z" fill="#ffc900"/>
                <path d="M522.09,667.02c-.06,.21-.13,.37-.23,.5s-.25,.23-.47,.3-.51,.11-.89,.14c-.38,.02-.87,.04-1.47,.04h-1.24c-.34,0-.64-.01-.88-.04-.24-.02-.44-.06-.59-.1-.15-.05-.28-.11-.38-.18s-.18-.16-.24-.26c-.06-.1-.11-.23-.16-.39l-7.21-20.75c-.15-.43-.24-.78-.27-1.03s.03-.45,.18-.57c.15-.13,.41-.21,.77-.24,.36-.03,.87-.05,1.52-.06,.56,0,.99,0,1.31,.02,.31,.02,.56,.06,.73,.12s.29,.15,.36,.27,.13,.27,.2,.45l5.91,18.06h.02l5.42-18.09c.05-.21,.11-.37,.18-.5s.19-.23,.37-.3,.42-.13,.75-.15c.33-.03,.77-.04,1.34-.05,.56,0,.99,0,1.3,.04,.31,.03,.52,.12,.64,.26s.16,.33,.12,.59c-.04,.25-.13,.59-.27,1.02l-6.79,20.92-.03-.02Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M438.39,666.78c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M457.23,666.59c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M466.37,665.82c.01,1.13-.18,1.88-.57,2.28-.39,.39-1.09,.59-2.11,.6-1.02,.01-1.72-.17-2.11-.55-.39-.38-.59-1.1-.6-2.16-.01-1.14,.18-1.9,.57-2.29,.39-.39,1.1-.59,2.13-.6,1-.01,1.7,.17,2.09,.55,.39,.38,.59,1.1,.6,2.18h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M486.32,661.93c.01,1.07-.16,2.02-.52,2.86s-.9,1.55-1.61,2.13-1.6,1.03-2.66,1.34-2.29,.47-3.68,.48c-1.33,.01-2.5-.11-3.51-.36-1.01-.26-1.85-.64-2.54-1.14-.68-.51-1.2-1.14-1.54-1.89-.35-.75-.53-1.62-.54-2.6,0-.67,.09-1.29,.28-1.88s.48-1.14,.87-1.64c.39-.51,.87-.98,1.46-1.43,.58-.45,1.25-.88,2.01-1.28-.62-.33-1.18-.69-1.68-1.08s-.93-.81-1.28-1.28c-.36-.46-.63-.97-.81-1.53-.19-.55-.29-1.16-.29-1.81,0-.94,.15-1.8,.47-2.57s.81-1.44,1.47-2.01c.65-.57,1.48-1.01,2.46-1.32,.99-.31,2.14-.48,3.45-.49,1.29-.01,2.41,.11,3.33,.38,.93,.27,1.69,.65,2.29,1.16,.6,.5,1.04,1.11,1.33,1.81,.29,.71,.44,1.49,.45,2.35,0,.57-.08,1.12-.26,1.66-.18,.54-.44,1.05-.77,1.54s-.75,.95-1.24,1.38-1.05,.83-1.68,1.19c.74,.38,1.41,.78,1.99,1.21s1.08,.89,1.49,1.39,.72,1.03,.93,1.6c.22,.57,.33,1.18,.34,1.85v-.02Zm-4.73,.38c0-.45-.09-.86-.24-1.22-.16-.37-.4-.71-.72-1.04-.32-.32-.73-.63-1.22-.93s-1.05-.6-1.7-.91c-.55,.31-1.04,.62-1.46,.92-.42,.31-.77,.63-1.04,.96-.28,.34-.48,.69-.62,1.06-.14,.37-.2,.77-.2,1.19,0,.94,.32,1.66,.94,2.15,.62,.49,1.53,.73,2.74,.72,1.19-.01,2.07-.28,2.65-.79,.58-.51,.87-1.22,.86-2.12h.01Zm-.67-11.71c0-.42-.07-.8-.19-1.14s-.32-.62-.58-.86c-.26-.23-.59-.41-.97-.53-.38-.12-.83-.17-1.35-.17-1.02,.01-1.77,.25-2.26,.72-.49,.47-.73,1.12-.72,1.94,0,.39,.07,.74,.2,1.07s.33,.63,.6,.92c.27,.29,.62,.57,1.04,.84,.43,.27,.93,.57,1.52,.87,.88-.53,1.55-1.09,2.02-1.69s.7-1.26,.7-1.99v.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M507.22,667.3c0,.13-.03,.25-.1,.36-.07,.1-.19,.19-.38,.26s-.44,.12-.77,.16-.74,.06-1.23,.07c-.79,0-1.35-.02-1.7-.07-.35-.06-.59-.15-.75-.26-.15-.12-.27-.26-.36-.43l-7.21-11.07,.12,11.15c0,.13-.03,.25-.11,.35-.07,.1-.2,.18-.38,.25s-.42,.12-.73,.16c-.3,.04-.69,.06-1.16,.07-.46,0-.84,0-1.15-.04-.31-.03-.55-.08-.74-.15-.18-.06-.31-.14-.39-.24-.08-.1-.12-.21-.12-.34l-.23-22.13c0-.13,.04-.25,.11-.35,.08-.1,.21-.18,.39-.24,.18-.06,.43-.11,.73-.15,.31-.04,.69-.06,1.15-.07,.47,0,.86,0,1.16,.04,.3,.03,.55,.08,.73,.14,.18,.06,.31,.14,.38,.23,.07,.1,.11,.21,.11,.34l.1,10,6.78-10.06c.08-.16,.18-.29,.3-.39,.12-.1,.28-.19,.47-.25s.44-.11,.74-.13c.3-.03,.69-.04,1.16-.05,.48,0,.88,0,1.2,.04,.32,.03,.57,.08,.75,.15,.19,.06,.32,.15,.39,.24,.07,.1,.11,.2,.11,.33,0,.21-.05,.42-.16,.64s-.31,.57-.61,1.04l-6.36,8.51,7.14,10.55c.27,.49,.44,.82,.49,.98,.06,.16,.08,.29,.09,.39l.04-.03Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M522.09,667.02c-.06,.21-.13,.37-.23,.5s-.25,.23-.47,.3-.51,.11-.89,.14c-.38,.02-.87,.04-1.47,.04h-1.24c-.34,0-.64-.01-.88-.04-.24-.02-.44-.06-.59-.1-.15-.05-.28-.11-.38-.18s-.18-.16-.24-.26c-.06-.1-.11-.23-.16-.39l-7.21-20.75c-.15-.43-.24-.78-.27-1.03s.03-.45,.18-.57c.15-.13,.41-.21,.77-.24,.36-.03,.87-.05,1.52-.06,.56,0,.99,0,1.31,.02,.31,.02,.56,.06,.73,.12s.29,.15,.36,.27,.13,.27,.2,.45l5.91,18.06h.02l5.42-18.09c.05-.21,.11-.37,.18-.5s.19-.23,.37-.3,.42-.13,.75-.15c.33-.03,.77-.04,1.34-.05,.56,0,.99,0,1.3,.04,.31,.03,.52,.12,.64,.26s.16,.33,.12,.59c-.04,.25-.13,.59-.27,1.02l-6.79,20.92-.03-.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              </g>
              <g>
                <path d="M438.39,666.78c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="#fff"/>
                <path d="M457.23,666.59c0,.35,0,.65-.04,.9-.03,.25-.07,.45-.13,.61s-.14,.27-.23,.34c-.1,.07-.21,.1-.33,.1l-13.51,.14c-.27,0-.5-.02-.69-.07-.2-.05-.35-.14-.48-.28-.12-.14-.21-.34-.27-.61-.06-.27-.09-.61-.09-1.03s.01-.74,.04-1.03c.03-.28,.1-.54,.19-.76,.09-.22,.22-.44,.37-.66,.15-.21,.34-.45,.58-.71l4.02-4.4c.8-.86,1.45-1.63,1.94-2.34,.49-.7,.87-1.34,1.14-1.92,.27-.58,.46-1.11,.56-1.59,.1-.49,.14-.95,.14-1.38,0-.4-.07-.78-.2-1.13-.13-.36-.32-.67-.57-.93s-.56-.47-.94-.62-.82-.22-1.33-.21c-.71,0-1.35,.1-1.89,.29-.55,.19-1.03,.4-1.45,.62-.42,.23-.76,.44-1.04,.63s-.49,.29-.65,.29c-.11,0-.2-.03-.28-.11-.08-.07-.15-.19-.19-.36-.05-.17-.09-.4-.13-.68-.03-.28-.05-.63-.06-1.04,0-.28,0-.51,.02-.7s.04-.35,.08-.49c.04-.14,.08-.26,.14-.36,.06-.1,.16-.23,.31-.38s.43-.33,.83-.56,.9-.45,1.49-.67,1.24-.4,1.96-.56c.71-.15,1.46-.23,2.23-.24,1.22-.01,2.29,.13,3.21,.43,.92,.3,1.68,.72,2.29,1.27,.61,.54,1.07,1.19,1.37,1.95,.3,.75,.46,1.57,.47,2.44,0,.76-.06,1.51-.19,2.25-.14,.74-.43,1.54-.88,2.4-.45,.86-1.09,1.81-1.91,2.85s-1.92,2.26-3.28,3.64l-2.71,2.84,9.26-.1c.12,0,.23,.03,.34,.11s.19,.18,.27,.33c.07,.15,.13,.35,.17,.6s.06,.54,.06,.88h0Z" fill="#fff"/>
                <path d="M466.37,665.82c.01,1.13-.18,1.88-.57,2.28-.39,.39-1.09,.59-2.11,.6-1.02,.01-1.72-.17-2.11-.55-.39-.38-.59-1.1-.6-2.16-.01-1.14,.18-1.9,.57-2.29,.39-.39,1.1-.59,2.13-.6,1-.01,1.7,.17,2.09,.55,.39,.38,.59,1.1,.6,2.18h0Z" fill="#fff"/>
                <path d="M486.32,661.93c.01,1.07-.16,2.02-.52,2.86s-.9,1.55-1.61,2.13-1.6,1.03-2.66,1.34-2.29,.47-3.68,.48c-1.33,.01-2.5-.11-3.51-.36-1.01-.26-1.85-.64-2.54-1.14-.68-.51-1.2-1.14-1.54-1.89-.35-.75-.53-1.62-.54-2.6,0-.67,.09-1.29,.28-1.88s.48-1.14,.87-1.64c.39-.51,.87-.98,1.46-1.43,.58-.45,1.25-.88,2.01-1.28-.62-.33-1.18-.69-1.68-1.08s-.93-.81-1.28-1.28c-.36-.46-.63-.97-.81-1.53-.19-.55-.29-1.16-.29-1.81,0-.94,.15-1.8,.47-2.57s.81-1.44,1.47-2.01c.65-.57,1.48-1.01,2.46-1.32,.99-.31,2.14-.48,3.45-.49,1.29-.01,2.41,.11,3.33,.38,.93,.27,1.69,.65,2.29,1.16,.6,.5,1.04,1.11,1.33,1.81,.29,.71,.44,1.49,.45,2.35,0,.57-.08,1.12-.26,1.66-.18,.54-.44,1.05-.77,1.54s-.75,.95-1.24,1.38-1.05,.83-1.68,1.19c.74,.38,1.41,.78,1.99,1.21s1.08,.89,1.49,1.39,.72,1.03,.93,1.6c.22,.57,.33,1.18,.34,1.85v-.02Zm-4.73,.38c0-.45-.09-.86-.24-1.22-.16-.37-.4-.71-.72-1.04-.32-.32-.73-.63-1.22-.93s-1.05-.6-1.7-.91c-.55,.31-1.04,.62-1.46,.92-.42,.31-.77,.63-1.04,.96-.28,.34-.48,.69-.62,1.06-.14,.37-.2,.77-.2,1.19,0,.94,.32,1.66,.94,2.15,.62,.49,1.53,.73,2.74,.72,1.19-.01,2.07-.28,2.65-.79,.58-.51,.87-1.22,.86-2.12h.01Zm-.67-11.71c0-.42-.07-.8-.19-1.14s-.32-.62-.58-.86c-.26-.23-.59-.41-.97-.53-.38-.12-.83-.17-1.35-.17-1.02,.01-1.77,.25-2.26,.72-.49,.47-.73,1.12-.72,1.94,0,.39,.07,.74,.2,1.07s.33,.63,.6,.92c.27,.29,.62,.57,1.04,.84,.43,.27,.93,.57,1.52,.87,.88-.53,1.55-1.09,2.02-1.69s.7-1.26,.7-1.99v.02Z" fill="#fff"/>
                <path d="M507.22,667.3c0,.13-.03,.25-.1,.36-.07,.1-.19,.19-.38,.26s-.44,.12-.77,.16-.74,.06-1.23,.07c-.79,0-1.35-.02-1.7-.07-.35-.06-.59-.15-.75-.26-.15-.12-.27-.26-.36-.43l-7.21-11.07,.12,11.15c0,.13-.03,.25-.11,.35-.07,.1-.2,.18-.38,.25s-.42,.12-.73,.16c-.3,.04-.69,.06-1.16,.07-.46,0-.84,0-1.15-.04-.31-.03-.55-.08-.74-.15-.18-.06-.31-.14-.39-.24-.08-.1-.12-.21-.12-.34l-.23-22.13c0-.13,.04-.25,.11-.35,.08-.1,.21-.18,.39-.24,.18-.06,.43-.11,.73-.15,.31-.04,.69-.06,1.15-.07,.47,0,.86,0,1.16,.04,.3,.03,.55,.08,.73,.14,.18,.06,.31,.14,.38,.23,.07,.1,.11,.21,.11,.34l.1,10,6.78-10.06c.08-.16,.18-.29,.3-.39,.12-.1,.28-.19,.47-.25s.44-.11,.74-.13c.3-.03,.69-.04,1.16-.05,.48,0,.88,0,1.2,.04,.32,.03,.57,.08,.75,.15,.19,.06,.32,.15,.39,.24,.07,.1,.11,.2,.11,.33,0,.21-.05,.42-.16,.64s-.31,.57-.61,1.04l-6.36,8.51,7.14,10.55c.27,.49,.44,.82,.49,.98,.06,.16,.08,.29,.09,.39l.04-.03Z" fill="#fff"/>
                <path d="M522.09,667.02c-.06,.21-.13,.37-.23,.5s-.25,.23-.47,.3-.51,.11-.89,.14c-.38,.02-.87,.04-1.47,.04h-1.24c-.34,0-.64-.01-.88-.04-.24-.02-.44-.06-.59-.1-.15-.05-.28-.11-.38-.18s-.18-.16-.24-.26c-.06-.1-.11-.23-.16-.39l-7.21-20.75c-.15-.43-.24-.78-.27-1.03s.03-.45,.18-.57c.15-.13,.41-.21,.77-.24,.36-.03,.87-.05,1.52-.06,.56,0,.99,0,1.31,.02,.31,.02,.56,.06,.73,.12s.29,.15,.36,.27,.13,.27,.2,.45l5.91,18.06h.02l5.42-18.09c.05-.21,.11-.37,.18-.5s.19-.23,.37-.3,.42-.13,.75-.15c.33-.03,.77-.04,1.34-.05,.56,0,.99,0,1.3,.04,.31,.03,.52,.12,.64,.26s.16,.33,.12,.59c-.04,.25-.13,.59-.27,1.02l-6.79,20.92-.03-.02Z" fill="#fff"/>
              </g>
            </g>
            <g>
              <path d="M475.97,611.26c0,.59-.04,.99-.15,1.23-.11,.23-.28,.35-.53,.35l-6.3,.07c-.25,0-.44-.11-.55-.35s-.17-.64-.18-1.22c0-.57,.05-.96,.15-1.2,.11-.23,.29-.35,.54-.35l6.3-.07c.12,0,.22,.02,.31,.07s.16,.13,.22,.26c.06,.12,.11,.28,.13,.48,.03,.2,.04,.44,.05,.73h0Z" fill="#ffc900"/>
              <path d="M475.97,611.26c0,.59-.04,.99-.15,1.23-.11,.23-.28,.35-.53,.35l-6.3,.07c-.25,0-.44-.11-.55-.35s-.17-.64-.18-1.22c0-.57,.05-.96,.15-1.2,.11-.23,.29-.35,.54-.35l6.3-.07c.12,0,.22,.02,.31,.07s.16,.13,.22,.26c.06,.12,.11,.28,.13,.48,.03,.2,.04,.44,.05,.73h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              <path d="M475.97,611.26c0,.59-.04,.99-.15,1.23-.11,.23-.28,.35-.53,.35l-6.3,.07c-.25,0-.44-.11-.55-.35s-.17-.64-.18-1.22c0-.57,.05-.96,.15-1.2,.11-.23,.29-.35,.54-.35l6.3-.07c.12,0,.22,.02,.31,.07s.16,.13,.22,.26c.06,.12,.11,.28,.13,.48,.03,.2,.04,.44,.05,.73h0Z" fill="#ffc900"/>
            </g>
            <g>
              <g>
                <path d="M504.37,615.53c.02,1.57-.24,2.98-.78,4.22-.53,1.24-1.29,2.29-2.28,3.15-.99,.86-2.18,1.52-3.57,1.98-1.39,.46-2.95,.7-4.65,.72-.9,0-1.75-.04-2.56-.14s-1.54-.24-2.18-.4-1.17-.33-1.59-.5c-.41-.17-.68-.31-.81-.42s-.22-.23-.28-.37c-.06-.13-.11-.29-.15-.48s-.07-.42-.09-.71c-.02-.28-.03-.62-.03-1.01,0-.42,0-.78,.03-1.07s.07-.53,.14-.71c.06-.18,.14-.31,.23-.38,.09-.08,.19-.11,.31-.12,.15,0,.38,.08,.69,.25s.72,.36,1.22,.56,1.12,.39,1.84,.55,1.58,.24,2.59,.23c.87,0,1.66-.11,2.37-.29,.71-.19,1.31-.48,1.8-.88s.87-.9,1.13-1.51c.26-.6,.39-1.32,.38-2.16,0-.72-.13-1.36-.36-1.92-.23-.56-.59-1.03-1.07-1.42-.48-.38-1.11-.67-1.87-.86-.77-.19-1.7-.27-2.79-.26-.87,0-1.66,.06-2.37,.16s-1.38,.15-2.01,.16c-.43,0-.75-.1-.94-.32-.19-.22-.29-.61-.29-1.2l-.13-12.62c0-.6,.11-1.03,.33-1.3,.23-.26,.6-.4,1.11-.4l13.77-.14c.13,0,.26,.04,.38,.13s.22,.23,.31,.42c.08,.19,.15,.45,.19,.77s.06,.71,.07,1.16c0,.9-.06,1.55-.22,1.96-.15,.41-.38,.61-.68,.61l-10.33,.11,.07,6.22c.52-.07,1.04-.11,1.56-.13s1.06-.03,1.63-.04c1.57-.02,2.97,.15,4.19,.51,1.22,.35,2.26,.88,3.09,1.58,.84,.7,1.48,1.58,1.93,2.63,.45,1.05,.67,2.27,.69,3.67h-.02Z" fill="#ffc900"/>
                <path d="M528.48,610.06c.02,2.32-.14,4.42-.5,6.29-.35,1.88-.95,3.48-1.78,4.8-.83,1.33-1.92,2.35-3.27,3.07-1.35,.72-3,1.09-4.96,1.11-1.99,.02-3.64-.3-4.95-.97s-2.35-1.64-3.13-2.91c-.78-1.27-1.33-2.83-1.66-4.68-.33-1.85-.5-3.94-.53-6.27-.02-2.31,.14-4.4,.51-6.28,.36-1.88,.96-3.49,1.79-4.81,.83-1.33,1.92-2.35,3.27-3.07,1.35-.72,3-1.09,4.94-1.11,1.99-.02,3.65,.3,4.96,.97,1.32,.67,2.36,1.64,3.14,2.91,.78,1.27,1.32,2.83,1.64,4.68s.49,3.94,.51,6.27h.02Zm-5.95,.35c-.01-1.38-.06-2.59-.15-3.65-.09-1.05-.21-1.97-.38-2.75s-.37-1.43-.6-1.97c-.24-.54-.52-.96-.86-1.28-.33-.32-.71-.54-1.13-.67s-.89-.19-1.39-.19c-.88,0-1.61,.23-2.18,.66s-1.02,1.08-1.35,1.93c-.33,.86-.55,1.92-.67,3.2-.12,1.27-.17,2.76-.16,4.45,.02,2.07,.12,3.78,.3,5.13s.45,2.43,.81,3.23,.82,1.35,1.38,1.67,1.22,.47,2,.46c.58,0,1.1-.1,1.55-.3,.45-.19,.84-.48,1.17-.85,.33-.38,.61-.85,.83-1.42,.22-.57,.4-1.23,.53-1.98s.22-1.6,.26-2.54c.04-.94,.06-1.98,.05-3.12h-.01Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M504.37,615.53c.02,1.57-.24,2.98-.78,4.22-.53,1.24-1.29,2.29-2.28,3.15-.99,.86-2.18,1.52-3.57,1.98-1.39,.46-2.95,.7-4.65,.72-.9,0-1.75-.04-2.56-.14s-1.54-.24-2.18-.4-1.17-.33-1.59-.5c-.41-.17-.68-.31-.81-.42s-.22-.23-.28-.37c-.06-.13-.11-.29-.15-.48s-.07-.42-.09-.71c-.02-.28-.03-.62-.03-1.01,0-.42,0-.78,.03-1.07s.07-.53,.14-.71c.06-.18,.14-.31,.23-.38,.09-.08,.19-.11,.31-.12,.15,0,.38,.08,.69,.25s.72,.36,1.22,.56,1.12,.39,1.84,.55,1.58,.24,2.59,.23c.87,0,1.66-.11,2.37-.29,.71-.19,1.31-.48,1.8-.88s.87-.9,1.13-1.51c.26-.6,.39-1.32,.38-2.16,0-.72-.13-1.36-.36-1.92-.23-.56-.59-1.03-1.07-1.42-.48-.38-1.11-.67-1.87-.86-.77-.19-1.7-.27-2.79-.26-.87,0-1.66,.06-2.37,.16s-1.38,.15-2.01,.16c-.43,0-.75-.1-.94-.32-.19-.22-.29-.61-.29-1.2l-.13-12.62c0-.6,.11-1.03,.33-1.3,.23-.26,.6-.4,1.11-.4l13.77-.14c.13,0,.26,.04,.38,.13s.22,.23,.31,.42c.08,.19,.15,.45,.19,.77s.06,.71,.07,1.16c0,.9-.06,1.55-.22,1.96-.15,.41-.38,.61-.68,.61l-10.33,.11,.07,6.22c.52-.07,1.04-.11,1.56-.13s1.06-.03,1.63-.04c1.57-.02,2.97,.15,4.19,.51,1.22,.35,2.26,.88,3.09,1.58,.84,.7,1.48,1.58,1.93,2.63,.45,1.05,.67,2.27,.69,3.67h-.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M528.48,610.06c.02,2.32-.14,4.42-.5,6.29-.35,1.88-.95,3.48-1.78,4.8-.83,1.33-1.92,2.35-3.27,3.07-1.35,.72-3,1.09-4.96,1.11-1.99,.02-3.64-.3-4.95-.97s-2.35-1.64-3.13-2.91c-.78-1.27-1.33-2.83-1.66-4.68-.33-1.85-.5-3.94-.53-6.27-.02-2.31,.14-4.4,.51-6.28,.36-1.88,.96-3.49,1.79-4.81,.83-1.33,1.92-2.35,3.27-3.07,1.35-.72,3-1.09,4.94-1.11,1.99-.02,3.65,.3,4.96,.97,1.32,.67,2.36,1.64,3.14,2.91,.78,1.27,1.32,2.83,1.64,4.68s.49,3.94,.51,6.27h.02Zm-5.95,.35c-.01-1.38-.06-2.59-.15-3.65-.09-1.05-.21-1.97-.38-2.75s-.37-1.43-.6-1.97c-.24-.54-.52-.96-.86-1.28-.33-.32-.71-.54-1.13-.67s-.89-.19-1.39-.19c-.88,0-1.61,.23-2.18,.66s-1.02,1.08-1.35,1.93c-.33,.86-.55,1.92-.67,3.2-.12,1.27-.17,2.76-.16,4.45,.02,2.07,.12,3.78,.3,5.13s.45,2.43,.81,3.23,.82,1.35,1.38,1.67,1.22,.47,2,.46c.58,0,1.1-.1,1.55-.3,.45-.19,.84-.48,1.17-.85,.33-.38,.61-.85,.83-1.42,.22-.57,.4-1.23,.53-1.98s.22-1.6,.26-2.54c.04-.94,.06-1.98,.05-3.12h-.01Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              </g>
              <g>
                <path d="M504.37,615.53c.02,1.57-.24,2.98-.78,4.22-.53,1.24-1.29,2.29-2.28,3.15-.99,.86-2.18,1.52-3.57,1.98-1.39,.46-2.95,.7-4.65,.72-.9,0-1.75-.04-2.56-.14s-1.54-.24-2.18-.4-1.17-.33-1.59-.5c-.41-.17-.68-.31-.81-.42s-.22-.23-.28-.37c-.06-.13-.11-.29-.15-.48s-.07-.42-.09-.71c-.02-.28-.03-.62-.03-1.01,0-.42,0-.78,.03-1.07s.07-.53,.14-.71c.06-.18,.14-.31,.23-.38,.09-.08,.19-.11,.31-.12,.15,0,.38,.08,.69,.25s.72,.36,1.22,.56,1.12,.39,1.84,.55,1.58,.24,2.59,.23c.87,0,1.66-.11,2.37-.29,.71-.19,1.31-.48,1.8-.88s.87-.9,1.13-1.51c.26-.6,.39-1.32,.38-2.16,0-.72-.13-1.36-.36-1.92-.23-.56-.59-1.03-1.07-1.42-.48-.38-1.11-.67-1.87-.86-.77-.19-1.7-.27-2.79-.26-.87,0-1.66,.06-2.37,.16s-1.38,.15-2.01,.16c-.43,0-.75-.1-.94-.32-.19-.22-.29-.61-.29-1.2l-.13-12.62c0-.6,.11-1.03,.33-1.3,.23-.26,.6-.4,1.11-.4l13.77-.14c.13,0,.26,.04,.38,.13s.22,.23,.31,.42c.08,.19,.15,.45,.19,.77s.06,.71,.07,1.16c0,.9-.06,1.55-.22,1.96-.15,.41-.38,.61-.68,.61l-10.33,.11,.07,6.22c.52-.07,1.04-.11,1.56-.13s1.06-.03,1.63-.04c1.57-.02,2.97,.15,4.19,.51,1.22,.35,2.26,.88,3.09,1.58,.84,.7,1.48,1.58,1.93,2.63,.45,1.05,.67,2.27,.69,3.67h-.02Z" fill="#ffc900"/>
                <path d="M528.48,610.06c.02,2.32-.14,4.42-.5,6.29-.35,1.88-.95,3.48-1.78,4.8-.83,1.33-1.92,2.35-3.27,3.07-1.35,.72-3,1.09-4.96,1.11-1.99,.02-3.64-.3-4.95-.97s-2.35-1.64-3.13-2.91c-.78-1.27-1.33-2.83-1.66-4.68-.33-1.85-.5-3.94-.53-6.27-.02-2.31,.14-4.4,.51-6.28,.36-1.88,.96-3.49,1.79-4.81,.83-1.33,1.92-2.35,3.27-3.07,1.35-.72,3-1.09,4.94-1.11,1.99-.02,3.65,.3,4.96,.97,1.32,.67,2.36,1.64,3.14,2.91,.78,1.27,1.32,2.83,1.64,4.68s.49,3.94,.51,6.27h.02Zm-5.95,.35c-.01-1.38-.06-2.59-.15-3.65-.09-1.05-.21-1.97-.38-2.75s-.37-1.43-.6-1.97c-.24-.54-.52-.96-.86-1.28-.33-.32-.71-.54-1.13-.67s-.89-.19-1.39-.19c-.88,0-1.61,.23-2.18,.66s-1.02,1.08-1.35,1.93c-.33,.86-.55,1.92-.67,3.2-.12,1.27-.17,2.76-.16,4.45,.02,2.07,.12,3.78,.3,5.13s.45,2.43,.81,3.23,.82,1.35,1.38,1.67,1.22,.47,2,.46c.58,0,1.1-.1,1.55-.3,.45-.19,.84-.48,1.17-.85,.33-.38,.61-.85,.83-1.42,.22-.57,.4-1.23,.53-1.98s.22-1.6,.26-2.54c.04-.94,.06-1.98,.05-3.12h-.01Z" fill="#ffc900"/>
              </g>
            </g>
            <g>
              <g>
                <path d="M428.38,605.9c0,.17,0,.32-.03,.44s-.04,.22-.08,.29-.07,.12-.12,.15c-.04,.03-.09,.05-.15,.05h-6.61s-.1-.02-.14-.05-.08-.08-.12-.15c-.03-.07-.06-.17-.08-.29s-.03-.27-.03-.44,0-.33,.02-.45,.04-.22,.07-.29,.07-.13,.12-.16c.04-.03,.09-.05,.15-.05h2.23v-7.79l-1.92,1.06c-.14,.07-.26,.11-.35,.12-.09,.02-.16,0-.21-.06-.05-.05-.09-.14-.11-.27s-.03-.31-.03-.55v-.37c0-.1,.02-.18,.05-.25,.02-.07,.06-.12,.1-.17,.04-.04,.1-.09,.18-.14l2.57-1.67s.07-.04,.12-.06,.11-.03,.18-.04c.07,0,.17-.02,.29-.02h.47c.23,0,.42,0,.57,.01,.14,0,.25,.02,.33,.05,.07,.02,.12,.05,.15,.09s.04,.08,.04,.14v9.9h1.95c.06,0,.11,.02,.15,.05,.05,.03,.09,.09,.12,.16,.03,.07,.06,.17,.07,.29s.02,.27,.02,.45l.03,.02Z" fill="#ffc900"/>
                <path d="M437.86,603.31c0,.6-.11,1.13-.34,1.59s-.55,.85-.95,1.17c-.41,.31-.89,.55-1.46,.72-.57,.16-1.19,.25-1.86,.25-.41,0-.79-.03-1.15-.09s-.67-.13-.95-.22-.5-.18-.68-.27-.3-.16-.35-.21c-.06-.05-.1-.1-.12-.17-.03-.06-.05-.13-.07-.22-.02-.08-.04-.19-.05-.31,0-.13-.01-.28-.01-.46,0-.3,.02-.5,.07-.62,.05-.11,.12-.17,.22-.17,.06,0,.17,.04,.32,.12s.34,.17,.58,.27c.23,.1,.51,.19,.82,.27s.67,.12,1.07,.12c.34,0,.64-.04,.9-.12,.26-.08,.48-.19,.66-.34s.32-.32,.41-.53,.13-.44,.13-.69c0-.28-.05-.53-.16-.75-.11-.22-.27-.41-.48-.57-.21-.16-.48-.28-.81-.37-.32-.09-.71-.13-1.14-.13h-1.04c-.08,0-.15-.01-.2-.03-.06-.02-.1-.07-.14-.13-.04-.07-.06-.16-.08-.28-.01-.12-.02-.28-.02-.47,0-.18,0-.33,.02-.44s.04-.2,.07-.26,.08-.1,.13-.13c.05-.02,.12-.04,.19-.04h1.05c.36,0,.68-.04,.95-.12,.28-.08,.51-.2,.7-.36,.19-.15,.33-.34,.43-.56s.15-.46,.15-.73c0-.2-.03-.4-.1-.58s-.17-.34-.3-.47c-.13-.13-.3-.24-.51-.31s-.46-.12-.74-.12c-.32,0-.62,.05-.91,.14-.28,.1-.54,.2-.76,.31-.23,.11-.42,.22-.57,.32-.16,.1-.27,.15-.35,.15-.05,0-.09-.01-.13-.03s-.07-.06-.09-.12-.04-.15-.05-.27c-.01-.12-.02-.27-.02-.45v-.38c0-.1,.02-.19,.04-.25s.04-.13,.07-.18,.07-.1,.13-.16,.19-.15,.38-.26c.19-.12,.43-.23,.72-.34,.29-.11,.62-.21,1-.28,.38-.08,.79-.12,1.24-.12,.58,0,1.09,.07,1.54,.2,.45,.14,.82,.33,1.13,.59s.54,.57,.69,.94c.16,.37,.24,.79,.24,1.26,0,.36-.05,.7-.14,1.02-.09,.31-.23,.6-.41,.84-.18,.25-.4,.46-.67,.63-.27,.17-.58,.3-.93,.39v.03c.42,.05,.8,.16,1.13,.32,.33,.16,.62,.37,.85,.62s.41,.53,.53,.84,.18,.65,.18,1h0Z" fill="#ffc900"/>
                <path d="M447.52,603.61c0,.54-.09,1.03-.28,1.45-.19,.43-.47,.79-.83,1.08-.37,.29-.82,.52-1.37,.67s-1.17,.23-1.88,.23-1.27-.07-1.79-.2c-.51-.14-.94-.33-1.29-.6s-.61-.59-.78-.97c-.17-.39-.26-.83-.26-1.33,0-.34,.05-.66,.15-.96,.1-.3,.25-.58,.45-.83,.2-.26,.45-.5,.75-.72,.3-.23,.64-.44,1.03-.64-.31-.17-.6-.36-.85-.56s-.47-.42-.65-.66-.31-.5-.41-.78c-.09-.28-.14-.59-.14-.93,0-.48,.08-.92,.25-1.31s.42-.73,.76-1.02c.34-.29,.76-.51,1.26-.66,.51-.15,1.09-.23,1.76-.23s1.23,.07,1.7,.21,.86,.34,1.16,.6c.3,.26,.53,.57,.67,.93s.22,.76,.22,1.2c0,.29-.05,.57-.14,.85-.1,.27-.23,.54-.4,.78-.17,.25-.39,.48-.64,.7-.25,.22-.54,.42-.86,.6,.38,.2,.71,.41,1.01,.63s.55,.46,.75,.72c.2,.26,.36,.53,.47,.82,.11,.29,.16,.6,.16,.94h.02Zm-2.41,.16c0-.23-.04-.44-.12-.62-.08-.19-.2-.37-.36-.53-.16-.17-.37-.33-.62-.48-.25-.15-.53-.31-.86-.47-.28,.15-.53,.31-.75,.46s-.39,.32-.54,.49c-.14,.17-.25,.35-.32,.54s-.11,.39-.11,.61c0,.48,.16,.85,.47,1.1s.78,.38,1.39,.38,1.06-.13,1.36-.39,.45-.62,.45-1.07v-.02Zm-.27-5.98c0-.22-.03-.41-.09-.58-.06-.17-.16-.32-.29-.44s-.3-.21-.49-.27-.42-.09-.69-.09c-.52,0-.9,.12-1.16,.36-.25,.24-.38,.57-.38,.99,0,.2,.03,.38,.1,.55,.06,.17,.17,.32,.3,.47s.31,.29,.53,.44c.22,.14,.47,.29,.77,.45,.45-.27,.8-.55,1.04-.85,.24-.3,.37-.64,.37-1.01v-.02Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M428.38,605.9c0,.17,0,.32-.03,.44s-.04,.22-.08,.29-.07,.12-.12,.15c-.04,.03-.09,.05-.15,.05h-6.61s-.1-.02-.14-.05-.08-.08-.12-.15c-.03-.07-.06-.17-.08-.29s-.03-.27-.03-.44,0-.33,.02-.45,.04-.22,.07-.29,.07-.13,.12-.16c.04-.03,.09-.05,.15-.05h2.23v-7.79l-1.92,1.06c-.14,.07-.26,.11-.35,.12-.09,.02-.16,0-.21-.06-.05-.05-.09-.14-.11-.27s-.03-.31-.03-.55v-.37c0-.1,.02-.18,.05-.25,.02-.07,.06-.12,.1-.17,.04-.04,.1-.09,.18-.14l2.57-1.67s.07-.04,.12-.06,.11-.03,.18-.04c.07,0,.17-.02,.29-.02h.47c.23,0,.42,0,.57,.01,.14,0,.25,.02,.33,.05,.07,.02,.12,.05,.15,.09s.04,.08,.04,.14v9.9h1.95c.06,0,.11,.02,.15,.05,.05,.03,.09,.09,.12,.16,.03,.07,.06,.17,.07,.29s.02,.27,.02,.45l.03,.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M437.86,603.31c0,.6-.11,1.13-.34,1.59s-.55,.85-.95,1.17c-.41,.31-.89,.55-1.46,.72-.57,.16-1.19,.25-1.86,.25-.41,0-.79-.03-1.15-.09s-.67-.13-.95-.22-.5-.18-.68-.27-.3-.16-.35-.21c-.06-.05-.1-.1-.12-.17-.03-.06-.05-.13-.07-.22-.02-.08-.04-.19-.05-.31,0-.13-.01-.28-.01-.46,0-.3,.02-.5,.07-.62,.05-.11,.12-.17,.22-.17,.06,0,.17,.04,.32,.12s.34,.17,.58,.27c.23,.1,.51,.19,.82,.27s.67,.12,1.07,.12c.34,0,.64-.04,.9-.12,.26-.08,.48-.19,.66-.34s.32-.32,.41-.53,.13-.44,.13-.69c0-.28-.05-.53-.16-.75-.11-.22-.27-.41-.48-.57-.21-.16-.48-.28-.81-.37-.32-.09-.71-.13-1.14-.13h-1.04c-.08,0-.15-.01-.2-.03-.06-.02-.1-.07-.14-.13-.04-.07-.06-.16-.08-.28-.01-.12-.02-.28-.02-.47,0-.18,0-.33,.02-.44s.04-.2,.07-.26,.08-.1,.13-.13c.05-.02,.12-.04,.19-.04h1.05c.36,0,.68-.04,.95-.12,.28-.08,.51-.2,.7-.36,.19-.15,.33-.34,.43-.56s.15-.46,.15-.73c0-.2-.03-.4-.1-.58s-.17-.34-.3-.47c-.13-.13-.3-.24-.51-.31s-.46-.12-.74-.12c-.32,0-.62,.05-.91,.14-.28,.1-.54,.2-.76,.31-.23,.11-.42,.22-.57,.32-.16,.1-.27,.15-.35,.15-.05,0-.09-.01-.13-.03s-.07-.06-.09-.12-.04-.15-.05-.27c-.01-.12-.02-.27-.02-.45v-.38c0-.1,.02-.19,.04-.25s.04-.13,.07-.18,.07-.1,.13-.16,.19-.15,.38-.26c.19-.12,.43-.23,.72-.34,.29-.11,.62-.21,1-.28,.38-.08,.79-.12,1.24-.12,.58,0,1.09,.07,1.54,.2,.45,.14,.82,.33,1.13,.59s.54,.57,.69,.94c.16,.37,.24,.79,.24,1.26,0,.36-.05,.7-.14,1.02-.09,.31-.23,.6-.41,.84-.18,.25-.4,.46-.67,.63-.27,.17-.58,.3-.93,.39v.03c.42,.05,.8,.16,1.13,.32,.33,.16,.62,.37,.85,.62s.41,.53,.53,.84,.18,.65,.18,1h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M447.52,603.61c0,.54-.09,1.03-.28,1.45-.19,.43-.47,.79-.83,1.08-.37,.29-.82,.52-1.37,.67s-1.17,.23-1.88,.23-1.27-.07-1.79-.2c-.51-.14-.94-.33-1.29-.6s-.61-.59-.78-.97c-.17-.39-.26-.83-.26-1.33,0-.34,.05-.66,.15-.96,.1-.3,.25-.58,.45-.83,.2-.26,.45-.5,.75-.72,.3-.23,.64-.44,1.03-.64-.31-.17-.6-.36-.85-.56s-.47-.42-.65-.66-.31-.5-.41-.78c-.09-.28-.14-.59-.14-.93,0-.48,.08-.92,.25-1.31s.42-.73,.76-1.02c.34-.29,.76-.51,1.26-.66,.51-.15,1.09-.23,1.76-.23s1.23,.07,1.7,.21,.86,.34,1.16,.6c.3,.26,.53,.57,.67,.93s.22,.76,.22,1.2c0,.29-.05,.57-.14,.85-.1,.27-.23,.54-.4,.78-.17,.25-.39,.48-.64,.7-.25,.22-.54,.42-.86,.6,.38,.2,.71,.41,1.01,.63s.55,.46,.75,.72c.2,.26,.36,.53,.47,.82,.11,.29,.16,.6,.16,.94h.02Zm-2.41,.16c0-.23-.04-.44-.12-.62-.08-.19-.2-.37-.36-.53-.16-.17-.37-.33-.62-.48-.25-.15-.53-.31-.86-.47-.28,.15-.53,.31-.75,.46s-.39,.32-.54,.49c-.14,.17-.25,.35-.32,.54s-.11,.39-.11,.61c0,.48,.16,.85,.47,1.1s.78,.38,1.39,.38,1.06-.13,1.36-.39,.45-.62,.45-1.07v-.02Zm-.27-5.98c0-.22-.03-.41-.09-.58-.06-.17-.16-.32-.29-.44s-.3-.21-.49-.27-.42-.09-.69-.09c-.52,0-.9,.12-1.16,.36-.25,.24-.38,.57-.38,.99,0,.2,.03,.38,.1,.55,.06,.17,.17,.32,.3,.47s.31,.29,.53,.44c.22,.14,.47,.29,.77,.45,.45-.27,.8-.55,1.04-.85,.24-.3,.37-.64,.37-1.01v-.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              </g>
              <g>
                <path d="M428.38,605.9c0,.17,0,.32-.03,.44s-.04,.22-.08,.29-.07,.12-.12,.15c-.04,.03-.09,.05-.15,.05h-6.61s-.1-.02-.14-.05-.08-.08-.12-.15c-.03-.07-.06-.17-.08-.29s-.03-.27-.03-.44,0-.33,.02-.45,.04-.22,.07-.29,.07-.13,.12-.16c.04-.03,.09-.05,.15-.05h2.23v-7.79l-1.92,1.06c-.14,.07-.26,.11-.35,.12-.09,.02-.16,0-.21-.06-.05-.05-.09-.14-.11-.27s-.03-.31-.03-.55v-.37c0-.1,.02-.18,.05-.25,.02-.07,.06-.12,.1-.17,.04-.04,.1-.09,.18-.14l2.57-1.67s.07-.04,.12-.06,.11-.03,.18-.04c.07,0,.17-.02,.29-.02h.47c.23,0,.42,0,.57,.01,.14,0,.25,.02,.33,.05,.07,.02,.12,.05,.15,.09s.04,.08,.04,.14v9.9h1.95c.06,0,.11,.02,.15,.05,.05,.03,.09,.09,.12,.16,.03,.07,.06,.17,.07,.29s.02,.27,.02,.45l.03,.02Z" fill="#ffc900"/>
                <path d="M437.86,603.31c0,.6-.11,1.13-.34,1.59s-.55,.85-.95,1.17c-.41,.31-.89,.55-1.46,.72-.57,.16-1.19,.25-1.86,.25-.41,0-.79-.03-1.15-.09s-.67-.13-.95-.22-.5-.18-.68-.27-.3-.16-.35-.21c-.06-.05-.1-.1-.12-.17-.03-.06-.05-.13-.07-.22-.02-.08-.04-.19-.05-.31,0-.13-.01-.28-.01-.46,0-.3,.02-.5,.07-.62,.05-.11,.12-.17,.22-.17,.06,0,.17,.04,.32,.12s.34,.17,.58,.27c.23,.1,.51,.19,.82,.27s.67,.12,1.07,.12c.34,0,.64-.04,.9-.12,.26-.08,.48-.19,.66-.34s.32-.32,.41-.53,.13-.44,.13-.69c0-.28-.05-.53-.16-.75-.11-.22-.27-.41-.48-.57-.21-.16-.48-.28-.81-.37-.32-.09-.71-.13-1.14-.13h-1.04c-.08,0-.15-.01-.2-.03-.06-.02-.1-.07-.14-.13-.04-.07-.06-.16-.08-.28-.01-.12-.02-.28-.02-.47,0-.18,0-.33,.02-.44s.04-.2,.07-.26,.08-.1,.13-.13c.05-.02,.12-.04,.19-.04h1.05c.36,0,.68-.04,.95-.12,.28-.08,.51-.2,.7-.36,.19-.15,.33-.34,.43-.56s.15-.46,.15-.73c0-.2-.03-.4-.1-.58s-.17-.34-.3-.47c-.13-.13-.3-.24-.51-.31s-.46-.12-.74-.12c-.32,0-.62,.05-.91,.14-.28,.1-.54,.2-.76,.31-.23,.11-.42,.22-.57,.32-.16,.1-.27,.15-.35,.15-.05,0-.09-.01-.13-.03s-.07-.06-.09-.12-.04-.15-.05-.27c-.01-.12-.02-.27-.02-.45v-.38c0-.1,.02-.19,.04-.25s.04-.13,.07-.18,.07-.1,.13-.16,.19-.15,.38-.26c.19-.12,.43-.23,.72-.34,.29-.11,.62-.21,1-.28,.38-.08,.79-.12,1.24-.12,.58,0,1.09,.07,1.54,.2,.45,.14,.82,.33,1.13,.59s.54,.57,.69,.94c.16,.37,.24,.79,.24,1.26,0,.36-.05,.7-.14,1.02-.09,.31-.23,.6-.41,.84-.18,.25-.4,.46-.67,.63-.27,.17-.58,.3-.93,.39v.03c.42,.05,.8,.16,1.13,.32,.33,.16,.62,.37,.85,.62s.41,.53,.53,.84,.18,.65,.18,1h0Z" fill="#ffc900"/>
                <path d="M447.52,603.61c0,.54-.09,1.03-.28,1.45-.19,.43-.47,.79-.83,1.08-.37,.29-.82,.52-1.37,.67s-1.17,.23-1.88,.23-1.27-.07-1.79-.2c-.51-.14-.94-.33-1.29-.6s-.61-.59-.78-.97c-.17-.39-.26-.83-.26-1.33,0-.34,.05-.66,.15-.96,.1-.3,.25-.58,.45-.83,.2-.26,.45-.5,.75-.72,.3-.23,.64-.44,1.03-.64-.31-.17-.6-.36-.85-.56s-.47-.42-.65-.66-.31-.5-.41-.78c-.09-.28-.14-.59-.14-.93,0-.48,.08-.92,.25-1.31s.42-.73,.76-1.02c.34-.29,.76-.51,1.26-.66,.51-.15,1.09-.23,1.76-.23s1.23,.07,1.7,.21,.86,.34,1.16,.6c.3,.26,.53,.57,.67,.93s.22,.76,.22,1.2c0,.29-.05,.57-.14,.85-.1,.27-.23,.54-.4,.78-.17,.25-.39,.48-.64,.7-.25,.22-.54,.42-.86,.6,.38,.2,.71,.41,1.01,.63s.55,.46,.75,.72c.2,.26,.36,.53,.47,.82,.11,.29,.16,.6,.16,.94h.02Zm-2.41,.16c0-.23-.04-.44-.12-.62-.08-.19-.2-.37-.36-.53-.16-.17-.37-.33-.62-.48-.25-.15-.53-.31-.86-.47-.28,.15-.53,.31-.75,.46s-.39,.32-.54,.49c-.14,.17-.25,.35-.32,.54s-.11,.39-.11,.61c0,.48,.16,.85,.47,1.1s.78,.38,1.39,.38,1.06-.13,1.36-.39,.45-.62,.45-1.07v-.02Zm-.27-5.98c0-.22-.03-.41-.09-.58-.06-.17-.16-.32-.29-.44s-.3-.21-.49-.27-.42-.09-.69-.09c-.52,0-.9,.12-1.16,.36-.25,.24-.38,.57-.38,.99,0,.2,.03,.38,.1,.55,.06,.17,.17,.32,.3,.47s.31,.29,.53,.44c.22,.14,.47,.29,.77,.45,.45-.27,.8-.55,1.04-.85,.24-.3,.37-.64,.37-1.01v-.02Z" fill="#ffc900"/>
              </g>
            </g>
            <g>
              <path d="M437.84,620.14c-.09,.06-.17,.1-.25,.12s-.18,.01-.3-.01c-.11-.03-.25-.08-.39-.15-.15-.07-.33-.17-.54-.31-.26-.17-.47-.32-.62-.45s-.25-.25-.31-.36c-.05-.11-.06-.2-.02-.29,.04-.09,.11-.18,.22-.26l16.02-12.54c.08-.07,.16-.11,.25-.13s.18-.02,.29,0c.11,.03,.24,.08,.4,.15,.16,.08,.33,.18,.54,.31,.27,.17,.48,.32,.63,.45s.25,.25,.3,.35,.06,.2,.02,.29c-.04,.09-.11,.18-.21,.27l-16.02,12.56h-.01Z" fill="#ffc900"/>
              <path d="M437.84,620.14c-.09,.06-.17,.1-.25,.12s-.18,.01-.3-.01c-.11-.03-.25-.08-.39-.15-.15-.07-.33-.17-.54-.31-.26-.17-.47-.32-.62-.45s-.25-.25-.31-.36c-.05-.11-.06-.2-.02-.29,.04-.09,.11-.18,.22-.26l16.02-12.54c.08-.07,.16-.11,.25-.13s.18-.02,.29,0c.11,.03,.24,.08,.4,.15,.16,.08,.33,.18,.54,.31,.27,.17,.48,.32,.63,.45s.25,.25,.3,.35,.06,.2,.02,.29c-.04,.09-.11,.18-.21,.27l-16.02,12.56h-.01Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              <path d="M437.84,620.14c-.09,.06-.17,.1-.25,.12s-.18,.01-.3-.01c-.11-.03-.25-.08-.39-.15-.15-.07-.33-.17-.54-.31-.26-.17-.47-.32-.62-.45s-.25-.25-.31-.36c-.05-.11-.06-.2-.02-.29,.04-.09,.11-.18,.22-.26l16.02-12.54c.08-.07,.16-.11,.25-.13s.18-.02,.29,0c.11,.03,.24,.08,.4,.15,.16,.08,.33,.18,.54,.31,.27,.17,.48,.32,.63,.45s.25,.25,.3,.35,.06,.2,.02,.29c-.04,.09-.11,.18-.21,.27l-16.02,12.56h-.01Z" fill="#ffc900"/>
            </g>
            <rect x="439.55" y="678.25" width="68.13" height="9.18" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <path d="M452.15,625.52c0,.6-.08,1.17-.26,1.68-.18,.52-.45,.96-.8,1.34-.35,.38-.79,.67-1.31,.89-.52,.22-1.13,.33-1.81,.34-.56,0-1.04-.05-1.46-.17-.41-.12-.77-.3-1.08-.54-.3-.23-.55-.52-.75-.86-.19-.34-.35-.72-.47-1.14-.12-.42-.2-.89-.25-1.39s-.08-1.03-.08-1.59c0-.47,.01-.96,.05-1.47,.04-.51,.12-1.02,.25-1.51s.31-.97,.55-1.42,.54-.84,.92-1.19c.38-.34,.83-.61,1.37-.82,.54-.2,1.18-.31,1.92-.32,.23,0,.47,0,.71,.03s.46,.06,.66,.1,.38,.09,.52,.14,.24,.1,.28,.14c.05,.04,.08,.08,.11,.13s.05,.1,.06,.16c.02,.06,.03,.13,.04,.22,0,.09,.01,.19,.01,.31v.48c0,.13-.02,.23-.05,.3-.02,.07-.06,.12-.1,.15s-.09,.05-.16,.05-.17-.02-.29-.06-.26-.08-.43-.13c-.17-.05-.36-.09-.59-.12s-.49-.05-.78-.05c-.5,0-.92,.1-1.26,.29s-.62,.44-.83,.76-.37,.69-.46,1.11-.14,.87-.14,1.33c.13-.09,.29-.18,.46-.26s.37-.16,.57-.23,.43-.12,.67-.16c.24-.04,.5-.07,.77-.07,.6,0,1.12,.07,1.56,.23,.43,.16,.79,.4,1.07,.7,.28,.31,.48,.68,.62,1.11,.13,.44,.2,.92,.21,1.46l-.02,.05Zm-2.39,.26c0-.31-.03-.59-.09-.83s-.15-.44-.28-.6c-.12-.16-.29-.28-.5-.36-.2-.08-.45-.12-.74-.12-.17,0-.34,.02-.51,.05-.17,.03-.33,.08-.49,.13-.16,.06-.31,.13-.46,.2-.15,.08-.29,.16-.41,.25,0,.66,.05,1.21,.13,1.65,.08,.44,.19,.79,.34,1.05s.33,.44,.55,.55,.47,.16,.76,.15c.28,0,.53-.06,.74-.17s.39-.26,.53-.45c.14-.19,.25-.42,.32-.67,.07-.26,.11-.54,.1-.84h0Z" fill="#ffc900"/>
                <path d="M461.57,622.89c0,.49-.01,.99-.06,1.51-.04,.52-.13,1.03-.27,1.53-.13,.5-.32,.98-.56,1.43s-.55,.85-.93,1.19-.85,.61-1.4,.82c-.55,.2-1.2,.31-1.96,.32-.26,0-.53-.01-.79-.05-.26-.03-.5-.08-.72-.13s-.4-.11-.55-.17-.26-.12-.33-.19c-.07-.06-.12-.16-.15-.28-.03-.12-.05-.3-.05-.52v-.49c0-.12,.03-.22,.05-.29,.03-.07,.06-.11,.1-.14,.04-.02,.09-.03,.14-.03,.07,0,.18,.02,.31,.06,.14,.04,.3,.09,.5,.14,.19,.05,.42,.1,.66,.14,.25,.04,.52,.06,.82,.06,.52,0,.96-.1,1.31-.29,.36-.19,.64-.44,.86-.76s.38-.68,.48-1.1c.1-.41,.16-.85,.16-1.31-.28,.18-.62,.34-1.02,.48-.4,.14-.86,.21-1.38,.21-.64,0-1.18-.07-1.62-.23s-.81-.4-1.09-.71-.49-.69-.62-1.14-.2-.96-.21-1.53c0-.6,.08-1.15,.25-1.66,.17-.5,.43-.94,.78-1.31s.78-.66,1.31-.87c.52-.21,1.13-.32,1.82-.32,.56,0,1.04,.05,1.46,.18,.42,.13,.78,.31,1.08,.54,.3,.24,.55,.53,.76,.87,.21,.34,.37,.72,.48,1.14,.12,.42,.2,.87,.25,1.36s.08,1,.08,1.53h.05Zm-2.42-.3c0-.66-.05-1.21-.13-1.66-.08-.44-.19-.8-.35-1.06-.15-.26-.34-.45-.56-.56s-.48-.16-.78-.16-.54,.06-.75,.16-.38,.25-.52,.44c-.14,.19-.24,.41-.3,.66s-.1,.53-.09,.83c0,.33,.04,.62,.1,.86,.06,.25,.16,.46,.29,.62s.29,.28,.5,.36c.2,.08,.45,.12,.74,.11,.36,0,.69-.06,1.01-.17s.6-.26,.84-.44h0Z" fill="#ffc900"/>
              </g>
              <g>
                <path d="M452.15,625.52c0,.6-.08,1.17-.26,1.68-.18,.52-.45,.96-.8,1.34-.35,.38-.79,.67-1.31,.89-.52,.22-1.13,.33-1.81,.34-.56,0-1.04-.05-1.46-.17-.41-.12-.77-.3-1.08-.54-.3-.23-.55-.52-.75-.86-.19-.34-.35-.72-.47-1.14-.12-.42-.2-.89-.25-1.39s-.08-1.03-.08-1.59c0-.47,.01-.96,.05-1.47,.04-.51,.12-1.02,.25-1.51s.31-.97,.55-1.42,.54-.84,.92-1.19c.38-.34,.83-.61,1.37-.82,.54-.2,1.18-.31,1.92-.32,.23,0,.47,0,.71,.03s.46,.06,.66,.1,.38,.09,.52,.14,.24,.1,.28,.14c.05,.04,.08,.08,.11,.13s.05,.1,.06,.16c.02,.06,.03,.13,.04,.22,0,.09,.01,.19,.01,.31v.48c0,.13-.02,.23-.05,.3-.02,.07-.06,.12-.1,.15s-.09,.05-.16,.05-.17-.02-.29-.06-.26-.08-.43-.13c-.17-.05-.36-.09-.59-.12s-.49-.05-.78-.05c-.5,0-.92,.1-1.26,.29s-.62,.44-.83,.76-.37,.69-.46,1.11-.14,.87-.14,1.33c.13-.09,.29-.18,.46-.26s.37-.16,.57-.23,.43-.12,.67-.16c.24-.04,.5-.07,.77-.07,.6,0,1.12,.07,1.56,.23,.43,.16,.79,.4,1.07,.7,.28,.31,.48,.68,.62,1.11,.13,.44,.2,.92,.21,1.46l-.02,.05Zm-2.39,.26c0-.31-.03-.59-.09-.83s-.15-.44-.28-.6c-.12-.16-.29-.28-.5-.36-.2-.08-.45-.12-.74-.12-.17,0-.34,.02-.51,.05-.17,.03-.33,.08-.49,.13-.16,.06-.31,.13-.46,.2-.15,.08-.29,.16-.41,.25,0,.66,.05,1.21,.13,1.65,.08,.44,.19,.79,.34,1.05s.33,.44,.55,.55,.47,.16,.76,.15c.28,0,.53-.06,.74-.17s.39-.26,.53-.45c.14-.19,.25-.42,.32-.67,.07-.26,.11-.54,.1-.84h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                <path d="M461.57,622.89c0,.49-.01,.99-.06,1.51-.04,.52-.13,1.03-.27,1.53-.13,.5-.32,.98-.56,1.43s-.55,.85-.93,1.19-.85,.61-1.4,.82c-.55,.2-1.2,.31-1.96,.32-.26,0-.53-.01-.79-.05-.26-.03-.5-.08-.72-.13s-.4-.11-.55-.17-.26-.12-.33-.19c-.07-.06-.12-.16-.15-.28-.03-.12-.05-.3-.05-.52v-.49c0-.12,.03-.22,.05-.29,.03-.07,.06-.11,.1-.14,.04-.02,.09-.03,.14-.03,.07,0,.18,.02,.31,.06,.14,.04,.3,.09,.5,.14,.19,.05,.42,.1,.66,.14,.25,.04,.52,.06,.82,.06,.52,0,.96-.1,1.31-.29,.36-.19,.64-.44,.86-.76s.38-.68,.48-1.1c.1-.41,.16-.85,.16-1.31-.28,.18-.62,.34-1.02,.48-.4,.14-.86,.21-1.38,.21-.64,0-1.18-.07-1.62-.23s-.81-.4-1.09-.71-.49-.69-.62-1.14-.2-.96-.21-1.53c0-.6,.08-1.15,.25-1.66,.17-.5,.43-.94,.78-1.31s.78-.66,1.31-.87c.52-.21,1.13-.32,1.82-.32,.56,0,1.04,.05,1.46,.18,.42,.13,.78,.31,1.08,.54,.3,.24,.55,.53,.76,.87,.21,.34,.37,.72,.48,1.14,.12,.42,.2,.87,.25,1.36s.08,1,.08,1.53h.05Zm-2.42-.3c0-.66-.05-1.21-.13-1.66-.08-.44-.19-.8-.35-1.06-.15-.26-.34-.45-.56-.56s-.48-.16-.78-.16-.54,.06-.75,.16-.38,.25-.52,.44c-.14,.19-.24,.41-.3,.66s-.1,.53-.09,.83c0,.33,.04,.62,.1,.86,.06,.25,.16,.46,.29,.62s.29,.28,.5,.36c.2,.08,.45,.12,.74,.11,.36,0,.69-.06,1.01-.17s.6-.26,.84-.44h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
              </g>
              <g>
                <path d="M452.15,625.52c0,.6-.08,1.17-.26,1.68-.18,.52-.45,.96-.8,1.34-.35,.38-.79,.67-1.31,.89-.52,.22-1.13,.33-1.81,.34-.56,0-1.04-.05-1.46-.17-.41-.12-.77-.3-1.08-.54-.3-.23-.55-.52-.75-.86-.19-.34-.35-.72-.47-1.14-.12-.42-.2-.89-.25-1.39s-.08-1.03-.08-1.59c0-.47,.01-.96,.05-1.47,.04-.51,.12-1.02,.25-1.51s.31-.97,.55-1.42,.54-.84,.92-1.19c.38-.34,.83-.61,1.37-.82,.54-.2,1.18-.31,1.92-.32,.23,0,.47,0,.71,.03s.46,.06,.66,.1,.38,.09,.52,.14,.24,.1,.28,.14c.05,.04,.08,.08,.11,.13s.05,.1,.06,.16c.02,.06,.03,.13,.04,.22,0,.09,.01,.19,.01,.31v.48c0,.13-.02,.23-.05,.3-.02,.07-.06,.12-.1,.15s-.09,.05-.16,.05-.17-.02-.29-.06-.26-.08-.43-.13c-.17-.05-.36-.09-.59-.12s-.49-.05-.78-.05c-.5,0-.92,.1-1.26,.29s-.62,.44-.83,.76-.37,.69-.46,1.11-.14,.87-.14,1.33c.13-.09,.29-.18,.46-.26s.37-.16,.57-.23,.43-.12,.67-.16c.24-.04,.5-.07,.77-.07,.6,0,1.12,.07,1.56,.23,.43,.16,.79,.4,1.07,.7,.28,.31,.48,.68,.62,1.11,.13,.44,.2,.92,.21,1.46l-.02,.05Zm-2.39,.26c0-.31-.03-.59-.09-.83s-.15-.44-.28-.6c-.12-.16-.29-.28-.5-.36-.2-.08-.45-.12-.74-.12-.17,0-.34,.02-.51,.05-.17,.03-.33,.08-.49,.13-.16,.06-.31,.13-.46,.2-.15,.08-.29,.16-.41,.25,0,.66,.05,1.21,.13,1.65,.08,.44,.19,.79,.34,1.05s.33,.44,.55,.55,.47,.16,.76,.15c.28,0,.53-.06,.74-.17s.39-.26,.53-.45c.14-.19,.25-.42,.32-.67,.07-.26,.11-.54,.1-.84h0Z" fill="#ffc900"/>
                <path d="M461.57,622.89c0,.49-.01,.99-.06,1.51-.04,.52-.13,1.03-.27,1.53-.13,.5-.32,.98-.56,1.43s-.55,.85-.93,1.19-.85,.61-1.4,.82c-.55,.2-1.2,.31-1.96,.32-.26,0-.53-.01-.79-.05-.26-.03-.5-.08-.72-.13s-.4-.11-.55-.17-.26-.12-.33-.19c-.07-.06-.12-.16-.15-.28-.03-.12-.05-.3-.05-.52v-.49c0-.12,.03-.22,.05-.29,.03-.07,.06-.11,.1-.14,.04-.02,.09-.03,.14-.03,.07,0,.18,.02,.31,.06,.14,.04,.3,.09,.5,.14,.19,.05,.42,.1,.66,.14,.25,.04,.52,.06,.82,.06,.52,0,.96-.1,1.31-.29,.36-.19,.64-.44,.86-.76s.38-.68,.48-1.1c.1-.41,.16-.85,.16-1.31-.28,.18-.62,.34-1.02,.48-.4,.14-.86,.21-1.38,.21-.64,0-1.18-.07-1.62-.23s-.81-.4-1.09-.71-.49-.69-.62-1.14-.2-.96-.21-1.53c0-.6,.08-1.15,.25-1.66,.17-.5,.43-.94,.78-1.31s.78-.66,1.31-.87c.52-.21,1.13-.32,1.82-.32,.56,0,1.04,.05,1.46,.18,.42,.13,.78,.31,1.08,.54,.3,.24,.55,.53,.76,.87,.21,.34,.37,.72,.48,1.14,.12,.42,.2,.87,.25,1.36s.08,1,.08,1.53h.05Zm-2.42-.3c0-.66-.05-1.21-.13-1.66-.08-.44-.19-.8-.35-1.06-.15-.26-.34-.45-.56-.56s-.48-.16-.78-.16-.54,.06-.75,.16-.38,.25-.52,.44c-.14,.19-.24,.41-.3,.66s-.1,.53-.09,.83c0,.33,.04,.62,.1,.86,.06,.25,.16,.46,.29,.62s.29,.28,.5,.36c.2,.08,.45,.12,.74,.11,.36,0,.69-.06,1.01-.17s.6-.26,.84-.44h0Z" fill="#ffc900"/>
              </g>
            </g>
          </g>
          <g>
            <rect x="387.22" y="760.89" width="340.57" height="37.02" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g clip-path="url(#clippath-14)">
                <g>
                  <polygon points="637.22 797.91 652.67 797.91 689.69 760.89 674.24 760.89 637.22 797.91" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <polygon points="668.11 797.91 683.56 797.91 720.58 760.89 705.13 760.89 668.11 797.91" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <polygon points="714.45 797.91 727.79 784.56 727.79 769.12 699 797.91 714.45 797.91" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
              </g>
              <rect x="387.22" y="760.89" width="340.57" height="37.02" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
          </g>
          <line x1="397.19" y1="744.79" x2="552.77" y2="744.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <rect x="424.9" y="741.51" width="9.39" height="6.47" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="513.52" y="741.51" width="9.39" height="6.47" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <rect x="564.02" y="710.73" width="156.23" height="50.16" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="654.73 710.73 654.73 739.78 625.98 739.78 625.98 710.73" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polygon points="720.25 555.98 564.02 555.98 564.02 712.35 625.98 712.35 625.98 719.89 654.73 719.89 654.73 712.35 720.25 712.35 720.25 555.98" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <rect x="578.75" y="668.87" width="64.95" height="8.75" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <rect x="578.75" y="685.1" width="41.03" height="8.75" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
        </g>
      </g>
    ),
    key: 8,
  }
]

const animations = [
  <g id="_01">
    <circle cx="944.57" cy="714.25" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="944.57" cy="714.25" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_02">
    <circle cx="1307.65" cy="718.25" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="1307.65" cy="718.25" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_03">
    <circle cx="1649.7" cy="482.57" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="1649.7" cy="482.57" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_04">
    <circle cx="1442.07" cy="398.9" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="1442.07" cy="398.9" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_05">
    <circle cx="1736.98" cy="271.3" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="1736.98" cy="271.3" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_06">
    <circle cx="336.92" cy="907.48" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="336.92" cy="907.48" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_07">
    <circle cx="270.73" cy="251.97" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="270.73" cy="251.97" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_08">
    <circle cx="550.53" cy="659.88" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
    <circle cx="550.53" cy="659.88" r="50" fill="#ffc900" opacity=".2">
      {animateCircleL()}
      {animateOpacity()}
    </circle>
  </g>,
  <g id="_09">
    <circle cx="1853.23" cy="396.31" r="180" fill="#ffc900" opacity=".2">
      {animateCircleM('0.7s')}
      {animateOpacity('0.7s')}
    </circle>
    <circle cx="1853.23" cy="396.31" r="50" fill="#ffc900" opacity=".2">
      {animateCircleM()}
      {animateOpacity()}
    </circle>
  </g>,
]

const questions = range(9)

const BackgroundSVG = ({ finish, setOpen, ...props }) => {
  const ref = useRef()
  const randomCatch = useMemo(() =>  {
    const remaining = questions.filter(d => !finish?.[d] || !(finish?.[d].includes('finish')))
    return sampleSize(remaining, 3)
  }, [finish])
  // console.log(ref?.current)
  return (
    <StyledSVG viewBox="0 0 1920 1010" id="bg" ref={ref} {...props}>
       <g id="_圖層_3">
        <g>
          <g id="bg">
            <rect width="1920" height="1010" fill="#fff"/>
            <rect y="822.61" width="1920" height="187.39" fill="#bbe4ec"/>
            <g>
              <g>
                <line x1="1886.75" y1="842.84" x2="1894.34" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1910.47" y1="896.95" x2="1918.05" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1906.68" y1="967.82" x2="1914.26" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1875.18" y1="933.32" x2="1882.77" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1886.75" y1="1001.16" x2="1894.34" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1906.68" y1="967.82" x2="1914.26" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1726.35" y1="842.84" x2="1733.94" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1831.25" y1="920.23" x2="1838.83" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1795.89" y1="936.69" x2="1803.47" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1798.96" y1="826.4" x2="1806.54" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1750.07" y1="896.95" x2="1757.65" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1746.28" y1="967.82" x2="1753.86" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1838.79" y1="886.2" x2="1846.37" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1838.79" y1="846.63" x2="1846.37" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1795.89" y1="889.53" x2="1803.47" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1831.25" y1="961.21" x2="1838.83" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1763.78" y1="863.95" x2="1771.36" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1714.78" y1="933.32" x2="1722.37" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="1726.35" y1="1001.16" x2="1733.94" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1798.96" y1="984.71" x2="1806.54" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1838.79" y1="1004.95" x2="1846.37" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="1746.28" y1="967.82" x2="1753.86" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1565.95" y1="842.84" x2="1573.53" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1670.85" y1="920.23" x2="1678.43" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1635.49" y1="936.69" x2="1643.07" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1638.56" y1="826.4" x2="1646.14" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1589.67" y1="896.95" x2="1597.25" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1585.87" y1="967.82" x2="1593.46" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1678.39" y1="886.2" x2="1685.97" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1678.39" y1="846.63" x2="1685.97" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1635.49" y1="889.53" x2="1643.07" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1670.85" y1="961.21" x2="1678.43" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1603.38" y1="863.95" x2="1610.96" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1554.38" y1="933.32" x2="1561.96" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="1565.95" y1="1001.16" x2="1573.53" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1638.56" y1="984.71" x2="1646.14" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1678.39" y1="1004.95" x2="1685.97" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="1585.87" y1="967.82" x2="1593.46" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1405.55" y1="842.84" x2="1413.13" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1510.44" y1="920.23" x2="1518.03" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1475.08" y1="936.69" x2="1482.67" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1478.15" y1="826.4" x2="1485.74" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1429.26" y1="896.95" x2="1436.85" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1425.47" y1="967.82" x2="1433.06" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1517.98" y1="886.2" x2="1525.57" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1517.98" y1="846.63" x2="1525.57" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1475.08" y1="889.53" x2="1482.67" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1510.44" y1="961.21" x2="1518.03" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1442.97" y1="863.95" x2="1450.56" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1393.98" y1="933.32" x2="1401.56" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="1405.55" y1="1001.16" x2="1413.13" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1478.15" y1="984.71" x2="1485.74" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1517.98" y1="1004.95" x2="1525.57" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="1425.47" y1="967.82" x2="1433.06" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1245.15" y1="842.84" x2="1252.73" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1350.04" y1="920.23" x2="1357.63" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1314.68" y1="936.69" x2="1322.27" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1317.75" y1="826.4" x2="1325.33" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1268.86" y1="896.95" x2="1276.45" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1265.07" y1="967.82" x2="1272.65" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1357.58" y1="886.2" x2="1365.17" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1357.58" y1="846.63" x2="1365.17" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1314.68" y1="889.53" x2="1322.27" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1350.04" y1="961.21" x2="1357.63" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1282.57" y1="863.95" x2="1290.15" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1233.58" y1="933.32" x2="1241.16" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="1245.15" y1="1001.16" x2="1252.73" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1317.75" y1="984.71" x2="1325.33" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1357.58" y1="1004.95" x2="1365.17" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="1265.07" y1="967.82" x2="1272.65" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="1084.74" y1="842.84" x2="1092.33" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1189.64" y1="920.23" x2="1197.22" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1154.28" y1="936.69" x2="1161.86" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1157.35" y1="826.4" x2="1164.93" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1108.46" y1="896.95" x2="1116.04" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1104.67" y1="967.82" x2="1112.25" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1197.18" y1="886.2" x2="1204.76" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1197.18" y1="846.63" x2="1204.76" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1154.28" y1="889.53" x2="1161.86" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1189.64" y1="961.21" x2="1197.22" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1122.17" y1="863.95" x2="1129.75" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1073.17" y1="933.32" x2="1080.76" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="1084.74" y1="1001.16" x2="1092.33" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1157.35" y1="984.71" x2="1164.93" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1197.18" y1="1004.95" x2="1204.76" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="1104.67" y1="967.82" x2="1112.25" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="924.34" y1="842.84" x2="931.93" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1029.24" y1="920.23" x2="1036.82" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="993.88" y1="936.69" x2="1001.46" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="996.95" y1="826.4" x2="1004.53" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="948.06" y1="896.95" x2="955.64" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="944.27" y1="967.82" x2="951.85" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1036.78" y1="886.2" x2="1044.36" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1036.78" y1="846.63" x2="1044.36" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="993.88" y1="889.53" x2="1001.46" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="1029.24" y1="961.21" x2="1036.82" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="961.77" y1="863.95" x2="969.35" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="912.77" y1="933.32" x2="920.36" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="924.34" y1="1001.16" x2="931.93" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="996.95" y1="984.71" x2="1004.53" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="1036.78" y1="1004.95" x2="1044.36" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="944.27" y1="967.82" x2="951.85" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="763.94" y1="842.84" x2="771.52" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="868.84" y1="920.23" x2="876.42" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="833.48" y1="936.69" x2="841.06" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="836.54" y1="826.4" x2="844.13" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="787.65" y1="896.95" x2="795.24" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="783.86" y1="967.82" x2="791.45" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="876.38" y1="886.2" x2="883.96" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="876.38" y1="846.63" x2="883.96" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="833.48" y1="889.53" x2="841.06" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="868.84" y1="961.21" x2="876.42" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="801.36" y1="863.95" x2="808.95" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="752.37" y1="933.32" x2="759.95" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="763.94" y1="1001.16" x2="771.52" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="836.54" y1="984.71" x2="844.13" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="876.38" y1="1004.95" x2="883.96" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="783.86" y1="967.82" x2="791.45" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="603.54" y1="842.84" x2="611.12" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="708.43" y1="920.23" x2="716.02" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="673.07" y1="936.69" x2="680.66" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="676.14" y1="826.4" x2="683.73" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="627.25" y1="896.95" x2="634.84" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="623.46" y1="967.82" x2="631.04" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="715.97" y1="886.2" x2="723.56" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="715.97" y1="846.63" x2="723.56" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="673.07" y1="889.53" x2="680.66" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="708.43" y1="961.21" x2="716.02" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="640.96" y1="863.95" x2="648.55" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="591.97" y1="933.32" x2="599.55" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="603.54" y1="1001.16" x2="611.12" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="676.14" y1="984.71" x2="683.73" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="715.97" y1="1004.95" x2="723.56" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="623.46" y1="967.82" x2="631.04" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="443.13" y1="842.84" x2="450.72" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="548.03" y1="920.23" x2="555.62" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="512.67" y1="936.69" x2="520.25" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="515.74" y1="826.4" x2="523.32" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="466.85" y1="896.95" x2="474.43" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="463.06" y1="967.82" x2="470.64" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="555.57" y1="886.2" x2="563.15" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="555.57" y1="846.63" x2="563.15" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="512.67" y1="889.53" x2="520.25" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="548.03" y1="961.21" x2="555.62" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="480.56" y1="863.95" x2="488.14" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="431.56" y1="933.32" x2="439.15" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="443.13" y1="1001.16" x2="450.72" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="515.74" y1="984.71" x2="523.32" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="555.57" y1="1004.95" x2="563.15" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="463.06" y1="967.82" x2="470.64" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="282.73" y1="842.84" x2="290.32" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="387.63" y1="920.23" x2="395.21" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="352.27" y1="936.69" x2="359.85" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="355.34" y1="826.4" x2="362.92" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="306.45" y1="896.95" x2="314.03" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="302.66" y1="967.82" x2="310.24" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="395.17" y1="886.2" x2="402.75" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="395.17" y1="846.63" x2="402.75" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="352.27" y1="889.53" x2="359.85" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="387.63" y1="961.21" x2="395.21" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="320.16" y1="863.95" x2="327.74" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="271.16" y1="933.32" x2="278.75" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="282.73" y1="1001.16" x2="290.32" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="355.34" y1="984.71" x2="362.92" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="395.17" y1="1004.95" x2="402.75" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="302.66" y1="967.82" x2="310.24" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="122.33" y1="842.84" x2="129.91" y2="850.43" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="227.23" y1="920.23" x2="234.81" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="191.87" y1="936.69" x2="199.45" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="194.94" y1="826.4" x2="202.52" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="146.05" y1="896.95" x2="153.63" y2="904.54" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="142.25" y1="967.82" x2="149.84" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="234.77" y1="886.2" x2="242.35" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="234.77" y1="846.63" x2="242.35" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="191.87" y1="889.53" x2="199.45" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="227.23" y1="961.21" x2="234.81" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="159.75" y1="863.95" x2="167.34" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="110.76" y1="933.32" x2="118.34" y2="925.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <g>
                  <line x1="122.33" y1="1001.16" x2="129.91" y2="1008.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="194.94" y1="984.71" x2="202.52" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line x1="234.77" y1="1004.95" x2="242.35" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
                <line x1="142.25" y1="967.82" x2="149.84" y2="975.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="66.82" y1="920.23" x2="74.41" y2="927.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="31.46" y1="936.69" x2="39.05" y2="944.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="34.53" y1="826.4" x2="42.12" y2="833.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="74.36" y1="886.2" x2="81.95" y2="893.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="74.36" y1="846.63" x2="81.95" y2="839.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="31.46" y1="889.53" x2="39.05" y2="881.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="66.82" y1="961.21" x2="74.41" y2="953.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="-.65" y1="863.95" x2="6.94" y2="856.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g>
                <line x1="34.53" y1="984.71" x2="42.12" y2="992.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <line x1="74.36" y1="1004.95" x2="81.95" y2="997.37" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </g>
            <line x1="127.39" y1="506.09" x2="127.39" y2="515.05" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="397.02" y1="380.82" x2="397.02" y2="386.69" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="412.02" y1="380.82" x2="412.02" y2="386.69" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <line x1="823.26" y1="462.1" x2="808.26" y2="462.1" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="1032.02 443.38 1009.01 443.38 1009.01 396.84 974.82 362.65 942.84 394.62 942.84 462.1 851.28 462.1" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="391.52 517.54 406.64 517.54 406.64 472.65 444.97 472.65 486.41 431.21 526.34 471.14 526.34 493.81 612.21 493.81" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="153.15 492.79 153.15 467.86 265.8 467.86 265.8 517.59 343.36 517.59" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="-47.43 520.19 28.94 520.19 28.94 410.19 127.39 410.19 127.39 492.79" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="96.15" y1="426.18" x2="96.15" y2="432.06" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="111.14" y1="426.18" x2="111.14" y2="432.06" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="96.15" y1="442.85" x2="96.15" y2="448.73" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="111.14" y1="442.85" x2="111.14" y2="448.73" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="422.26" y1="487.32" x2="422.26" y2="493.2" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="437.25" y1="487.32" x2="437.25" y2="493.2" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="422.26" y1="504" x2="422.26" y2="509.87" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="437.25" y1="504" x2="437.25" y2="509.87" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="397.02" y1="397.49" x2="397.02" y2="403.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="412.02" y1="397.49" x2="412.02" y2="403.36" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <line x1="183.99" y1="478.33" x2="183.99" y2="484.21" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="198.98" y1="478.33" x2="198.98" y2="484.21" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="169" y1="478.33" x2="169" y2="484.21" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="183.99" y1="496.22" x2="183.99" y2="502.09" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="198.98" y1="496.22" x2="198.98" y2="502.09" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="169" y1="496.22" x2="169" y2="502.09" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="698.34" y1="469.39" x2="698.34" y2="475.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="713.33" y1="469.39" x2="713.33" y2="475.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="683.35" y1="469.39" x2="683.35" y2="475.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="1076.25" y1="417.24" x2="1076.25" y2="423.11" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1091.24" y1="417.24" x2="1091.24" y2="423.11" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1061.26" y1="417.24" x2="1061.26" y2="423.11" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="1076.25" y1="435.12" x2="1076.25" y2="441" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1091.24" y1="435.12" x2="1091.24" y2="441" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1061.26" y1="435.12" x2="1061.26" y2="441" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="1249.2" y1="437.5" x2="1249.2" y2="443.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1264.19" y1="437.5" x2="1264.19" y2="443.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1234.21" y1="437.5" x2="1234.21" y2="443.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <line x1="1249.2" y1="455.39" x2="1249.2" y2="461.26" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1264.19" y1="455.39" x2="1264.19" y2="461.26" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1234.21" y1="455.39" x2="1234.21" y2="461.26" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <line x1="1142.13" y1="465.96" x2="1142.13" y2="478.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="996.72 496.15 1046.99 496.15 1046.99 404.58 1096.87 404.58 1096.87 362.09 1142.13 362.09 1142.13 450.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="1239.9 424.72 1274.13 424.72 1274.13 465.96 1288.48 465.96" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="1306.06" y1="465.96" x2="1330.12" y2="465.96" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="1155.61" y1="478.77" x2="1208.84" y2="478.77" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="153.15" y1="502.09" x2="153.15" y2="526.32" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="376.96 417.24 376.96 431.93 335.97 431.93 335.97 456.33" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="376.96 401.07 376.96 367.28 422.57 367.28 422.57 412.3 447.07 412.3 447.07 436.78" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="335.97" y1="463.99" x2="335.97" y2="473.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <polyline points="631.35 513.21 631.35 456.32 731.17 456.32 731.17 493.93 761.45 493.93 761.45 524.73" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <line x1="631.35" y1="524.73" x2="631.35" y2="535.97" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M48.86,728.32c3.45-5.58,5.45-12.15,5.45-19.19,0-18.69-14.05-34.09-32.17-36.23-3.76-13.08-15.8-22.65-30.08-22.65-7.71,0-14.76,2.79-20.22,7.41-5.67-6.73-14.16-11.02-23.66-11.02-11.82,0-22.08,6.63-27.29,16.37-1.76-.31-3.57-.48-5.42-.48-17.08,0-30.92,13.84-30.92,30.92,0,1.43,.11,2.84,.29,4.22-8.76,2.76-15.1,10.94-15.1,20.61,0,4.48,1.36,8.64,3.7,12.1-4.35,3.95-7.1,9.64-7.1,15.98,0,10.98,8.2,20.03,18.81,21.41,0,.28-.02,.56-.02,.84,0,13.46,10.91,24.37,24.37,24.37H11.12c11.95,0,21.88-8.6,23.96-19.95,.14,0,.27,.01,.41,.01,13.46,0,24.37-10.91,24.37-24.37,0-8.52-4.38-16.02-11-20.37v.02Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M281.82,728.32c3.45-5.58,5.45-12.15,5.45-19.19,0-18.69-14.05-34.09-32.16-36.23-3.76-13.08-15.8-22.65-30.08-22.65-7.71,0-14.76,2.79-20.22,7.41-5.67-6.73-14.16-11.02-23.66-11.02-11.82,0-22.08,6.63-27.29,16.37-1.76-.31-3.57-.48-5.42-.48-17.08,0-30.92,13.84-30.92,30.92,0,1.43,.11,2.84,.29,4.22-8.76,2.76-15.11,10.94-15.11,20.61,0,4.48,1.36,8.64,3.7,12.1-4.35,3.95-7.1,9.64-7.1,15.98,0,10.98,8.2,20.03,18.81,21.41,0,.28-.02,.56-.02,.84,0,13.46,10.91,24.37,24.37,24.37h101.61c11.95,0,21.88-8.6,23.96-19.95,.14,0,.27,.01,.41,.01,13.46,0,24.37-10.91,24.37-24.37,0-8.52-4.38-16.02-11-20.37v.02Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M747.74,728.85c3.45-5.58,5.45-12.15,5.45-19.19,0-18.69-14.05-34.09-32.16-36.23-3.76-13.08-15.8-22.65-30.08-22.65-7.71,0-14.76,2.79-20.22,7.41-5.67-6.73-14.16-11.02-23.66-11.02-11.82,0-22.08,6.63-27.29,16.37-1.76-.31-3.57-.48-5.42-.48-17.08,0-30.92,13.84-30.92,30.92,0,1.43,.11,2.84,.29,4.22-8.76,2.76-15.11,10.94-15.11,20.61,0,4.48,1.36,8.64,3.7,12.1-4.35,3.95-7.1,9.64-7.1,15.98,0,10.98,8.2,20.03,18.81,21.41,0,.28-.02,.56-.02,.84,0,13.46,10.91,24.37,24.37,24.37h101.61c11.95,0,21.88-8.6,23.96-19.95,.14,0,.27,.01,.41,.01,13.46,0,24.37-10.91,24.37-24.37,0-8.52-4.38-16.02-11-20.37v.02Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M514.78,728.32c3.45-5.58,5.45-12.15,5.45-19.19,0-18.69-14.05-34.09-32.16-36.23-3.76-13.08-15.8-22.65-30.08-22.65-7.71,0-14.76,2.79-20.22,7.41-5.67-6.73-14.16-11.02-23.66-11.02-11.82,0-22.08,6.63-27.29,16.37-1.76-.31-3.57-.48-5.42-.48-17.08,0-30.92,13.84-30.92,30.92,0,1.43,.11,2.84,.29,4.22-8.76,2.76-15.11,10.94-15.11,20.61,0,4.48,1.36,8.64,3.7,12.1-4.35,3.95-7.1,9.64-7.1,15.98,0,10.98,8.2,20.03,18.81,21.41,0,.28-.02,.56-.02,.84,0,13.46,10.91,24.37,24.37,24.37h101.61c11.95,0,21.88-8.6,23.96-19.95,.14,0,.27,.01,.41,.01,13.46,0,24.37-10.91,24.37-24.37,0-8.52-4.38-16.02-11-20.37v.02Z" fill="#b6db9e" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <rect x="801.28" y="604.4" width="293.22" height="193.44" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1046.07" y1="604.4" x2="1046.07" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="995.36" y1="604.4" x2="995.36" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="944.65" y1="541.74" x2="944.65" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="893.94" y1="604.4" x2="893.94" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="843.23" y1="604.4" x2="843.23" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="801.28" y="541.74" width="293.22" height="62.65" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="801.28" y1="573.07" x2="1094.49" y2="573.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <rect x="1094.49" y="604.4" width="293.22" height="193.44" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1339.28" y1="604.4" x2="1339.28" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1288.58" y1="604.4" x2="1288.58" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1237.87" y1="541.74" x2="1237.87" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1187.16" y1="604.4" x2="1187.16" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1136.45" y1="604.4" x2="1136.45" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="1094.49" y="541.74" width="293.22" height="62.65" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1094.49" y1="573.07" x2="1387.71" y2="573.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <rect x="1387.71" y="604.4" width="293.22" height="193.44" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1632.5" y1="604.4" x2="1632.5" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1581.79" y1="604.4" x2="1581.79" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1531.08" y1="541.74" x2="1531.08" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1480.38" y1="604.4" x2="1480.38" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1429.67" y1="604.4" x2="1429.67" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="1387.71" y="541.74" width="293.22" height="62.65" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1387.71" y1="573.07" x2="1680.93" y2="573.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <g>
                <rect x="1680.93" y="604.4" width="293.22" height="193.44" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1875.01" y1="604.4" x2="1875.01" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1824.3" y1="541.74" x2="1824.3" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1773.59" y1="604.4" x2="1773.59" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1722.89" y1="604.4" x2="1722.89" y2="797.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="1680.93" y="541.74" width="293.22" height="62.65" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1680.93" y1="573.07" x2="1974.15" y2="573.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <line x1="802.35" y1="541.74" x2="1974.15" y2="541.74" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
              <line x1="802.35" y1="604.4" x2="1974.15" y2="604.4" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
              <line x1="802.35" y1="573.07" x2="1974.15" y2="573.07" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.46"/>
              <line x1="801.28" y1="557.41" x2="1974.15" y2="557.41" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <line x1="801.28" y1="588.73" x2="1974.15" y2="588.73" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <rect x="1204.8" y="109.04" width="34.25" height="688.8" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="1186.77" y="109.04" width="34.25" height="688.8" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <rect x="891.5" y="109.04" width="34.25" height="688.8" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="873.47" y="109.04" width="34.25" height="688.8" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <g>
                <g>
                  <rect x="1632.53" y="398.57" width="188.86" height="133.12" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <rect x="1632.53" y="398.57" width="188.86" height="18.53" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <path d="M1751.63,445.72c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22c6.59-6.59,10.22-15.35,10.22-24.68s-3.63-18.09-10.22-24.68h0Z" fill="#fff"/>
                  <polygon points="1749.28 472.17 1742.74 480.67 1742.74 476.79 1728.07 497.28 1739.7 484.54 1739.6 488.61 1745.96 479.92 1746.14 482.05 1753.91 471.34 1748.27 475.96 1749.28 472.17" fill="#e50012"/>
                  <polygon points="1742.74 464.24 1742.56 460.36 1749.2 468.58 1748.37 464.79 1753.91 469.5 1745.87 458.42 1745.87 460.83 1739.42 452.15 1739.6 456.02 1727.79 443.57 1742.74 464.24" fill="#e50012"/>
                  <polygon points="1704.98 468.95 1711.45 460.73 1711.45 464.42 1726.11 443.65 1714.31 456.67 1714.58 452.33 1708.03 461.19 1708.03 458.88 1700.28 469.86 1705.73 465.16 1704.98 468.95" fill="#e50012"/>
                  <polygon points="1711.45 476.79 1711.63 480.49 1704.98 472.27 1706 476.23 1700.28 471.25 1708.21 482.33 1708.21 479.92 1714.76 488.7 1714.58 484.63 1726.3 497.28 1711.45 476.79" fill="#e50012"/>
                  <g>
                    <path d="M1751.63,445.72c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22c6.59-6.59,10.22-15.35,10.22-24.68s-3.63-18.09-10.22-24.68h0Zm-24.68,57.87c-18.31,0-33.19-14.89-33.19-33.19s14.88-33.19,33.19-33.19,33.19,14.88,33.19,33.19-14.88,33.19-33.19,33.19Z" fill="#0069ab"/>
                    <path d="M1726.86,438.42c-17.59,0-31.9,14.31-31.9,31.9s14.31,31.9,31.9,31.9,31.91-14.31,31.91-31.9-14.3-31.9-31.91-31.9h0Zm0,62.24c-16.73,0-30.34-13.61-30.34-30.34s13.61-30.34,30.34-30.34,30.35,13.62,30.35,30.34-13.61,30.34-30.35,30.34Z" fill="#0069ab"/>
                    <path d="M1726.86,441.33c-15.98,0-28.98,13.01-28.98,28.99s13.01,29,28.98,29,29-13.01,29-29-13.01-28.99-29-28.99h0Zm0,56.56c-15.2,0-27.57-12.37-27.57-27.57s12.37-27.57,27.57-27.57,27.58,12.37,27.58,27.57-12.37,27.57-27.58,27.57Z" fill="#0069ab"/>
                    <path d="M1727.69,468.78h10.15l-.72-1.83h-20.19l-.82,1.83h9.74v1.49h-10.26l2.97,6.9h2.03l-2.12-5.02h1.79l2.24,5.02,1.97-.02-2.13-5.05,3.51-.02v6.51h-6.81l3.68,8.29h3.17v7.23l1.37,2.98,3.6-8.67h-2.02l-1.06,2.79v-4.33h3.7l3.39-8.29-7.19-.03v-6.48l4,.02-2.22,5.05h2.07l2.12-5.08h2.12l-2.12,5.04,1.83,.03,2.91-6.87-10.7-.02v-1.47h0Zm-5.02,13.04l-.68-1.45h3.84v1.45h-3.17Zm3.17,3.34l-1.72-.02-.72-1.54,2.46,.02-.03,1.54h0Zm4.43,0h-2.57v-1.54s3.18,0,3.18,0l-.6,1.54Zm1.99-4.72l-.65,1.42h-3.92v-1.42s4.57,0,4.57,0Z" fill="#0069ab"/>
                    <path d="M1732.59,456.97h-11.29l-3.4,7.81h18.18l-3.5-7.81h0Zm-10.05,1.8h8.9l1.8,4.12h-12.44l1.74-4.12h0Z" fill="#0069ab"/>
                    <polygon points="1728.98 448.59 1727.96 450.83 1729.2 453.58 1724.78 453.58 1728.05 446.43 1727.02 443.97 1722.04 455.39 1731.94 455.39 1728.98 448.59" fill="#0069ab"/>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <rect x="1632.53" y="531.68" width="188.86" height="133.12" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <rect x="1632.53" y="531.68" width="188.86" height="18.53" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <path d="M1751.63,578.84c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22,10.22-15.35,10.22-24.68-3.63-18.09-10.22-24.68Z" fill="#fff"/>
                  <polygon points="1749.28 605.29 1742.74 613.77 1742.74 609.9 1728.07 630.39 1739.7 617.65 1739.6 621.72 1745.96 613.04 1746.14 615.17 1753.91 604.46 1748.27 609.07 1749.28 605.29" fill="#e50012"/>
                  <polygon points="1742.74 597.35 1742.56 593.47 1749.2 601.69 1748.37 597.91 1753.91 602.61 1745.87 591.54 1745.87 593.93 1739.42 585.26 1739.6 589.14 1727.79 576.67 1742.74 597.35" fill="#e50012"/>
                  <polygon points="1704.98 602.06 1711.45 593.85 1711.45 597.54 1726.11 576.77 1714.31 589.78 1714.58 585.45 1708.03 594.3 1708.03 592 1700.28 602.98 1705.73 598.28 1704.98 602.06" fill="#e50012"/>
                  <polygon points="1711.45 609.9 1711.63 613.59 1704.98 605.37 1706 609.35 1700.28 604.36 1708.21 615.43 1708.21 613.04 1714.76 621.81 1714.58 617.75 1726.3 630.39 1711.45 609.9" fill="#e50012"/>
                  <g>
                    <path d="M1751.63,578.84c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22,10.22-15.35,10.22-24.68-3.63-18.09-10.22-24.68Zm-24.68,57.87c-18.31,0-33.19-14.89-33.19-33.19s14.88-33.19,33.19-33.19,33.19,14.88,33.19,33.19-14.88,33.19-33.19,33.19Z" fill="#0069ab"/>
                    <path d="M1726.86,571.52c-17.59,0-31.9,14.31-31.9,31.9s14.31,31.9,31.9,31.9,31.91-14.31,31.91-31.9-14.3-31.9-31.91-31.9Zm0,62.24c-16.73,0-30.34-13.61-30.34-30.34s13.61-30.34,30.34-30.34,30.35,13.62,30.35,30.34-13.61,30.34-30.35,30.34Z" fill="#0069ab"/>
                    <path d="M1726.86,574.44c-15.98,0-28.98,13.01-28.98,28.99s13.01,29,28.98,29,29-13.01,29-29-13.01-28.99-29-28.99Zm0,56.56c-15.2,0-27.57-12.37-27.57-27.57s12.37-27.57,27.57-27.57,27.58,12.37,27.58,27.57-12.37,27.57-27.58,27.57Z" fill="#0069ab"/>
                    <path d="M1727.69,601.89h10.15l-.72-1.83h-20.19l-.82,1.83h9.74v1.49h-10.26l2.97,6.9h2.03l-2.12-5.02h1.79l2.24,5.02,1.97-.02-2.13-5.05,3.51-.02v6.51h-6.81l3.68,8.29h3.17v7.23l1.37,2.98,3.6-8.67h-2.02l-1.06,2.79v-4.33h3.7l3.39-8.29-7.19-.03v-6.48l4,.02-2.22,5.05h2.07l2.12-5.08h2.12l-2.12,5.04,1.83,.03,2.91-6.87-10.7-.02v-1.47h0Zm-5.02,13.04l-.68-1.45h3.84v1.45h-3.17Zm3.17,3.34l-1.72-.02-.72-1.54,2.46,.02-.03,1.54h0Zm4.43,0h-2.57v-1.54s3.18,0,3.18,0l-.6,1.54Zm1.99-4.72l-.65,1.42h-3.92v-1.42s4.57,0,4.57,0Z" fill="#0069ab"/>
                    <path d="M1732.59,590.09h-11.29l-3.4,7.81h18.18l-3.5-7.81h0Zm-10.05,1.8h8.9l1.8,4.12h-12.44l1.74-4.12h0Z" fill="#0069ab"/>
                    <polygon points="1728.98 581.71 1727.96 583.95 1729.2 586.69 1724.78 586.69 1728.05 579.53 1727.02 577.07 1722.04 588.5 1731.94 588.5 1728.98 581.71" fill="#0069ab"/>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <rect x="1632.53" y="664.79" width="188.86" height="133.12" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <rect x="1632.53" y="664.79" width="188.86" height="18.53" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <path d="M1751.63,711.94c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22c6.59-6.59,10.22-15.35,10.22-24.68s-3.63-18.09-10.22-24.68Z" fill="#fff"/>
                  <polygon points="1749.28 738.39 1742.74 746.89 1742.74 743.02 1728.07 763.51 1739.7 750.77 1739.6 754.83 1745.96 746.15 1746.14 748.27 1753.91 737.56 1748.27 742.18 1749.28 738.39" fill="#e50012"/>
                  <polygon points="1742.74 730.46 1742.56 726.59 1749.2 734.8 1748.37 731.01 1753.91 735.72 1745.87 724.64 1745.87 727.05 1739.42 718.37 1739.6 722.24 1727.79 709.79 1742.74 730.46" fill="#e50012"/>
                  <polygon points="1704.98 735.17 1711.45 726.95 1711.45 730.64 1726.11 709.88 1714.31 722.89 1714.58 718.56 1708.03 727.42 1708.03 725.1 1700.28 736.1 1705.73 731.39 1704.98 735.17" fill="#e50012"/>
                  <polygon points="1711.45 743.02 1711.63 746.71 1704.98 738.49 1706 742.47 1700.28 737.48 1708.21 748.55 1708.21 746.15 1714.76 754.92 1714.58 750.87 1726.3 763.51 1711.45 743.02" fill="#e50012"/>
                  <g>
                    <path d="M1751.63,711.94c-6.59-6.59-15.35-10.22-24.68-10.22s-18.1,3.63-24.68,10.22c-6.59,6.59-10.22,15.35-10.22,24.68s3.63,18.08,10.22,24.68c6.59,6.59,15.35,10.22,24.68,10.22s18.09-3.63,24.68-10.22c6.59-6.59,10.22-15.35,10.22-24.68s-3.63-18.09-10.22-24.68Zm-24.68,57.87c-18.31,0-33.19-14.89-33.19-33.19s14.88-33.19,33.19-33.19,33.19,14.88,33.19,33.19-14.88,33.19-33.19,33.19Z" fill="#0069ab"/>
                    <path d="M1726.86,704.64c-17.59,0-31.9,14.31-31.9,31.9s14.31,31.9,31.9,31.9,31.91-14.31,31.91-31.9-14.3-31.9-31.91-31.9Zm0,62.24c-16.73,0-30.34-13.61-30.34-30.34s13.61-30.34,30.34-30.34,30.35,13.62,30.35,30.34-13.61,30.34-30.35,30.34Z" fill="#0069ab"/>
                    <path d="M1726.86,707.55c-15.98,0-28.98,13.01-28.98,28.99s13.01,29,28.98,29,29-13.01,29-29-13.01-28.99-29-28.99Zm0,56.56c-15.2,0-27.57-12.37-27.57-27.57s12.37-27.57,27.57-27.57,27.58,12.37,27.58,27.57-12.37,27.57-27.58,27.57Z" fill="#0069ab"/>
                    <path d="M1727.69,735h10.15l-.72-1.83h-20.19l-.82,1.83h9.74v1.49h-10.26l2.97,6.9h2.03l-2.12-5.02h1.79l2.24,5.02,1.97-.02-2.13-5.05,3.51-.02v6.51h-6.81l3.68,8.29h3.17v7.23l1.37,2.98,3.6-8.67h-2.02l-1.06,2.79v-4.33h3.7l3.39-8.29-7.19-.03v-6.48l4,.02-2.22,5.05h2.07l2.12-5.08h2.12l-2.12,5.04,1.83,.03,2.91-6.87-10.7-.02v-1.47h0Zm-5.02,13.04l-.68-1.45h3.84v1.45h-3.17Zm3.17,3.34l-1.72-.02-.72-1.54,2.46,.02-.03,1.54h0Zm4.43,0h-2.57v-1.54s3.18,0,3.18,0l-.6,1.54Zm1.99-4.72l-.65,1.42h-3.92v-1.42s4.57,0,4.57,0Z" fill="#0069ab"/>
                    <path d="M1732.59,723.2h-11.29l-3.4,7.81h18.18l-3.5-7.81h0Zm-10.05,1.8h8.9l1.8,4.12h-12.44l1.74-4.12h0Z" fill="#0069ab"/>
                    <polygon points="1728.98 714.81 1727.96 717.05 1729.2 719.8 1724.78 719.8 1728.05 712.65 1727.02 710.19 1722.04 721.61 1731.94 721.61 1728.98 714.81" fill="#0069ab"/>
                  </g>
                </g>
              </g>
            </g>
            <line x1="-47.12" y1="797.91" x2="1834" y2="797.91" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="-70.59" y1="821.38" x2="1950.82" y2="821.38" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <rect x="-128.51" y="780.74" width="177.08" height="17.16" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="104.45" y="780.74" width="177.08" height="17.16" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="570.37" y="781.28" width="177.08" height="17.16" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="337.41" y="780.74" width="177.08" height="17.16" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="792.94" y="780.74" width="1181.2" height="17.16" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            </g>
            <g>
              <rect x="1324.34" y="662.87" width="244.51" height="135.03" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="1324.34" y="662.77" width="244.51" height="21.46" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <rect x="1324.34" y="527.49" width="244.51" height="135.03" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1324.34" y1="548.94" x2="1568.86" y2="548.94" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <line x1="1324.34" y1="684.33" x2="1568.86" y2="684.33" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <rect x="1324.34" y="527.16" width="244.51" height="21.46" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <g>
                  <line x1="1479.68" y1="560.34" x2="1479.68" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1512.52" y1="560.34" x2="1512.52" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1545.36" y1="560.34" x2="1545.36" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1446.84" y1="560.34" x2="1446.84" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1414" y1="560.34" x2="1414" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1381.16" y1="560.34" x2="1381.16" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1348.32" y1="560.34" x2="1348.32" y2="650.55" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
                <g>
                  <line x1="1335.82" y1="574.93" x2="1555.29" y2="574.93" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1335.82" y1="605.44" x2="1555.29" y2="605.44" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1335.82" y1="635.95" x2="1555.29" y2="635.95" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
              </g>
              <g>
                <g>
                  <line x1="1479.68" y1="695.43" x2="1479.68" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1512.52" y1="695.43" x2="1512.52" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1545.36" y1="695.43" x2="1545.36" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1446.84" y1="695.43" x2="1446.84" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1414" y1="695.43" x2="1414" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1381.16" y1="695.43" x2="1381.16" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1348.32" y1="695.43" x2="1348.32" y2="785.64" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
                <g>
                  <line x1="1335.82" y1="710.02" x2="1555.29" y2="710.02" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1335.82" y1="740.53" x2="1555.29" y2="740.53" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  <line x1="1335.82" y1="771.04" x2="1555.29" y2="771.04" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <rect x="1756.27" y="506.23" width="194.55" height="145.84" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <rect x="1774.25" y="554.48" width="158.59" height="49.33" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <line x1="1756.27" y1="523.3" x2="1950.82" y2="523.3" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <g>
                    <path d="M1845.86,568.91c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64s-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64-.05-.58-.06-.96c0-.39,0-.72,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35,.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1,.1,.07,.18,.18,.25,.35,.07,.16,.12,.38,.15,.65s.05,.6,.06,.99h0Z" fill="#ffc900"/>
                    <path d="M1865.99,574.21c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.05,2.01-1.88,2.75-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.41-.25s-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05,.54,.04,1.18,.13,1.93,.27s1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.31,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.54,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
                    <path d="M1887.37,587.61c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62,.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.21-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
                  </g>
                  <g>
                    <path d="M1845.86,568.91c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64s-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64-.05-.58-.06-.96c0-.39,0-.72,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35,.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1,.1,.07,.18,.18,.25,.35,.07,.16,.12,.38,.15,.65s.05,.6,.06,.99h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path d="M1865.99,574.21c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.05,2.01-1.88,2.75-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.41-.25s-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05,.54,.04,1.18,.13,1.93,.27s1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.31,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.54,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path d="M1887.37,587.61c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62,.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.21-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                  <g>
                    <path d="M1845.86,568.91c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64s-.14,.28-.24,.36c-.09,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.42-.25-.08-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64-.05-.58-.06-.96c0-.39,0-.72,.04-.99,.03-.27,.07-.49,.14-.65,.06-.16,.14-.28,.23-.35,.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1,.1,.07,.18,.18,.25,.35,.07,.16,.12,.38,.15,.65s.05,.6,.06,.99h0Z" fill="#ffc900"/>
                    <path d="M1865.99,574.21c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.05,2.01-1.88,2.75-.83,.75-1.85,1.32-3.06,1.72-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9,0-1.23-.05c-.33-.04-.59-.08-.78-.15-.19-.06-.33-.14-.41-.25s-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05,.54,.04,1.18,.13,1.93,.27s1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.31,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24-.45-.27-.93-.43-1.43-.5s-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.54,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
                    <path d="M1887.37,587.61c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74-1.38-.51-2.56-1.29-3.54-2.33-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67,.55-1.66,1.33-3.05,2.33-4.18,1.01-1.13,2.22-1.99,3.64-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.49,.63,.44,.24,.75,.45,.92,.62,.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.78,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.04,1.53c.8,.33,1.69,.49,2.68,.48,.9,0,1.68-.12,2.33-.34,.65-.22,1.21-.46,1.68-.72,.47-.26,.85-.5,1.15-.71s.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <rect x="1756.27" y="652.07" width="194.55" height="145.84" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <rect x="1774.25" y="700.32" width="158.59" height="49.33" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <line x1="1756.27" y1="669.14" x2="1950.82" y2="669.14" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <g>
                    <path d="M1845.86,715.15c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64-.06,.16-.14,.28-.24,.36-.1,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.09-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.08-.49,.14-.65s.14-.28,.23-.35c.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.06,.6,.06,.99h0Z" fill="#ffc900"/>
                    <path d="M1865.99,720.44c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.06,2-1.89,2.75s-1.85,1.32-3.06,1.72c-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27,1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24s-.93-.43-1.43-.5-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
                    <path d="M1887.37,733.85c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74s-2.56-1.29-3.54-2.33c-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67s1.33-3.05,2.33-4.18c1.01-1.13,2.22-1.99,3.63-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.48,.63s.75,.45,.92,.62c.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.77,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.05,1.53,1.69,.49,2.68,.48c.9,0,1.68-.12,2.34-.34,.65-.22,1.21-.46,1.68-.72s.85-.5,1.15-.71,.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
                  </g>
                  <g>
                    <path d="M1845.86,715.15c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64-.06,.16-.14,.28-.24,.36-.1,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.09-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.08-.49,.14-.65s.14-.28,.23-.35c.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.06,.6,.06,.99h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path d="M1865.99,720.44c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.06,2-1.89,2.75s-1.85,1.32-3.06,1.72c-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27,1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24s-.93-.43-1.43-.5-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path d="M1887.37,733.85c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74s-2.56-1.29-3.54-2.33c-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67s1.33-3.05,2.33-4.18c1.01-1.13,2.22-1.99,3.63-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.48,.63s.75,.45,.92,.62c.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.77,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.05,1.53,1.69,.49,2.68,.48c.9,0,1.68-.12,2.34-.34,.65-.22,1.21-.46,1.68-.72s.85-.5,1.15-.71,.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  </g>
                  <g>
                    <path d="M1845.86,715.15c0,.37,0,.69-.04,.96-.03,.26-.08,.48-.14,.64-.06,.16-.14,.28-.24,.36-.1,.08-.2,.12-.32,.12l-6.26,.07,.21,20.16c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.78,.16-.33,.04-.74,.07-1.23,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.42-.25-.09-.1-.13-.22-.13-.35l-.21-20.16-6.26,.07c-.13,0-.24-.04-.33-.11-.09-.08-.17-.19-.24-.35s-.12-.37-.15-.64c-.04-.26-.05-.58-.06-.96,0-.39,0-.71,.04-.99,.03-.27,.08-.49,.14-.65s.14-.28,.23-.35c.09-.07,.2-.11,.33-.11l17.62-.18c.12,0,.22,.03,.32,.1s.18,.19,.25,.35,.12,.38,.15,.65,.06,.6,.06,.99h0Z" fill="#ffc900"/>
                    <path d="M1865.99,720.44c.01,1.4-.19,2.65-.62,3.74-.43,1.09-1.06,2-1.89,2.75s-1.85,1.32-3.06,1.72c-1.21,.4-2.64,.61-4.29,.62l-2.09,.02,.08,7.98c0,.13-.04,.25-.12,.35s-.22,.19-.41,.26c-.19,.07-.45,.12-.77,.16-.32,.04-.73,.07-1.24,.07s-.9-.01-1.23-.05-.59-.08-.78-.15c-.19-.06-.33-.14-.41-.25-.08-.1-.12-.22-.12-.35l-.24-22.49c0-.61,.15-1.06,.46-1.37,.31-.31,.73-.46,1.24-.47l5.89-.06c.59,0,1.16,.01,1.69,.05s1.18,.13,1.93,.27,1.51,.41,2.28,.8,1.43,.89,1.98,1.5c.55,.61,.97,1.32,1.26,2.13,.29,.82,.44,1.73,.45,2.75v.02Zm-5.3,.42c0-.88-.17-1.6-.49-2.16-.31-.56-.7-.98-1.15-1.24s-.93-.43-1.43-.5-1.01-.1-1.54-.09l-2.16,.02,.09,8.48,2.28-.02c.81,0,1.49-.12,2.04-.35,.54-.22,.99-.53,1.33-.93,.34-.4,.6-.87,.78-1.42,.17-.55,.26-1.15,.25-1.79h0Z" fill="#ffc900"/>
                    <path d="M1887.37,733.85c0,.32,0,.6-.02,.82-.02,.23-.04,.42-.08,.58-.04,.16-.09,.3-.15,.42s-.16,.25-.3,.4-.41,.34-.82,.58c-.4,.24-.9,.46-1.48,.68s-1.25,.4-2,.55c-.75,.15-1.57,.23-2.44,.24-1.71,.02-3.26-.23-4.65-.74s-2.56-1.29-3.54-2.33c-.98-1.04-1.73-2.34-2.26-3.91-.53-1.57-.81-3.39-.83-5.48-.02-2.13,.24-4.02,.79-5.67s1.33-3.05,2.33-4.18c1.01-1.13,2.22-1.99,3.63-2.59,1.42-.59,2.98-.9,4.7-.92,.7,0,1.37,.04,2.01,.15,.64,.11,1.24,.25,1.79,.44,.55,.18,1.04,.39,1.48,.63s.75,.45,.92,.62c.18,.17,.3,.32,.36,.43,.07,.12,.12,.26,.16,.44s.07,.39,.09,.64c.02,.24,.03,.55,.04,.91,0,.39,0,.72-.03,.99-.02,.27-.07,.49-.13,.66s-.14,.29-.23,.37-.19,.12-.31,.12c-.19,0-.44-.11-.74-.33-.3-.22-.68-.47-1.16-.74-.47-.27-1.04-.52-1.69-.74-.65-.22-1.43-.32-2.33-.31-.99,.01-1.88,.22-2.65,.64-.77,.41-1.43,1-1.97,1.76-.54,.76-.95,1.68-1.22,2.75s-.4,2.28-.39,3.62c.02,1.47,.18,2.74,.49,3.81,.31,1.07,.75,1.95,1.32,2.64s1.25,1.2,2.05,1.53,1.69,.49,2.68,.48c.9,0,1.68-.12,2.34-.34,.65-.22,1.21-.46,1.68-.72s.85-.5,1.15-.71,.54-.31,.7-.32c.13,0,.23,.02,.31,.07,.08,.05,.14,.15,.2,.31,.05,.15,.09,.37,.12,.65s.05,.65,.05,1.11h.03Z" fill="#ffc900"/>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <polygon points="797.15 145.36 797.15 132.11 810.84 126.37 797.15 145.36" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path d="M1937.83-114.42H916.53l-119.38,259.78c17.28,0,17.28-16.01,34.56-16.01s17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.29-16.01,34.57-16.01,17.29,16.01,34.57,16.01,17.29-16.01,34.57-16.01" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <path d="M1937.83-127.68H916.53l-119.38,259.78c17.28,0,17.28-16.01,34.56-16.01s17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.56-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.56,16.01,17.28-16.01,34.57-16.01,17.28,16.01,34.57,16.01,17.29-16.01,34.57-16.01,17.29,16.01,34.57,16.01,17.29-16.01,34.57-16.01" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              <g>
                <line x1="957.09" y1="-98.7" x2="863.26" y2="117.5" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1016.06" y1="-98.7" x2="928.09" y2="116.71" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1075.02" y1="-98.7" x2="992.92" y2="115.92" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1133.98" y1="-98.7" x2="1057.75" y2="115.14" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1192.95" y1="-98.7" x2="1122.58" y2="114.35" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1251.91" y1="-98.7" x2="1187.41" y2="113.56" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1310.88" y1="-98.7" x2="1252.23" y2="112.78" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1369.84" y1="-98.7" x2="1317.06" y2="111.99" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1428.81" y1="-98.7" x2="1381.89" y2="111.2" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1487.77" y1="-98.7" x2="1446.72" y2="110.42" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1546.73" y1="-98.7" x2="1511.55" y2="109.63" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1605.7" y1="-98.7" x2="1576.38" y2="108.84" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1664.66" y1="-98.7" x2="1641.21" y2="108.06" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1723.63" y1="-98.7" x2="1706.03" y2="107.27" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1782.59" y1="-98.7" x2="1770.86" y2="106.48" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1841.55" y1="-98.7" x2="1835.69" y2="105.7" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1900.52" y1="-98.7" x2="1900.52" y2="104.91" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="1532.22" y="656.48" width="224.32" height="141.43" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <line x1="1532.22" y1="676.16" x2="1756.54" y2="676.16" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <rect x="1557.63" y="761.87" width="94.38" height="8.1" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <g>
                <g>
                  <path d="M1587.45,717.34c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
                  <path d="M1617.33,725.54c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
                  <path d="M1648.93,745.81c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
                </g>
                <g>
                  <path d="M1587.45,717.34c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path d="M1617.33,725.54c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path d="M1648.93,745.81c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
                <g>
                  <path d="M1587.45,717.34c0,.56-.02,1.03-.07,1.42-.05,.39-.12,.71-.22,.95s-.22,.42-.36,.53c-.14,.11-.3,.17-.47,.17h-9.32v29.99c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.68,.17-1.17,.23s-1.1,.09-1.83,.09-1.34-.03-1.83-.09-.88-.13-1.16-.23c-.29-.1-.49-.22-.62-.37-.12-.15-.19-.33-.19-.52v-29.99h-9.32c-.19,0-.36-.06-.49-.17-.13-.12-.25-.29-.34-.53-.1-.24-.17-.56-.22-.95-.05-.39-.07-.87-.07-1.42s.02-1.06,.07-1.47,.12-.72,.22-.96c.1-.24,.21-.41,.34-.52,.13-.11,.3-.16,.49-.16h26.23c.17,0,.33,.05,.47,.16s.26,.28,.36,.52,.17,.56,.22,.96c.05,.4,.07,.89,.07,1.47h.02Z" fill="#ffc900"/>
                  <path d="M1617.33,725.54c0,2.09-.33,3.94-.98,5.55s-1.6,2.97-2.85,4.07c-1.25,1.1-2.78,1.94-4.59,2.52s-3.95,.86-6.4,.86h-3.11v11.88c0,.19-.06,.36-.19,.52-.12,.15-.33,.28-.62,.37-.29,.1-.67,.17-1.15,.23s-1.09,.09-1.84,.09-1.34-.03-1.83-.09-.88-.13-1.17-.23-.49-.22-.6-.37c-.12-.15-.17-.33-.17-.52v-33.47c0-.9,.23-1.58,.71-2.03,.47-.45,1.09-.68,1.85-.68h8.77c.88,0,1.72,.03,2.52,.1,.79,.07,1.75,.21,2.86,.43s2.24,.63,3.38,1.22c1.14,.59,2.11,1.35,2.92,2.26,.8,.91,1.42,1.97,1.84,3.19,.42,1.22,.63,2.58,.63,4.1h.02Zm-7.91,.55c0-1.3-.23-2.38-.69-3.22-.46-.84-1.03-1.47-1.7-1.87-.67-.4-1.38-.66-2.11-.76-.74-.11-1.5-.16-2.29-.16h-3.22v12.62h3.39c1.21,0,2.22-.16,3.03-.49s1.48-.78,2-1.37c.52-.58,.91-1.28,1.18-2.1,.27-.81,.4-1.7,.4-2.66h0Z" fill="#ffc900"/>
                  <path d="M1648.93,745.81c0,.48-.01,.89-.04,1.22-.03,.34-.07,.62-.13,.86s-.13,.45-.23,.62-.25,.37-.46,.59c-.21,.22-.62,.5-1.22,.85-.6,.34-1.34,.68-2.21,.99-.87,.32-1.87,.58-2.99,.79-1.12,.21-2.33,.32-3.64,.32-2.55,0-4.85-.39-6.9-1.18s-3.8-1.96-5.23-3.52c-1.44-1.56-2.54-3.51-3.31-5.85-.77-2.34-1.15-5.06-1.15-8.17s.42-5.97,1.27-8.43c.84-2.45,2.02-4.52,3.54-6.18,1.51-1.67,3.33-2.93,5.45-3.8,2.12-.86,4.45-1.29,7-1.29,1.04,0,2.03,.09,2.99,.26s1.84,.4,2.66,.68c.81,.28,1.55,.6,2.2,.96,.65,.36,1.11,.68,1.37,.93s.44,.47,.53,.65,.17,.39,.23,.66c.06,.27,.1,.59,.13,.95s.04,.81,.04,1.35c0,.58-.02,1.06-.06,1.47-.04,.4-.11,.73-.2,.98-.1,.25-.21,.43-.35,.55s-.29,.17-.46,.17c-.29,0-.65-.17-1.09-.5-.44-.34-1.01-.71-1.71-1.12s-1.53-.79-2.5-1.12c-.97-.34-2.12-.5-3.47-.5-1.48,0-2.79,.3-3.95,.91s-2.15,1.47-2.96,2.59c-.82,1.12-1.43,2.48-1.85,4.07-.42,1.59-.63,3.38-.63,5.38,0,2.19,.22,4.08,.68,5.68,.45,1.6,1.09,2.92,1.93,3.95,.83,1.04,1.84,1.81,3.02,2.31,1.18,.51,2.51,.76,3.98,.76,1.34,0,2.5-.16,3.48-.47,.98-.32,1.82-.67,2.52-1.05,.7-.38,1.28-.73,1.73-1.04s.8-.46,1.05-.46c.19,0,.35,.04,.46,.11,.12,.08,.21,.23,.29,.46,.08,.23,.13,.55,.17,.96,.04,.41,.06,.96,.06,1.65h-.04Z" fill="#ffc900"/>
                </g>
              </g>
            </g>
            <rect x="-37.03" y="817.78" width="2021.26" height="9.05" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <rect x="-37.03" y="797.91" width="2021.26" height="19.87" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M138.55,125.42c0-32.54,26.38-58.92,58.92-58.92,21.37,0,40.08,11.37,50.41,28.39,2.65-.72,5.43-1.11,8.31-1.11,17.47,0,31.64,14.17,31.64,31.64H138.55Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M756.83,207.52c-6.19-16.54-22.13-28.32-40.84-28.32-22.35,0-40.77,16.83-43.29,38.51-1.66-.48-3.42-.74-5.24-.74-10.44,0-18.9,8.46-18.9,18.9,0,1.42,.16,2.81,.46,4.14h135.76c0-.21,.02-.41,.02-.62,0-16.34-12.19-29.83-27.98-31.87h0Z" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="246.55" y1="143.92" x2="300.6" y2="143.92" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="316.74" y1="143.92" x2="329.12" y2="143.92" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="590.14" y1="259.41" x2="693.6" y2="259.41" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="673.34" y1="274.75" x2="708.07" y2="274.75" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <line x1="650.13" y1="274.75" x2="661.52" y2="274.75" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M125.87,219.27s-105.88,58.15-236.49,58.15-236.49-58.15-236.49-58.15" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path d="M125.87,291.75s-105.88,58.15-236.49,58.15-236.49-58.15-236.49-58.15" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <g>
              <g>
                <path d="M255.54,734.55c0,5.02,7.12,9.1,15.9,9.1s15.9-4.07,15.9-9.1V224.41h-31.79v510.14h0Z" fill="#3085cc" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <g>
                  <g clip-path="url(#clippath-1)">
                    <g>
                      <path d="M255.55,741.28c0,1.31,7.12,2.37,15.9,2.37s15.9-1.06,15.9-2.37v-133.12h-31.79v133.12h0Z" fill="#013"/>
                      <g>
                        <path d="M255.55,741.28c0,1.31,7.12,2.37,15.9,2.37s15.9-1.06,15.9-2.37v-133.12h-31.79v133.12h0Z" fill="#013"/>
                        <polygon points="255.55 690.31 255.7 690.16 287.34 658.52 287.34 643.07 255.55 674.86 255.55 690.31" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                        <polygon points="255.55 705.75 255.55 721.2 286.59 690.16 287.34 689.41 287.34 673.96 271.15 690.16 255.55 705.75" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                        <polygon points="255.55 643.97 255.55 659.42 274.89 640.07 287.34 627.63 287.34 612.18 259.45 640.07 255.55 643.97" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                        <polygon points="255.55 628.53 275.91 608.16 260.47 608.16 255.55 613.08 255.55 628.53" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                        <polygon points="255.55 740.24 267.4 740.24 287.34 720.3 287.34 704.85 255.55 736.65 255.55 740.24" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                      </g>
                    </g>
                  </g>
                  <path d="M255.55,741.28c0,1.31,7.12,2.37,15.9,2.37s15.9-1.06,15.9-2.37v-133.12h-31.79v133.12h0Z" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <ellipse cx="271.44" cy="224.41" rx="15.9" ry="9.1" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="264.91" y="438.03" width="13.05" height="27.91" fill="#bbe4ec" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
              <rect x="238.98" y="727.33" width="62.75" height="70.51" rx="6.92" ry="6.92" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <path d="M233.44,775.52h73.83c2.88,0,5.22,2.34,5.22,5.22v17.1h-84.27v-17.1c0-2.88,2.34-5.22,5.22-5.22h0Z" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <line x1="248.85" y1="775.52" x2="291.86" y2="775.52" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </g>
          </g>
          {challenges.map(({ tag, key }, i) => (
            <g className="clickable" onClick={() => {
                setOpen((key - 1).toString())
              }} key={i}>
              {randomCatch.includes(key - 1) && animations[key - 1]}
              {tag}
            </g>
          ))}
           <g className="clickable" onClick={() => setOpen(10)} id="_10_list">
              {/* <circle cx="1040.89" cy="231.18" r="50" fill="#ffc900" opacity=".2">
                {animateCircleM('0.7s')}
                {animateOpacity('0.7s')}
              </circle>
              <circle cx="1040.89" cy="231.18" r="50" fill="#ffc900" opacity=".2">
                {animateCircleM()}
                {animateOpacity()}
              </circle> */}
              <g>
                <rect x="948.2" y="186.34" width="177.56" height="123.37" rx="10" ry="10" fill="#4c9b78" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <rect x="948.2" y="212.19" width="177.56" height="85.72" fill="#013" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <g>
                  <line x1="996.55" y1="201.07" x2="996.55" y2="130.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <circle cx="996.55" cy="200.56" r="3.92" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <line x1="1077.41" y1="201.07" x2="1077.41" y2="130.98" fill="none" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                  <circle cx="1077.41" cy="200.56" r="3.92" fill="#fff" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                </g>
                <g>
                  <path d="M978.63,248.32c-.5-1.08-.27-1.94,.69-2.58,.68-.44,1.51-1.04,2.49-1.8v-3.75c0-.48-.24-.72-.72-.72h-.93c-1.1,0-1.65-.55-1.65-1.65s.55-1.65,1.65-1.65h3.51c.48,0,.86,.15,1.15,.43,.29,.29,.44,.68,.44,1.16v5.94c1.16,1.84,3.35,2.84,6.57,3,3.64,.16,7.62,.12,11.94-.12,.8-.06,1.32,.07,1.58,.38,.25,.31,.25,.85,.01,1.63-.16,.5-.37,.86-.63,1.08s-.65,.34-1.17,.36c-4.48,.14-8.4,.15-11.76,.03-1.9-.1-3.46-.39-4.68-.87-1.32-.52-2.43-1.35-3.33-2.49-1.04,.92-1.84,1.62-2.4,2.1-1.22,1.04-2.14,.88-2.76-.48h0Zm3.54-14.79c-.54-.48-1.19-.97-1.95-1.47-.54-.34-.84-.72-.9-1.14s.13-.85,.57-1.29c.62-.62,1.29-.74,2.01-.36,.6,.36,1.15,.71,1.65,1.05,.18,.12,.46,.32,.84,.6,.1,.06,.17,.11,.21,.15,.78,.7,.78,1.46,0,2.28-.44,.44-.84,.68-1.2,.72-.4,.04-.81-.14-1.23-.54h0Zm1.02-5.94c-.52-.5-1.13-1-1.83-1.5-.54-.34-.83-.72-.88-1.15s.15-.85,.62-1.28c.62-.58,1.29-.67,2.01-.27,.58,.36,1.11,.72,1.59,1.08,.32,.22,.65,.48,.99,.78,.78,.72,.77,1.47-.03,2.25-.88,.84-1.7,.87-2.46,.09h0Zm18.51,17.4c-1.68-.88-3.13-2.1-4.35-3.66l.09,1.17c.06,.64-.24,1.02-.9,1.14-1.42,.28-3.51,.69-6.27,1.23-.64,.12-1.12,.21-1.44,.27-.56,.1-.97,.04-1.24-.19s-.46-.63-.56-1.22c-.18-1.16,.3-1.81,1.44-1.95,.04,0,.08,0,.13-.02,.05,0,.08-.02,.11-.02v-16.17c0-.58,.18-1.05,.54-1.41s.83-.54,1.41-.54h10.41c.58,0,1.05,.18,1.41,.54s.54,.83,.54,1.41v9.6l1.23,1.02c.32,.28,.47,.57,.45,.85-.02,.29-.22,.55-.6,.79-.16,.1-.39,.24-.69,.42-1.24,.78-2.15,1.32-2.73,1.62,.84,.78,1.68,1.39,2.52,1.83,.82,.32,1.33,.69,1.53,1.11,.22,.46,.09,1.01-.39,1.65-.62,.86-1.5,1.03-2.64,.51v.02Zm-9.57-16.8h7.44v-.87c0-.46-.23-.69-.69-.69h-6.03c-.48,0-.72,.24-.72,.72v.84Zm0,4.23h7.44v-1.65h-7.44v1.65Zm0,8.82l4.65-.72c-.9-1.42-1.66-3.12-2.28-5.1h-2.37v5.82Zm6.81-3.48c.2-.14,.5-.35,.9-.63,.28-.22,.5-.39,.66-.51,.56-.42,1.04-.82,1.44-1.2h-4.23c.32,.8,.73,1.58,1.23,2.34Z" fill="#fff"/>
                  <path d="M1013.91,250.6c-1.14,0-1.71-.57-1.71-1.71v-11.01c-.4,.4-.71,.7-.93,.9-.58,.48-1.08,.7-1.5,.65s-.81-.39-1.17-1.04c-.32-.58-.42-1.09-.32-1.53,.11-.44,.44-.86,.98-1.26,2.04-1.64,3.74-3.44,5.1-5.4,.56-.86,1.32-1.09,2.28-.69,1.12,.48,1.36,1.23,.72,2.25-.6,.86-1.18,1.64-1.74,2.34v14.79c0,1.14-.57,1.71-1.71,1.71h0Zm-3.99-19.64c-.36-.07-.7-.38-1.02-.91-.58-1-.38-1.83,.6-2.49,1.52-1.16,2.84-2.42,3.96-3.78,.56-.74,1.32-.92,2.28-.54,.64,.26,1.01,.57,1.12,.93s-.04,.81-.43,1.35c-.74,.9-1.55,1.77-2.43,2.61-.92,.88-1.84,1.68-2.76,2.4-.52,.36-.96,.51-1.32,.43h0Zm7.98,18.53c-.4-.64-.5-1.16-.3-1.56,.18-.36,.66-.66,1.44-.9,1.6-.58,3.08-1.3,4.44-2.16-.98-1.04-1.83-2.19-2.55-3.45-.3-.58-.36-1.09-.18-1.55,.18-.45,.57-.77,1.17-.94l.66-.21h-2.61c-1.06,0-1.59-.54-1.59-1.62s.53-1.59,1.59-1.59h11.88c.7,0,1.18,.21,1.44,.63,.26,.44,.26,1.08,0,1.92-.96,2.7-2.4,4.99-4.32,6.87,1.24,.76,2.7,1.4,4.38,1.92,.92,.22,1.51,.53,1.77,.93,.26,.44,.14,1.02-.36,1.74-.58,.8-1.43,1.05-2.55,.75-2.3-.72-4.32-1.72-6.06-3-1.82,1.22-3.79,2.2-5.91,2.94-1.04,.34-1.82,.1-2.34-.72h0Zm1.26-15.03c-1.14-.78-1.07-1.83,.21-3.15,.72-1.18,1.08-2.7,1.08-4.56v-1.65c0-.44,.13-.79,.39-1.05,.26-.26,.61-.39,1.05-.39h7.35c.58,0,1.05,.18,1.41,.54s.54,.83,.54,1.41v4.56c0,.54,.03,.85,.09,.93,.08,.08,.22,.12,.42,.12h.63c.2,0,.34-.03,.42-.09,.14-.08,.24-.31,.3-.69v-.06s.03-.08,.03-.12c.04-.76,.19-1.26,.45-1.5,.28-.26,.72-.22,1.32,.12,.64,.36,.93,.99,.87,1.89-.1,.88-.22,1.5-.36,1.86-.16,.56-.53,.99-1.11,1.29-.44,.24-1.04,.36-1.8,.36h-1.77c-.96,0-1.7-.26-2.22-.78-.3-.3-.51-.66-.63-1.08-.14-.48-.21-1.19-.21-2.13v-2.79c0-.48-.24-.72-.72-.72h-3.12v.06c0,3.06-.7,5.45-2.1,7.17-.88,.84-1.72,.99-2.52,.45h0Zm6.99,8.34c1.32-1.3,2.36-2.66,3.12-4.08h-6.12c.7,1.48,1.7,2.84,3,4.08Z" fill="#fff"/>
                  <path d="M1044.93,250.6c-1.2,0-1.8-.59-1.8-1.77v-9.84c-.82,2-1.56,3.56-2.22,4.68-.52,.74-.97,1.09-1.35,1.06s-.77-.44-1.17-1.24c-.24-.48-.34-.91-.32-1.29,.03-.38,.21-.78,.53-1.2,1.72-2.38,3.08-5.32,4.08-8.82h-2.43c-1.12,0-1.68-.57-1.68-1.71s.56-1.68,1.68-1.68h2.88v-4.41c0-1.18,.6-1.77,1.8-1.77s1.77,.59,1.77,1.77v4.41h2.07c1.12,0,1.68,.56,1.68,1.68s-.56,1.71-1.68,1.71h-2.07v.81c.72,.6,1.99,1.86,3.81,3.78,.32,.36,.47,.69,.45,.99-.02,.24-.19,.59-.51,1.05-.44,.66-.84,1-1.21,1.01s-.78-.3-1.21-.95c-.48-.7-.92-1.31-1.32-1.83v11.79c0,1.18-.59,1.77-1.77,1.77h-.01Zm10.23-1.59c-.48-1.42,.26-2.11,2.22-2.07h1.05c.28-.02,.47-.08,.58-.18s.17-.28,.17-.54v-9.12c-1.9,3.08-4.05,5.7-6.45,7.86-.54,.44-1.01,.66-1.41,.65-.4,0-.82-.27-1.26-.79-.5-.6-.72-1.1-.65-1.52,.07-.41,.44-.85,1.1-1.34,1.48-1.22,2.9-2.7,4.26-4.44,1.32-1.72,2.43-3.5,3.33-5.34h-5.16c-1.12,0-1.68-.57-1.68-1.71s.56-1.68,1.68-1.68h6.24v-4.26c0-1.24,.63-1.86,1.89-1.86s1.86,.62,1.86,1.86v4.26h1.14c1.12,0,1.68,.56,1.68,1.68s-.56,1.71-1.68,1.71h-1.14v14.04c0,1.02-.12,1.8-.38,2.34-.25,.54-.67,.95-1.28,1.23-.76,.38-1.93,.6-3.51,.66-1.4,.04-2.27-.44-2.61-1.44h.01Z" fill="#fff"/>
                  <path d="M1074.45,250.54c-1.1,0-1.65-.54-1.65-1.62v-8.97c-.76,2.14-1.46,3.85-2.1,5.13-.48,.82-.9,1.21-1.26,1.18s-.73-.48-1.11-1.36c-.2-.48-.28-.89-.25-1.21,.03-.33,.18-.69,.46-1.1,1.22-1.84,2.27-4.08,3.15-6.72h-1.71c-1.1,0-1.65-.56-1.65-1.68s.55-1.65,1.65-1.65h2.82v-8.19c0-1.08,.55-1.62,1.65-1.62s1.62,.54,1.62,1.62v8.19h2.76c1.1,0,1.65,.55,1.65,1.65s-.55,1.68-1.65,1.68h-2.76v.21c.72,.7,1.93,2.1,3.63,4.2,.28,.36,.4,.68,.36,.96-.04,.26-.22,.59-.54,.99-.48,.6-.89,.9-1.23,.89-.34,0-.68-.34-1.02-1.01-.32-.6-.72-1.3-1.2-2.1v8.91c0,1.08-.54,1.62-1.62,1.62h0Zm-4.5-18.96c-.26-.14-.41-.46-.45-.96-.14-1.2-.42-2.63-.84-4.29-.24-.84,.07-1.37,.93-1.59,.8-.22,1.31,.03,1.53,.75,.22,.74,.43,1.56,.63,2.46,.16,.7,.29,1.45,.39,2.25,.08,.7-.26,1.16-1.02,1.38-.52,.14-.91,.14-1.17,0h0Zm7.62,.03c-.8-.22-1.09-.66-.87-1.32,.3-1.04,.65-2.56,1.05-4.56,.18-1,.77-1.38,1.77-1.14,.98,.22,1.32,.82,1.02,1.8-.16,.52-.4,1.28-.72,2.28-.04,.14-.11,.34-.21,.6-.28,.84-.49,1.44-.63,1.8-.24,.56-.71,.74-1.41,.54h0Zm13.29,18.99c-1.1,0-1.65-.55-1.65-1.65v-5.85l-6.63,1.2c-1.12,.2-1.77-.27-1.95-1.41-.18-1.06,.28-1.7,1.38-1.92l7.2-1.29v-15.45c0-1.1,.55-1.65,1.65-1.65s1.65,.55,1.65,1.65v14.85l1.35-.27c1.12-.22,1.77,.24,1.95,1.38,.18,1.08-.27,1.73-1.35,1.95l-1.95,.36v6.45c0,1.1-.55,1.65-1.65,1.65h0Zm-6.66-13.98c-.16-.12-.42-.32-.78-.6-.06-.04-.15-.11-.27-.21-.5-.36-.88-.63-1.14-.81-.9-.54-1.05-1.26-.45-2.16,.54-.8,1.22-.97,2.04-.51,1.18,.72,2.12,1.36,2.82,1.92,.34,.3,.52,.61,.54,.93,.02,.3-.1,.66-.36,1.08-.68,1.12-1.48,1.24-2.4,.36h0Zm2.4-6.79c-.38,.05-.79-.17-1.23-.65-.24-.22-.48-.43-.72-.63-.02-.02-.06-.05-.12-.09-.56-.46-.99-.79-1.29-.99-.78-.56-.88-1.25-.3-2.07,.54-.78,1.2-.92,1.98-.42,1.14,.76,2.06,1.45,2.76,2.07,.3,.3,.45,.6,.45,.9,0,.28-.13,.61-.39,.99-.38,.54-.76,.84-1.14,.89h0Z" fill="#fff"/>
                  <path d="M1011.9,271.54c-.12-.08-.32-.2-.6-.36-.14-.08-.24-.14-.29-.17s-.12-.07-.23-.14c-.5-.28-.87-.48-1.11-.6-1.08-.52-1.26-1.29-.54-2.31,.52-.76,1.18-.98,1.98-.66,1.12,.44,2.14,.94,3.06,1.5,.46,.3,.71,.63,.75,.99,.02,.34-.13,.75-.45,1.23-.72,1.08-1.58,1.25-2.58,.51h0Zm-1.53,13.51c-.52-.38-.83-.75-.93-1.11-.1-.38,0-.83,.33-1.35,.4-.64,.97-1.61,1.71-2.91,.8-1.46,1.41-2.61,1.83-3.45,.6-1.18,1.42-1.35,2.46-.51,.76,.64,.93,1.39,.51,2.25-.82,1.72-1.93,3.9-3.33,6.54-.64,1.14-1.5,1.32-2.58,.54h0Zm2.91-21.6c-.12-.1-.33-.23-.63-.39-.08-.06-.14-.1-.18-.12-.26-.16-.67-.4-1.23-.72-1.14-.54-1.33-1.33-.57-2.37,.52-.74,1.2-.93,2.04-.57,1.04,.46,2,.97,2.88,1.53,.48,.32,.74,.66,.78,1.01,.04,.35-.11,.77-.45,1.24-.76,1.06-1.64,1.19-2.64,.39Zm4.32,7.17c-.9,0-1.35-.46-1.35-1.38s.45-1.35,1.35-1.35h6.57v-1.23h-4.77c-.82,0-1.23-.41-1.23-1.23s.41-1.23,1.23-1.23h4.77v-1.17h-5.49c-.86,0-1.29-.43-1.29-1.29s.43-1.29,1.29-1.29h5.49v-.09c0-1.16,.59-1.74,1.77-1.74s1.74,.58,1.74,1.74v.09h5.94c.86,0,1.29,.43,1.29,1.29s-.43,1.29-1.29,1.29h-5.94v1.17h5.16c.82,0,1.23,.41,1.23,1.23s-.41,1.23-1.23,1.23h-5.16v1.23h6.84c.9,0,1.35,.45,1.35,1.35s-.45,1.38-1.35,1.38h-16.92Zm9.48,14.76c-.42-1.28,.21-1.9,1.89-1.86h.69c.38,0,.57-.16,.57-.48v-2.67h-8.16v4.59c0,1.1-.55,1.65-1.65,1.65s-1.68-.55-1.68-1.65v-10.98c0-.58,.18-1.05,.54-1.41,.36-.36,.83-.54,1.41-.54h11.07c.58,0,1.05,.18,1.41,.54,.36,.36,.54,.83,.54,1.41v9.12c0,.86-.1,1.5-.3,1.92-.2,.48-.57,.84-1.11,1.08-.66,.3-1.67,.47-3.03,.51-1.18,.04-1.91-.37-2.19-1.23h0Zm-5.01-7.71h2.46v-2.91h-1.74c-.48,0-.72,.24-.72,.72v2.19Zm5.58,0h2.58v-2.19c0-.48-.24-.72-.72-.72h-1.86v2.91Z" fill="#fff"/>
                  <path d="M1051.86,286.63c-1.2,0-1.8-.6-1.8-1.8v-2.01h-9.78c-1.02,0-1.53-.51-1.53-1.53s.51-1.53,1.53-1.53h9.78v-1.56h-6.51c-.58,0-1.05-.18-1.41-.54-.36-.36-.54-.83-.54-1.41v-7.35c0-.58,.18-1.05,.54-1.41,.36-.36,.83-.54,1.41-.54h17.13c.58,0,1.05,.18,1.41,.54,.36,.36,.54,.83,.54,1.41v7.35c0,.58-.18,1.05-.54,1.41-.36,.36-.83,.54-1.41,.54h-7.02v1.56h10.05c1.02,0,1.53,.51,1.53,1.53s-.51,1.53-1.53,1.53h-10.05v2.01c0,1.2-.6,1.8-1.8,1.8h0Zm-9.39-20.85c-.58,0-1.05-.18-1.41-.54s-.54-.83-.54-1.41v-2.1c0-.58,.18-1.05,.54-1.41,.36-.36,.83-.54,1.41-.54h6.69c.58,0,1.05,.18,1.41,.54,.36,.36,.54,.83,.54,1.41v2.1c0,.58-.18,1.05-.54,1.41-.36,.36-.83,.54-1.41,.54h-6.69Zm1.92-2.13h2.7c.48,0,.72-.24,.72-.72v-.3c0-.48-.24-.72-.72-.72h-2.7c-.48,0-.72,.24-.72,.72v.3c0,.48,.24,.72,.72,.72Zm.63,7.65h5.04v-1.59h-4.32c-.48,0-.72,.24-.72,.72v.87h0Zm.72,4.17h4.32v-1.62h-5.04v.9c0,.48,.24,.72,.72,.72h0Zm9.15-9.69c-.58,0-1.05-.18-1.41-.54s-.54-.83-.54-1.41v-2.1c0-.58,.18-1.05,.54-1.41,.36-.36,.83-.54,1.41-.54h6.75c.58,0,1.05,.18,1.41,.54,.36,.36,.54,.83,.54,1.41v2.1c0,.58-.18,1.05-.54,1.41-.36,.36-.83,.54-1.41,.54h-6.75Zm-1.23,5.52h5.37v-.9c0-.46-.23-.69-.69-.69h-4.68v1.59Zm0,4.17h4.65c.48,0,.72-.24,.72-.72v-.9h-5.37v1.62Zm3.12-11.82h2.79c.48,0,.72-.24,.72-.72v-.3c0-.48-.24-.72-.72-.72h-2.79c-.48,0-.72,.24-.72,.72v.3c0,.48,.24,.72,.72,.72Z" fill="#fff"/>
                </g>
                <g>
                  <circle cx="1155.13" cy="204.47" r="18.94" fill="#ffc900" stroke="#013" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <g>
                    <path d="M1155.15,199.1c-.92,0-1.66-.26-2.24-.78s-.87-1.19-.87-2.01,.29-1.5,.87-2.01c.58-.52,1.33-.78,2.24-.78s1.66,.26,2.23,.79c.57,.51,.85,1.18,.85,2.01s-.29,1.48-.87,2-1.32,.78-2.21,.78Zm0,16.34c-1.81,0-2.72-.9-2.72-2.69v-8.76c0-1.79,.91-2.69,2.72-2.69s2.69,.9,2.69,2.69v8.76c0,1.79-.9,2.69-2.69,2.69Z" fill="#013"/>
                    <path d="M1155.15,199.1c-.92,0-1.66-.26-2.24-.78s-.87-1.19-.87-2.01,.29-1.5,.87-2.01c.58-.52,1.33-.78,2.24-.78s1.66,.26,2.23,.79c.57,.51,.85,1.18,.85,2.01s-.29,1.48-.87,2-1.32,.78-2.21,.78Zm0,16.34c-1.81,0-2.72-.9-2.72-2.69v-8.76c0-1.79,.91-2.69,2.72-2.69s2.69,.9,2.69,2.69v8.76c0,1.79-.9,2.69-2.69,2.69Z" fill="#013"/>
                  </g>
                </g>
              </g>
            </g>,
        </g>
      </g>
    </StyledSVG>
  )
}

export default BackgroundSVG
