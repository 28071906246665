import React from 'react'
import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
  Stack,
} from '@chakra-ui/react'
import BackgroundImage from '../../components/BackgroundImage'
import SpecialButton from '../../components/SpecialButton'
import { responsive } from '../../components/ThemeProvider/theme'
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import CustomModalCloseButton from '../../components/ModalCloseButton'
import CustomModal from '../../components/CustomModal'
import badge from './badge.png'

const Compelete = ({ isOpen, onClose }) => {
  const { isMobile } = useResponsive()
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent my={responsive('0')} maxH={responsive('86vh', null, 'auto')} maxW={responsive('30em', null, '39.875em')} overflow="auto" border="3px solid" borderRadius={'1.25rem'} color="textBlue" width={responsive('91.8%', '39.875em')} p="0">
        <CustomModalCloseButton />
        <ModalBody px={responsive('1.5em', '3.75em')} pt={responsive('1em', '2.25em')} pb={responsive('1.75em', '2.25em')}>
          <Stack spacing={'1.125em'}>
            <Box textAlign={'center'} borderBottom="3px solid"><Text variant={'h1'}>任務完成！</Text></Box>
            <Text variant={'mission_title'} textAlign='center'>
              {isMobile ? (
                <>
                  恭喜你解鎖 9 大退役材料，<br />
                  獲得<Text as="span" variant={'mission_title'} color="normalBlue">霹靂徽章</Text>！
                </>
              ) : (
                <>
                  恭喜你解鎖 9 大退役材料，獲得<Text as="span" variant={'mission_title'} color="normalBlue">霹靂徽章</Text>！
                </>
              )}
            </Text>
            <Box>
              <Box mx="auto" width={responsive('90%', '100%')}><BackgroundImage src={badge} ratio={813 / 522} /></Box>
            </Box>
            <Text>
              相信下次在某個路口看見這些材料，你會想起這段探索。每個循環材料皆經過動輒十年的考驗，品質絕不掉漆。與台電文創一同支持環境永續，讓它們再陪你更久。
            </Text>
            <Box textAlign={'center'}>
              <SpecialButton variant={'orange'} href="https://www.tpcreativeshop.com/">
                看更多循環再造商品
              </SpecialButton>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </CustomModal>
  )
}

export default Compelete
