import { ModalCloseButton } from '@chakra-ui/react'
import React from 'react'
import { responsive } from './ThemeProvider/theme'

const CustomModalCloseButton = (props) => {
  return (
    <ModalCloseButton
      _hover={{}}
      fontSize={responsive("1.25em", null, '1em', null, '1.25em')}
      minWidth="auto"
      top={responsive('0.5rem', 2)}
      right={responsive('0.5em', "0.75em")}
      {...props}
    />
  )
}

export default CustomModalCloseButton
