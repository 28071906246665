import React from 'react';
import { Button } from "@chakra-ui/react";

import Link from './Link'

const ReButton = ({ href, onClick, trackerName, to, ...props }) => {
  const handleTrackEvent = (e) => {
    if (onClick) {
      onClick(e)
    }
    if (trackerName) {
      if (typeof window.gtag === 'function') {
        window.gtag("event", "click_button", {
          button_name: trackerName,
        });
      }
    }
  }
  if (href || to) return <Button as={p => <Link {...p} to={to} href={href} target={href && '_blank'} />} onClick={handleTrackEvent} {...props} />
  return <Button onClick={handleTrackEvent} {...props} />
}

ReButton.defaultProps = {
  colorScheme: 'blue',
}

ReButton.Secondary = props => (
  <ReButton colorScheme="green" {...props} />
)

ReButton.Danger = props => (
  <ReButton colorScheme="red" {...props} />
)

export default ReButton
