import React, { useEffect, useMemo, useState } from 'react'
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs'
import { Box, Flex, keyframes, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

import BackgroundImage from '../../components/BackgroundImage'
import SpecialButton from '../../components/SpecialButton'
import { responsive } from '../../components/ThemeProvider/theme'

import brush from './image/brush.png'
import saw from './image/saw.png'
import shovel from './image/shovel.png'
// import brushVideo from './video/brush.mp4'
import brushVideo from './video/brush.gif'
import sawVideo from './video/saw.gif'
import shovelVideo from './video/shovel.gif'

const btns = [
  { img: brush, video: brushVideo },
  { img: shovel, video: shovelVideo },
  { img: saw, video: sawVideo },
]

const toggle = keyframes`
  100% {
    transform: translateY(-10px);
  }
`

const Fluctuate = styled(Box)`
  animation: ${props => props.hasDone} 0.3s alternate infinite;
  animation-delay: ${props => props.delay};
`

const Play = ({ data, finish, setFinish, isOpen, onClose }) => {
  const [anime, setAnime] = useState()
  const hasDone = useMemo(() => finish[isOpen]?.includes(data.ans), [finish, isOpen])
  useEffect(() => {
    if (hasDone && !finish[isOpen]?.includes('anime')) {
      setAnime(true)
      setTimeout(() => {
        setAnime(false)
        finish[isOpen].push('anime')
        setFinish({ ...finish })
      }, 1000)
    }
    if (!finish?.[isOpen]) {
      finish[isOpen] = []
    }
  }, [finish])
  return (
    <Box
      mt={responsive('0.625em', 0)}
      ml={responsive(0, null ,'3em')}
      border="3px solid"
      borderColor="textBlue"
      borderRadius={'10px'}
      bg="paleGray"
      minWidth={responsive('auto', null, '50.9%', null, '33.875em')}
      overflow="hidden"
      transform="translateZ(0)"
    >
      <Box pos="relative" borderBottom={'3px solid'} borderColor="borderGray">
        <Box position={'relative'}>
          <BackgroundImage transition="all 0.3s" opacity={finish[isOpen]?.includes('anime') ? 0 : 1} src={data.game} ratio={813 / 765} />
          <Box pos={'absolute'} top={0} left={0} right={0}>
            <BackgroundImage transition="all 0.3s" opacity={finish[isOpen]?.includes('anime') ? 1 : 0} src={data.finish} ratio={813 / 765} />
          </Box>
          {anime && (
            <Box pos={'absolute'} top={0} left={0} right={0} width={'100%'}>
              <BackgroundImage src={btns?.[data.ans].video} ratio={813 / 765} />
            </Box>
          )}
        </Box>
        <Flex pos="absolute" width="100%" px={responsive('1em', null, '2.5em', null, '4.75em')} justifyContent="space-between" bottom={responsive('1.25em', null, '1.25em', null, '2.5em')}>
          {btns.map(({ img }, i) => (
            <SpecialButton
              p="0"
              border="3px solid"
              borderRadius={'0.625rem'}
              width={responsive('18.35vw', null, '3.75em', null, '6rem')}
              variant={'yellow'}
              _hover={{
                transform: 'translateY(6px)',
              }}
              onClick={() => {
                finish[isOpen].push(i)
                if (i === data.ans) {
                  finish[isOpen].push('finish')
                }
                setFinish({...finish})
              }}
              ans={finish[isOpen]?.includes(i)}
              isCorrect={i === data.ans}
              disabled={finish[isOpen]?.includes(i) || hasDone}
              _disabled={{
                bg: data.ans !== i && 'borderGray',
                cursor: 'not-allowed',
                _hover: {
                  transform: 'none'
                }
              }}
              key={i}
            >
              <Box width={'100%'}>
                <BackgroundImage src={img} ratio={1} />
              </Box>
            </SpecialButton>
          ))}
        </Flex>
      </Box>
      <Box
        pos="relative"
        mx={responsive('1em', '0.75em')}
        px={responsive('1em', null, null, null, '2.5em')}
        my={'1.25em'}
        py={'0.75em'}
        border="3px solid"
        color="textBlue"
        minH={responsive('auto', '5.625em')}
        borderRadius="0.625em"
        bg="white"
      >
        <Text variant={'h4'}>
          {finish[isOpen]?.includes(true) ? '再去找找其他材料吧！'
            : (finish[isOpen]?.length ?
            (hasDone ? data.correct : '嗯......應該會有更適合的再造方式，試看看其他工具吧！')
            : '點擊上方工具開始再造材料！'
          )}
        </Text>
        <Box
          pos="absolute"
          display={finish[isOpen]?.includes(true) ? 'block' : 'none'}
          fontSize={responsive('1em', '1.5em')}
          right={responsive('0.25rem', '0.5rem')}
          top={responsive('0.5rem', '0.75rem')}
          cursor={hasDone && 'pointer'}
          pointerEvents={!hasDone && 'none'}
          onClick={() => {
            finish[isOpen] = finish[isOpen].filter(d => d !== true)
            setFinish({ ...finish })
          }}
        >
          <Fluctuate hasDone={hasDone && toggle} delay={'0.15s'}>
            <BsCaretUpFill size={'1em'} />
          </Fluctuate>
        </Box>
        <Box
          pos="absolute"
          fontSize={responsive('1em', '1.5em')}
          right={responsive('0.25rem', '0.5rem')}
          bottom={responsive('0.25rem', '0.5rem')}
          cursor={hasDone && 'pointer'}
          pointerEvents={!hasDone && 'none'}
          onClick={() => {
            if (finish[isOpen]?.includes(true)) {
              onClose()
            } else {
              finish[isOpen]?.push(true)
              setFinish({ ...finish })
            }
          }}
        >
          <Fluctuate hasDone={hasDone && toggle}>
            <BsCaretDownFill size={'1em'} />
          </Fluctuate>
        </Box>
      </Box>
    </Box>
  )
}

export default Play
