import { Modal } from '@chakra-ui/react'
import React, { useRef } from 'react'
import useResponsive from '../contexts/mediaQuery/useResponsive'
import CustomModalOverlay from './CustomModalOverlay'

const CustomModal = ({ children, overlyChildren, ...props }) => {
  const { isLaptop } = useResponsive()
  return (
    <Modal size="auto" isCentered={!isLaptop} preserveScrollBarGap autoFocus={false} {...props}>
      <CustomModalOverlay bg="rgba(0,17,51,0.4)" children={overlyChildren} />
      {children}
    </Modal>
  )
}

export default CustomModal
