import React from "react";
import { Flex, Box, Text, Stack, Circle, useConst } from "@chakra-ui/react";
import { responsive } from "../../components/ThemeProvider/theme";
import Type, { types } from "./Type";
import SpecialButton from "../../components/SpecialButton";
import useSWR from "swr";
import Papa from "papaparse";
import set from "lodash/set";

const keyRename = {
  材料名稱: "name",
  材料介紹: "intro",
};

types.forEach((type, i) => {
  keyRename[type] = `categories[${i}]`;
});

const fetcher = (url) =>
  fetch(url)
    .then((r) => r.text())
    .then(Papa.parse)
    .then(({ data }) => {
      if (!data) return [];
      const [header, ...body] = data;
      if (!header) return [];
      return body
        .map((row) =>
          header.reduce((acc, key, i) => {
            set(acc, keyRename[key] || key, row[i]);
            return acc;
          }, {})
        )
        .filter((d) => d.name);
    });

const MaterialList = ({ questions }) => {
  const t = useConst(() => Math.floor(Date.now() / (1000 * 60)));
  const { data = [] } = useSWR(`more-data.csv?t=${t}`, fetcher);
  return (
    <Stack width={responsive("auto", "32.375em")} spacing={6}>
      <Box textAlign={"center"} borderBottom="3px solid">
        <Text variant={"h1"}>材料清單</Text>
      </Box>
      {[...questions, ...data].map((d, i) => (
        <Stack spacing={"14px"} key={i}>
          <Flex alignItems={"center"} borderBottom={"3px dashed"} pb="0.75rem">
            <Circle
              bg="textBlue"
              fontSize={responsive("2.75em", "2.5em")}
              size={"1em"}
            >
              <Text color="white" fontSize="1.375rem">
                {String(i + 1).padStart(2, "0")}
              </Text>
            </Circle>
            <Text ml="0.375rem" variant={"h2"}>
              {d.name}
            </Text>
          </Flex>
          <Text textAlign={"justify"}>{d.intro}</Text>
          <Type data={d} />
        </Stack>
      ))}
      <Box textAlign={"center"}>
        <SpecialButton
          variant={"orange"}
          href="https://www.tpcreativeshop.com/"
        >
          看更多循環再造商品
        </SpecialButton>
      </Box>
    </Stack>
  );
};

export default MaterialList;
