import { ModalOverlay } from '@chakra-ui/react'
import React from 'react'

const CustomModalOverlay = (props) => {
  return (
    <ModalOverlay {...props} />
  )
}

export default CustomModalOverlay
